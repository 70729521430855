import axios from "axios";
import { reactive, ref } from "vue";
import router from "../router";
import { useToast } from "vue-toastification";

class Auth {
  constructor() {
    this.errors = ref(null);
    this.loading = ref(false);
    this.loadingBtn = ref(false);
    this.submit = ref(false);
    this.meta = reactive({});
    this.message = ref(null);
    this.enterCode = ref(false);
    this.timeout = ref(null);
    this.token = ref(window.localStorage.getItem("token"));
    // let userData = window.localStorage.getItem("user");
    // this.user = ref(userData ? JSON.parse(userData) : {});
    this.user = ref({});
    this.editUser = reactive({});
    this.toast = useToast();
    if (this.token.value)
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.token.value;
  }

  async login(user) {
    this.loadingBtn.value = true;
    await axios
      .post("login", user)
      .then((res) => {
        this.loadingBtn.value = false;
        const data = res.data.data;
        this.setUser(data.token, data);
        this.toast.success(res.data.message);
        router.replace({ name: "user.home" });
      })
      .catch((error) => {
        window.networkError(error);
        const response = error.response;
        this.loadingBtn.value = false;
      });
  }

  async verification(user, token) {
    this.loadingBtn.value = true;
    await axios
      .post("verification", { ...user, verification_code: token })
      .then((res) => {
        const data = res.data.data;
        this.setUser(data.token, data);
        this.loadingBtn.value = false;
        this.toast.success(res.data.message);
        router.replace({ name: "user.home" });
      })
      .catch((error) => {
        window.networkError(error);
        const response = error.response;
        this.loadingBtn.value = false;
      });
  }

  async create() {
    this.loading.value = true;
    await axios
      .get("register")
      .then((res) => {
        this.loading.value = false;
        Object.assign(this.meta, res.data);
      })
      .catch((error) => {
        window.networkError(error);
        this.errors = error;
        this.loading.value = false;
      });
  }

  async register(user) {
    this.loadingBtn.value = true;
    await axios
      .post("register", user)
      .then((res) => {
        this.loadingBtn.value = false;
        const data = res.data;
        if (data.error) this.toast.error(data.message);
        else {
          this.enterCode.value = true;
          this.starTimer(data.timeout);
          this.toast.success(data.message);
        }
      })
      .catch((error) => {
        window.networkError(error);
        const response = error.response;
        this.loadingBtn.value = false;
      });
  }

  async getUser() {
    this.errors.value = "";
    this.loading.value = true;
    await axios
      .get("user")
      .then((res) => {
        this.loading.value = false;
        const data = res.data.data;
        Object.assign(this.editUser, data);
        const birthday = this.editUser.birthday?.split("/");
        if (birthday)
          Object.assign(this.editUser, {
            year: birthday[0],
            month: birthday[1],
            day: birthday[2],
          });
        this.setUser(this.token.value, data);
      })
      .catch((error) => {
        this.errors.value = error;
        this.loading.value = false;
      });
  }

  async updateUser() {
    window.progressDialog.value = true;
    await axios
      .put("user", this.editUser)
      .then((res) => {
        const data = res.data.data;
        this.setUser(this.token.value, data);
        this.toast.success(res.data.message);
        window.progressDialog.value = false;
      })
      .catch((error) => {
        window.networkError(error);
        window.progressDialog.value = false;
      });
  }

  async uploadImage(image) {
    window.progressDialog.value = true;
    const file = new FormData();
    file.append("image", image);
    file.append("type", 'avatar');
    file.append("store", '1');
    await axios
      .post("user/image", file, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        this.user.value.image = res.data.image;
        window.progressDialog.value = false;
        this.toast.success(res.data.message);
      })
      .catch((error) => {
        window.networkError(error);
        window.progressDialog.value = false;
      });
  }

  setUser(token, user) {
    localStorage.setItem("token", token);
    // localStorage.setItem("user", JSON.stringify(user));
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    this.token.value = token;
    this.user.value = user;
  }

  resetForm() {
    this.enterCode.value = false;
    this.timeout.value = null;
  }

  check() {
    return this.token.value != null;
  }

  async forgetPassword(user) {
    this.loadingBtn.value = true;
    await axios
      .post("password/forget", user)
      .then((res) => {
        this.loadingBtn.value = false;
        this.toast.success(res.data.message);
      })
      .catch((e) => {
        this.loadingBtn.value = false;
        networkError(e);
      });
  }

  async resetPassword(user) {
    this.loadingBtn.value = true;
    await axios
      .post("password/reset", user)
      .then((res) => {
        this.loadingBtn.value = false;
        const data = res.data.data;
        this.setUser(data.token, data);
        this.toast.success(res.data.message);
        router.replace({ name: "user.home" });
      })
      .catch((e) => {
        this.loadingBtn.value = false;
        networkError(e);
      });
  }

  async logout() {
    window.progressDialog.value = true;
    await axios
      .post("logout")
      .then((res) => {
        window.progressDialog.value = false;
        this.removeUser();
        router.push({ name: "login" });
        this.toast.success(res.data.message);
      })
      .catch((error) => {
        window.networkError(error);
        window.progressDialog.value = false;
      });
  }

  removeUser() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    this.user.value = {};
    this.token.value = null;
  }

  starTimer(duration) {
    duration = duration * 60;
    let auth = this;
    auth.timeout.value =
      window.i18n["Request again"] + " (0" + duration / 60 + ":00)";
    duration--;
    let timer = duration,
      minutes,
      seconds;
    let interval = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      auth.timeout.value =
        window.i18n["Request again"] + " (" + minutes + ":" + seconds + ")";
      if (timer < 0) {
        auth.timeout.value = null;
        clearInterval(interval);
      }
      timer--;
    }, 1000);
  }
}

export default new Auth();
