<template>
    <Form @submit="onSubmit" v-slot="{ errors }" @invalid-submit="onInvalidSubmit">
        <div class="flex flex-col">
            <div>
                <div class="mb-10  last:mb-0">
                    <h2 class="text-dark-550  dark:text-white font-bold flex items-center text-xl">
                        <i class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full "></i>
                        {{ state }}
                    </h2>
                    <div class="divider col-span-full mt-0 mb-3"></div>
                    <div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 1">
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Opportunity name') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="opportunity.name" rules="required"
                                    :class="{ 'input-error': errors.name }" class="input" type="text"
                                    name="name" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Account name') }} <span class="text-error">*</span>
                                </label>
                                <Field as="select" v-model="opportunity.account_id" :class="{ 'input-error': errors.account_id }"
                                    class="select" name="account_id" rules="required">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in meta.accounts" :value="item.id">{{
                                        item.name }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Currency') }} <span class="text-error">*</span>
                                </label>
                                <Field as="select" v-model="opportunity.currency" rules="required"
                                    :class="{ 'select-error': errors.currency }" class="select" name="currency">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in currencyTypes" :value="item">{{ __(item.capitalize()) }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Amount') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="opportunity.amount" :class="{ 'input-error': errors.amount }" class="input"
                                    type="number" name="amount" rules="required" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Sales stage') }} <span class="text-error">*</span>
                                </label>
                                <Field as="select" v-model="opportunity.sales_stage" rules="required"
                                    :class="{ 'input-error': errors.sales_stage }" class="select" name="sales_stage">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in salesStageTypes" :value="item">{{ __(item.replaceAll('_',' ').capitalize()) }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Sale date') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="opportunity.date_closed" name="date_closed" readonly class="input date_closed-input cursor-pointer" rules="required" :class="{'input-error': errors.date_closed}" />
                                <DatePicker v-model="opportunity.date_closed" custom-input=".date_closed-input" type="date" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Probability') }}
                                </label>
                                <Field v-model="opportunity.probability" :class="{ 'input-error': errors.probability }" class="input"
                                    type="text" name="probability" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Expected amount') }} 
                                </label>
                                <Field v-model="opportunity.amount_expected" :class="{ 'input-error': errors.amount_expected }" class="input"
                                    type="number" name="amount_expected" />
                            </div>
                        </div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 2">
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Lead source') }}
                                </label>
                                <Field as="select" v-model="opportunity.lead_source"
                                    :class="{ 'input-error': errors.lead_source }" class="select" name="lead_source">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in meta.lead_sources" :value="item.title">{{item.title }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Campaign') }}
                                </label>
                                <Field as="select" v-model="opportunity.campaign_id"
                                    :class="{ 'input-error': errors.campaign_id }" class="select" name="campaign_id">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in meta.campaigns" :value="item.id">{{ item.name }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Priority') }}
                                </label>
                                <Field as="select" v-model="opportunity.priority" :class="{ 'input-error': errors.priority }"
                                    class="select" name="priority">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in priorityTypes" :value="item">{{
                                        __(item.replace('_',' ').capitalize()) }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Type') }}
                                </label>
                                <Field as="select" v-model="opportunity.type" :class="{ 'input-error': errors.type }"
                                    class="select" name="type">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option value="existing_business">{{ __('Existing business') }}</option>
                                    <option value="new_business">{{ __('New business') }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Next step') }}
                                </label>
                                <Field v-model="opportunity.next_step" :class="{ 'input-error': errors.next_step }"
                                    class="input" type="text" name="next_step" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('The reason for the loss') }}
                                </label>
                                <Field as="select" v-model="opportunity.lost_reason" :class="{ 'input-error': errors.lost_reason }"
                                    class="select" name="lost_reason">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in lostReasonTypes" :value="item">{{ __(item.replaceAll('_',' ').capitalize())  }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Sales forecast') }}
                                </label>
                                <Field as="select" v-model="opportunity.forecast" :class="{ 'input-error': errors.forecast }"
                                    class="select" name="forecast">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option value="include">{{ __('Include') }}</option>
                                    <option value="exclude">{{ __('Exclude') }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Invoice sending periods') }}
                                </label>
                                <Field as="select" v-model="opportunity.invoicing_period" :class="{ 'input-error': errors.invoicing_period }"
                                    class="select" name="invoicing_period">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in periodTypes" :value="item">{{ __('Every') + ' ' + (item >= 12 ? item / 12 : item) + ' ' + __(item >= 12 ? 'Year' : 'Month')  }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('End date of the payment period') }}
                                </label>
                                <DatePicker v-model="opportunity.recurring_end_date" name="recurring_end_date" type="date" inputClass="input" />

                            </div>
                            <div class="flex flex-col col-span-full">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Description') }}
                                </label>
                                <Field as="textarea" v-model="opportunity.description"
                                    :class="{ 'input-error': errors.description }" class="textarea" type="text"
                                    name="description" />
                            </div>
                        </div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 3">
                            <div class="flex flex-col col-span-full">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('File') }}
                                </label>
                                <Field class="file-input pr-0" @change="addAttachment($event,'attachment')" type="file" name="file" />
                            </div>
                            <div class="overflow-x-auto col-span-full">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{{ __('File title') }}</th>
                                            <th>{{ __('File type') }}</th>
                                            <th>{{ __('Status') }}</th>
                                            <th>{{ __('Action') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,index) in opportunity.attachments">
                                            <th>{{ index + 1 }}</th>
                                            <th>{{ item.name }}</th>
                                            <td>{{ item.mime_type }}</td>
                                            <td>
                                                <div v-if="isNumeric(item.status)">
                                                    <progress class="progress progress-info w-16" :value="item.status" max="100"></progress> <span class="mr-0.5 inline-block w-4">{{ item.status + '%' }}</span>
                                                </div>
                                                <div v-else>{{ __(item.url ? 'Uploaded' : 'Uploading') }}</div>
                                            </td>
                                            <td>
                                    <button @click="removeFile(opportunity.attachments,index)" type="button"
                                        class="btn btn-outline btn-error btn-sm">
                                        {{ __('Delete') }}
                                    </button>
                                </td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="text-center" v-if="!opportunity.attachments.length">
                                        <tr>
                                            <td colspan="5">{{ __('No file found to display') }}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider h-auto w-full"></div>
            <div class="text-end">
                <button type="submit" class="btn btn-primary px-6 mr-2">{{
                    __('Save Changes')
                }}</button>
            </div>
        </div>
    </Form>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import useDashboard from '../../composables/dashboard';
import DatePicker from 'vue3-persian-datetime-picker'
import { Field, Form } from "vee-validate";
const props = defineProps(['opportunity','meta','step','state']);
const currencyTypes = ['rial','dollar', 'euro'];
const priorityTypes = ['very_high', 'high', 'medium', 'low', 'very_low'];
const lostReasonTypes = ['Went_with_competitor', 'not_interested', 'solution_is_expensive', 'solution_does_not_fulfill_needs'];
const salesStageTypes = ['prospecting', 'qualification', 'proposal_price_quote', 'negotiation_review', 'closed_won', 'closed_lost'];
const periodTypes = ['1', '2','3','4','6','12'];
const { uploadFile, removeFile,opportunity,storeOpportunity,updateOpportunity,route,loading,message,checkNumber} = useDashboard();
Object.assign(opportunity,props.opportunity);
const addAttachment = (event,type) => {
    opportunity.attachments = [...opportunity.attachments,{title: event.target.files[0].name,type: 'attachment',status: 'uploading'}];
    uploadFile(event,type,opportunity.attachments[opportunity.attachments.length - 1]);
}
function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

const onSubmit = () => {
    if(route.params.id) updateOpportunity();
    else storeOpportunity();
}
const onInvalidSubmit = ({ errors }) => {
   
};
</script>