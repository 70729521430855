<template>
  <main class="flex min-h-screen w-full items-center  justify-center flex-col sm:px-0 px-3  pb-16 pt-20">
    <a href="/" class="mb-6">
      <img :src="logo" class="flex" alt="logo">
    </a>
    <!-- <router-link :to="{name:'home'}" class="mb-10">
      <img :src="logo" class="flex" alt="logo">
    </router-link> -->
    <section
      class="bg-white dark:bg-base-300 dark:shadow-whiteShadow  py-9 sm:px-13 px-5 rounded-2xl shadow-sm  sm:w-467 w-full">

      <div class="mb-4 text-center">
        <h2 class="sm:text-26 text-22 text-dark-550 dark:text-white font-semibold mb-1">{{ __('Reset Password') }}</h2>
        <h6 class="sm:text-15 text-13 text-gray-300 dark:text-gray-210 font-medium">
          {{ __('Enter your new password and remember it') }}
        </h6>
      </div>


      <div>
        <Form @submit="onSubmit" class="h-full flex flex-col px-4" v-slot="{ errors }">
          <div class="flex-1">
            <div class="form-control mt-3">
              <div>
                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-mediuminput" for="">
                  {{ __('Username') }} <span class="text-error">*</span>
                </label>
                <Field type="email" name="email" v-model="user.email" :validateOnBlur="false" :validateOnInput="true" readonly
                  rules="required" :class="{ 'input-error': errors.email }" class="input" />
              </div>
              <div>
                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                  {{ __('Password') }} <span class="text-error">*</span>
                </label>
                <Field v-model="user.password" :rules="$route.params.id ? '' : 'required'"
                  :class="{ 'input-error': errors.password }" class="input" type="password" name="password" />
              </div>
              <div>
                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                  {{ __('Confirm Password') }} <span class="text-error">*</span>
                </label>
                <Field v-model="user.password_confirmation" :rules="$route.params.id ? '' : 'required'"
                  :class="{ 'input-error': errors.password_confirmation }" class="input" type="password"
                  name="password_confirmation" />
              </div>
            </div>
          </div>
          <div class="form-control">
            <button type="submit" class="btn btn-primary mt-3" :disabled="loading">
              <span class="loading loading-spinner" v-if="loading"></span>
              {{ __('login') }}
            </button>
          </div>
        </Form>
      </div>
    </section>
  </main>
</template>
<script setup>
import Auth from "../../composables/auth";
import { Field, Form } from "vee-validate";
import { computed, reactive, ref } from 'vue';
import { useRoute } from 'vue-router'
const loading = Auth.loadingBtn;
const route = useRoute();
const user = reactive({
        email: route.params.email,
        token: route.params.token
});
const onSubmit = () => Auth.resetPassword(user)
const logo = window.header.logo;
</script>
