<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!account.first_name && errors" @tryAgain="getData" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <div class="flex justify-between items-center mb-6">
                <h3 class="flex items-center md:text-26 text-2xl dark:text-white text-biscay-700 font-semibold">
                    <i class="md:flex hidden w-2 h-2 dark:bg-white rounded-full bg-blue-700 ml-2"></i>
                    {{ __('Account details') }}
                </h3>
                <div class="flex justify-end order-first lg:order-last">
                        <router-link :to="{name: 'user.account.edit',params: {id: account.id}}" class="btn btn-outline btn-info btn-sm"
                        :class="{'btn-disabled': !can('account.edit.all') && !can('account.edit.self',account.self)}">
                            {{ __('Edit') }}
                        </router-link>
                        <label @click="setItem(account)" for="delete-modal" :class="{'btn-disabled': !can('account.delete.all') && !can('account.delete.self',account.self)}"
                                        class="btn btn-outline btn-error btn-sm mr-2">
                                        {{ __('Delete') }}
                        </label>
                    </div>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse-plus collapse">
                <input type="checkbox" checked />
                <div class="collapse-title text-xl font-medium">
                    {{ __('personal information') }}
                </div>
                <div class="collapse-content">
                    <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                        <p class="text-gray-70">{{ __('Type') }}: <span class="text-black dark:text-white">{{
                            __(account.type?.capitalize())
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Name') }}: <span class="text-black dark:text-white">{{
                            account.name
                                }}</span></p>
                        <p class="text-gray-70" v-if="account.emails.filter(item => item.value).length">{{ __('Email') }}: <span class="text-black dark:text-white"
                                v-for="item in account.emails.filter(item => item.value)">{{
                                    item.value
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Website') }}: <span class="text-black dark:text-white">{{
                            account.website || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Account type') }}: <span class="text-black dark:text-white">{{
                            __(account.account_type?.capitalize()) || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Account ID') }}: <span class="text-black dark:text-white">{{
                            account.account_id || '---' }}</span></p>
                               <p class="text-gray-70">{{ __('Customer code') }}: <span class="text-black dark:text-white">{{
                            account.account_code || '---' }}</span></p>
                              <p class="text-gray-70">{{ __('User') }}: <span class="text-black dark:text-white">{{
                            account.user }}</span></p>
                        <p class="text-gray-70">{{ __('Created At') }}: <span class="text-black dark:text-white">{{
                            account.created_at }}</span></p>
                        <p class="text-gray-70">{{ __('Update date') }}: <span class="text-black dark:text-white">{{
                            account.updated_at }}</span></p>
                        <div class="divider h-auto col-span-full my-0"
                            v-if="account.numbers.filter(item => item.value).length"></div>
                        <p class="text-gray-70" v-for="item in account.numbers.filter(item => item.value)">{{
                            __(item.type.capitalize()) }}: <span class="text-black dark:text-white">{{ item.value
                                }}</span></p>
                        <div class="divider h-auto col-span-full my-0"></div>
                        <p class="text-gray-70">{{ __('Description') }}: <span class="text-black dark:text-white">{{
                            account.description || '---' }}</span></p>
                    </div>
                </div>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('More information') }}
                </div>
                <div class="collapse-content">
                    <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                        <p class="text-gray-70">{{ __('The level of attractiveness') }}: <span
                                class="text-black dark:text-white">{{
                                    __(account.rating?.capitalize()) || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Campaign') }}: <span class="text-black dark:text-white">{{
                            account.campaign_name || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Industry') }}: <span class="text-black dark:text-white">{{
                            account.industry || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Sub industry') }}: <span class="text-black dark:text-white">{{
                            account.subindustry || '---' }}</span></p>
                             <p class="text-gray-70">{{ __('Member') }}: <span class="text-black dark:text-white">{{
                            account.parent_name || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Another name') }}: <span class="text-black dark:text-white">{{
                            account.name_alias || '---' }}</span></p>
                                 <p class="text-gray-70">{{ __('Ownership') }}: <span class="text-black dark:text-white">{{
                            account.ownership || '---' }}</span></p>
                                 <p class="text-gray-70">{{ __('Economic code') }}: <span class="text-black dark:text-white">{{
                            account.tax_id || '---' }}</span></p>
                                 <p class="text-gray-70">{{ __('Registration number') }}: <span class="text-black dark:text-white">{{
                            account.registration_number || '---' }}</span></p>
                             <p class="text-gray-70">{{ __('National Number/National ID') }}: <span class="text-black dark:text-white">{{
                            account.national_number || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Following') }}: <span class="text-black dark:text-white">{{
                            __(account.is_foreigner ? 'Yes' : 'No') }}</span></p>
                    </div>
                </div>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('Address') }}
                </div>
                <div class="collapse-content">
                    <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                        <p class="text-gray-70">{{ __('Country') }}: <span class="text-black dark:text-white">{{
                            account.billing.country || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('State') }}: <span class="text-black dark:text-white">{{
                            account.billing.state || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('City') }}: <span class="text-black dark:text-white">{{
                            account.billing.city || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Postal Code') }}: <span class="text-black dark:text-white">{{
                            account.billing.postalcode || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Address') }}: <span class="text-black dark:text-white">{{
                            account.billing.street || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Latitude') }}: <span class="text-black dark:text-white">{{
                            account.billing.latitude || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Longitude') }}: <span class="text-black dark:text-white">{{
                            account.billing.longitude || '---' }}</span></p>
                    </div>
                </div>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('Validation') }}
                </div>
                <div class="collapse-content">
                    <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                        <p class="text-gray-70">{{ __('Credit status') }}: <span class="text-black dark:text-white">{{
                            __(account.credit.status?.replace('_',' ')?.capitalize()) || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Credit type') }}: <span class="text-black dark:text-white">{{
                            __(account.credit.type?.replace('_',' ')?.capitalize()) || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Expiration date') }}: <span class="text-black dark:text-white">{{
                            account.credit.expiration_date || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Credit source') }}: <span class="text-black dark:text-white">{{
                            __(account.credit.source?.replace('_',' ')?.capitalize()) || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Credit limit') }}: <span class="text-black dark:text-white">{{
                           setCurrency(account.credit.limit) || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Sales discount (%)') }}: <span class="text-black dark:text-white">{{
                            account.credit.sales_discount || '---' }}</span></p>
                        <p class="text-gray-70 col-span-full">{{ __('Credit source description') }}: <span class="text-black dark:text-white">{{
                            account.credit.source_description || '---' }}</span></p>
                    </div>
                </div>
            </div>
            <div v-if="account.social.length"
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('Social') }}
                </div>
                <div class="collapse-content">
                    <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                        <p class="text-gray-70" v-for="(item, key) in account.social">{{ __(key?.capitalize()) }}: <span
                                class="text-black dark:text-white">{{
                                    item || '---'
                                }}</span></p>
                    </div>
                </div>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('Appendices') }}
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table table-xs md:table-sm xl:table-md">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{{ __('File title') }}</th>
                                    <th>{{ __('File type') }}</th>
                                    <th>{{ __('Status') }}</th>
                                    <th>{{ __('Action') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in account.attachments">
                                    <th>{{ index + 1 }}</th>
                                    <th>{{ item.name }}</th>
                                    <td>{{ item.mime_type }}</td>
                                    <td>
                                        {{ __(item.url ? 'Uploaded' : 'Uploading') }}
                                    </td>
                                    <td>
                                        <a :href="item.url" target="_blank"
                                            class="btn btn-outline btn-info btn-sm">
                                            {{ __('Show') }}
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="text-center" v-if="!account.attachments.length">
                                <tr>
                                    <td colspan="5">{{ __('No file found to display') }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Teleport to="#app">
        <input type="checkbox" id="delete-modal" class="modal-toggle" />
        <label class="modal" for="delete-modal">
            <label class="modal-box" for="">
                <h3 class="font-bold text-lg">{{ __('Delete account') }}</h3>
                <p class="py-4">{{ __('Are you sure to delete this account?') }}</p>
                <div class="modal-action">
                    <label class="btn btn-sm" for="delete-modal"
                        :class="{ 'pointer-events-none opacity-60': loadingBtn }">{{
                            __('Cancel') }}</label>
                    <button class="btn btn-error btn-sm text-white" @click="removeAccount"
                        :class="{ 'loading': loadingBtn }">
                        {{ __('Delete') }}
                    </button>
                </div>
            </label>
        </label>
    </Teleport>
</template>
<script setup>
import useDashboard from "../../composables/dashboard";
import { computed, onMounted, reactive, ref } from 'vue';
const { getAccount, account, route, loading, meta, errors,remove, removeAccount, loadingBtn } = useDashboard();
const setItem = (item) => Object.assign(remove,{id:item.id,single:true});
const setCurrency = (value) => window.setCurrency(value);
const getData = () => {
    getAccount();
}
onMounted(() => {
    getData();
});
</script>