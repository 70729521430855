<template>
    <svg class="" width="390" height="278" viewBox="0 0 390 278" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.4">
            <path
                d="M201.086 68.018H116.479C115.181 68.018 114.171 67.0082 114.171 65.7099V16.3736C114.171 15.0752 115.181 14.0654 116.479 14.0654H201.086C202.385 14.0654 203.395 15.0752 203.395 16.3736V65.6377C203.395 66.9361 202.385 68.018 201.086 68.018Z"
                stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10"></path>
            <path d="M114.17 23.8032H203.394" stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10"></path>
            <path
                d="M119.94 20.4848C120.736 20.4848 121.382 19.8389 121.382 19.0422C121.382 18.2455 120.736 17.5996 119.94 17.5996C119.143 17.5996 118.497 18.2455 118.497 19.0422C118.497 19.8389 119.143 20.4848 119.94 20.4848Z"
                stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10"></path>
            <path
                d="M125.782 20.4848C126.579 20.4848 127.225 19.8389 127.225 19.0422C127.225 18.2455 126.579 17.5996 125.782 17.5996C124.986 17.5996 124.34 18.2455 124.34 19.0422C124.34 19.8389 124.986 20.4848 125.782 20.4848Z"
                stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10"></path>
            <path
                d="M131.697 20.4848C132.494 20.4848 133.14 19.8389 133.14 19.0422C133.14 18.2455 132.494 17.5996 131.697 17.5996C130.901 17.5996 130.255 18.2455 130.255 19.0422C130.255 19.8389 130.901 20.4848 131.697 20.4848Z"
                stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10"></path>
            <path
                d="M2.58684 107.257C3.46323 107.257 4.17368 106.547 4.17368 105.67C4.17368 104.794 3.46323 104.083 2.58684 104.083C1.71045 104.083 1 104.794 1 105.67C1 106.547 1.71045 107.257 2.58684 107.257Z"
                stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round"></path>
            <path
                d="M272.639 192.946C273.515 192.946 274.225 192.235 274.225 191.359C274.225 190.482 273.515 189.772 272.639 189.772C271.762 189.772 271.052 190.482 271.052 191.359C271.052 192.235 271.762 192.946 272.639 192.946Z"
                stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round"></path>
            <path
                d="M383.067 60.9496C383.944 60.9496 384.654 60.2391 384.654 59.3627C384.654 58.4863 383.944 57.7759 383.067 57.7759C382.191 57.7759 381.48 58.4863 381.48 59.3627C381.48 60.2391 382.191 60.9496 383.067 60.9496Z"
                stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round"></path>
            <path
                d="M296.296 11.2533C297.172 11.2533 297.883 10.5428 297.883 9.66643C297.883 8.79005 297.172 8.07959 296.296 8.07959C295.419 8.07959 294.709 8.79005 294.709 9.66643C294.709 10.5428 295.419 11.2533 296.296 11.2533Z"
                stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round"></path>
            <path d="M381.481 111.945V116.922" stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M383.934 114.469H378.957" stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M251.36 207.805V212.782" stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M253.885 210.329H248.908" stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M146.122 0.505371V5.48228" stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M148.575 2.95752H143.598" stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M34.6846 48.6162V53.5931" stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M37.1371 51.0684H32.1602" stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M26.6064 163.156V168.133" stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M29.0589 165.681H24.082" stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M308.702 28.6357V33.6127" stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M311.227 31.1602H306.25" stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10"
                stroke-linecap="round" stroke-linejoin="round"></path>
            <path
                d="M63.6087 134.882H13.7675C12.902 134.882 12.1807 134.161 12.1807 133.295C12.1807 132.43 12.902 131.708 13.7675 131.708H63.6087C64.4743 131.708 65.1956 132.43 65.1956 133.295C65.1956 134.161 64.4743 134.882 63.6087 134.882Z"
                stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10"></path>
            <path
                d="M356.884 95.0672H307.043C306.177 95.0672 305.456 94.346 305.456 93.4804C305.456 92.6149 306.177 91.8936 307.043 91.8936H356.884C357.75 91.8936 358.471 92.6149 358.471 93.4804C358.471 94.346 357.75 95.0672 356.884 95.0672Z"
                stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10"></path>
            <path
                d="M364.171 105.67H345.778C345.345 105.67 344.984 105.31 344.984 104.877C344.984 104.444 345.345 104.083 345.778 104.083H364.171C364.603 104.083 364.964 104.444 364.964 104.877C364.964 105.31 364.603 105.67 364.171 105.67Z"
                fill="#C0CCD8"></path>
            <path
                d="M315.411 68.1625H297.018C296.585 68.1625 296.225 67.8019 296.225 67.3691C296.225 66.9363 296.585 66.5757 297.018 66.5757H315.411C315.844 66.5757 316.204 66.9363 316.204 67.3691C316.204 67.8019 315.844 68.1625 315.411 68.1625Z"
                fill="#C0CCD8"></path>
            <path
                d="M45.0705 120.961H31.0053C30.5726 120.961 30.2119 120.601 30.2119 120.168C30.2119 119.735 30.5726 119.375 31.0053 119.375H45.0705C45.5033 119.375 45.8639 119.735 45.8639 120.168C45.8639 120.601 45.5033 120.961 45.0705 120.961Z"
                fill="#C0CCD8"></path>
            <path
                d="M329.404 51.4289H344.623C346.066 51.4289 347.292 50.2027 347.292 48.7601V33.5408C347.292 32.0983 346.066 30.8721 344.623 30.8721H329.404C327.962 30.8721 326.735 32.0983 326.735 33.5408V48.7601C326.735 50.2027 327.889 51.4289 329.404 51.4289Z"
                stroke="#C0CCD8" stroke-width="0.724522" stroke-miterlimit="10" stroke-linejoin="round"></path>
            <path
                d="M339.935 40.2489L339.575 42.0522H341.522V43.639H339.286L338.709 46.7406H337.05L337.627 43.639H335.463L334.886 46.7406H333.227L333.804 43.639H332.001V42.0522H334.093L334.453 40.2489H332.506V38.6621H334.742L335.319 35.5605H336.978L336.401 38.6621H338.565L339.142 35.5605H340.801L340.224 38.6621H342.027V40.2489H339.935ZM335.68 42.0522H337.843L338.204 40.2489H336.04L335.68 42.0522Z"
                fill="#C0CCD8"></path>
            <path
                d="M163.651 193.812L149.225 98.9624H84.0928L99.6727 199.438C100.755 206.218 106.381 211.051 113.449 211.051H222.941L223.735 201.818H173.172C168.267 201.891 164.372 198.573 163.651 193.812Z"
                fill="#64748B"></path>
            <path
                d="M101.982 193.812L88.0606 102.497C87.7721 100.405 86.041 98.9624 83.9492 98.9624H83.805C80.8477 98.9624 78.3953 101.775 78.8281 104.588L93.5424 199.438C94.6243 206.218 100.25 211.051 107.319 211.051H177.861L178.655 201.818H111.503C106.67 201.891 102.703 198.573 101.982 193.812Z"
                fill="#717A91"></path>
            <path d="M146.123 211.123V262.839" stroke="#98A3B8" stroke-width="1.08678" stroke-miterlimit="10"></path>
            <path
                d="M117.058 267.455C117.058 264.93 119.077 262.911 121.602 262.911H170.505C173.03 262.911 175.049 264.93 175.049 267.455"
                stroke="#98A3B8" stroke-width="1.08678" stroke-miterlimit="10"></path>
            <path d="M141.077 267.455C141.077 264.931 143.097 262.911 145.621 262.911" stroke="#98A3B8"
                stroke-width="1.08678" stroke-miterlimit="10"></path>
            <path
                d="M117.057 276.687C119.606 276.687 121.673 274.62 121.673 272.071C121.673 269.521 119.606 267.455 117.057 267.455C114.507 267.455 112.44 269.521 112.44 272.071C112.44 274.62 114.507 276.687 117.057 276.687Z"
                fill="#717A91"></path>
            <g opacity="0.45">
                <path opacity="0.45"
                    d="M117.058 273.803C118.014 273.803 118.789 273.028 118.789 272.071C118.789 271.115 118.014 270.34 117.058 270.34C116.102 270.34 115.327 271.115 115.327 272.071C115.327 273.028 116.102 273.803 117.058 273.803Z"
                    fill="#8E9DB2"></path>
            </g>
            <path
                d="M141.076 276.687C143.626 276.687 145.692 274.62 145.692 272.071C145.692 269.521 143.626 267.455 141.076 267.455C138.527 267.455 136.46 269.521 136.46 272.071C136.46 274.62 138.527 276.687 141.076 276.687Z"
                fill="#717A91"></path>
            <g opacity="0.45">
                <path opacity="0.45"
                    d="M141.078 273.803C142.034 273.803 142.809 273.028 142.809 272.071C142.809 271.115 142.034 270.34 141.078 270.34C140.122 270.34 139.347 271.115 139.347 272.071C139.347 273.028 140.122 273.803 141.078 273.803Z"
                    fill="#8E9DB2"></path>
            </g>
            <path
                d="M175.123 276.687C177.673 276.687 179.739 274.62 179.739 272.071C179.739 269.521 177.673 267.455 175.123 267.455C172.574 267.455 170.507 269.521 170.507 272.071C170.507 274.62 172.574 276.687 175.123 276.687Z"
                fill="#717A91"></path>
            <g opacity="0.45">
                <path opacity="0.45"
                    d="M175.124 273.802C176.08 273.802 176.855 273.027 176.855 272.071C176.855 271.115 176.08 270.34 175.124 270.34C174.168 270.34 173.393 271.115 173.393 272.071C173.393 273.027 174.168 273.802 175.124 273.802Z"
                    fill="#8E9DB2"></path>
            </g>
            <path d="M196.976 109.132L213.493 84.4634L218.614 85.5453C214.719 98.7449 212.123 103.938 212.123 103.938"
                fill="#D6DAE3"></path>
            <path
                d="M216.017 89.6566L218.614 85.5453C218.614 85.5453 223.519 84.3912 225.322 83.5257C227.125 82.588 228.279 81.0011 228.784 79.6307C229.145 78.6209 229 75.5193 228.568 75.4472C227.486 75.3029 225.394 79.27 224.456 79.4864C223.446 79.7028 220.273 79.4864 218.542 79.7028C215.584 80.0635 214.358 82.8765 214.358 82.8765L211.689 87.7813"
                fill="#D6DAE3"></path>
            <path d="M207.794 91.7483L210.679 87.4927L218.181 90.8827L215.8 97.2301" fill="#E6E8EF"></path>
            <path
                d="M147.566 82.9489C147.566 82.9489 159.756 77.3228 170.576 86.916C181.323 96.5091 188.608 105.381 192.575 108.266L208.804 89.5847L217.892 93.8404C215.512 107.329 208.804 128.895 192.359 131.131C174.615 133.511 148.576 111.656 148.576 111.656"
                fill="#7C8692"></path>
            <path d="M187.309 114.469L192.574 108.266" stroke="#475466" stroke-width="0.724522" stroke-miterlimit="10">
            </path>
            <path
                d="M213.204 268.898L211.834 274.164C211.473 275.462 212.483 276.688 213.781 276.688H229.722C230.659 276.688 231.453 275.895 231.453 274.957C231.453 274.236 230.948 273.515 230.227 273.298L219.335 268.898"
                fill="#64748B"></path>
            <path
                d="M204.043 186.527C201.518 213.359 213.275 268.826 213.275 268.826C213.275 268.826 216.088 272.216 219.478 268.826C219.478 268.826 222.291 215.883 225.321 192.081"
                fill="#D6DAE3"></path>
            <path
                d="M165.959 155.151L184.28 164.744V164.96C184.28 164.96 221.715 162.436 227.341 175.78C236.213 196.697 237.728 264.715 237.728 264.715H210.319C210.319 264.715 200.148 207.589 196.614 204.127C193.152 200.664 178.798 200.881 152.327 200.881C122.682 200.881 137.108 162.652 148.215 159.334"
                fill="#98A3B8"></path>
            <path
                d="M212.195 264.715C212.195 264.715 204.044 205.786 198.058 201.458C190.773 196.193 156.295 199.655 156.295 199.655"
                stroke="#64748B" stroke-width="0.724522" stroke-miterlimit="10"></path>
            <path
                d="M155.788 268.898L154.418 274.164C154.057 275.462 155.067 276.688 156.365 276.688H172.306C173.243 276.688 174.037 275.895 174.037 274.957C174.037 274.236 173.532 273.515 172.811 273.298L161.919 268.898"
                fill="#64748B"></path>
            <path
                d="M158.458 186.527C155.933 213.359 155.789 268.826 155.789 268.826C155.789 268.826 158.602 272.216 161.992 268.826C161.992 268.826 171.585 215.883 174.615 192.081"
                fill="#D6DAE3"></path>
            <path
                d="M133.575 163.59C133.575 163.59 181.685 164.384 187.96 178.954C195.678 196.842 182.911 264.787 182.911 264.787H153.916V204.055C153.916 202.901 152.978 201.819 151.824 201.819C147.785 201.891 138.841 201.891 127.516 201.891C97.8712 201.891 112.297 155.728 123.405 152.41"
                fill="#98A3B8"></path>
            <path
                d="M133.575 163.59C133.575 163.59 181.685 164.384 187.961 178.954C190.052 183.786 191.134 192.081 190.557 201.891"
                stroke="#64748B" stroke-width="0.724522" stroke-miterlimit="10"></path>
            <path
                d="M158.028 264.715C158.028 264.715 158.317 203.766 156.946 200.52C154.999 195.976 148.363 198.212 132.927 198.212C103.282 198.212 115.328 155.728 126.363 152.482"
                stroke="#64748B" stroke-width="0.724522" stroke-miterlimit="10"></path>
            <path
                d="M115.111 171.02C115.111 171.02 130.331 171.38 132.639 169.072C134.947 166.764 132.639 156.305 132.639 156.305"
                stroke="#64748B" stroke-width="0.724522" stroke-miterlimit="10"></path>
            <path
                d="M114.751 173.039C114.751 173.039 132.134 173.4 134.803 171.092C137.472 168.783 134.803 158.325 134.803 158.325"
                stroke="#64748B" stroke-width="0.724522" stroke-miterlimit="10" stroke-dasharray="2.17 2.17"></path>
            <path
                d="M111.789 100.116C111.789 100.116 108.111 164.96 113.665 164.96C119.218 164.96 180.6 164.744 184.207 164.744C187.813 164.744 179.374 109.277 167.256 97.5919C167.256 97.5919 149.296 78.0449 141.362 79.9203C133.428 81.7956 111.789 100.116 111.789 100.116Z"
                fill="#7C8692"></path>
            <path d="M180.385 129.617L172.739 98.3135" stroke="#475466" stroke-width="0.724522" stroke-miterlimit="10">
            </path>
            <path d="M80.7745 111.874L64.2569 87.2056L59.1357 88.2875C63.0307 101.487 65.6274 106.68 65.6274 106.68"
                fill="#D6DAE3"></path>
            <path
                d="M61.733 92.3266L59.1363 88.2152C59.1363 88.2152 54.2315 87.0611 52.4283 86.1956C50.6251 85.2579 49.471 83.6711 48.9661 82.3006C48.6055 81.2908 48.7497 78.1893 49.1825 78.1171C50.2644 77.9729 52.3562 81.94 53.2939 82.1563C54.3037 82.3727 57.4774 82.1563 59.2085 82.3727C62.1657 82.7334 63.3919 85.5464 63.3919 85.5464L66.0607 90.4512"
                fill="#D6DAE3"></path>
            <path d="M69.955 94.4183L67.0698 90.2349L59.5684 93.5528L61.9486 99.9723" fill="#E6E8EF"></path>
            <path
                d="M134.513 83.3104C134.513 83.3104 122.467 74.727 109.989 85.2579C98.3763 95.0675 90.5864 114.903 87.9897 114.903C86.1865 114.903 75.0065 100.621 68.2985 91.8938L60.3643 96.1494C65.3412 111.441 76.3048 137.407 92.606 137.407C105.445 137.407 131.7 107.402 131.7 107.402"
                fill="#7C8692"></path>
            <path d="M110.78 127.886L130.255 111.657" stroke="#475466" stroke-width="0.724522" stroke-miterlimit="10">
            </path>
            <path d="M135.666 84.8254L147.279 97.2316L148.793 82.8779" fill="#E6E8EF"></path>
            <path d="M135.666 84.8254L147.279 97.2316L148.793 82.8779" stroke="#475466" stroke-width="0.724522"
                stroke-miterlimit="10" stroke-linejoin="round"></path>
            <path
                d="M144.97 72.2745L148.216 79.4153C148.216 79.4153 149.514 83.5266 144.104 85.402C138.118 87.4216 134.511 83.3102 134.511 83.3102L133.646 72.0581"
                fill="#D6DAE3"></path>
            <path
                d="M134.225 79.1992C134.225 79.1992 138.552 84.6089 144.178 85.4023L140.067 91.4612C136.749 89.5858 132.71 82.3008 132.71 82.3008L134.225 79.1992Z"
                fill="white"></path>
            <path
                d="M147.927 78.478C147.927 78.478 147.206 82.3009 144.177 85.4024L148.865 88.9367C149.947 86.268 149.803 82.1566 149.803 82.1566L147.927 78.478Z"
                fill="white"></path>
            <path
                d="M149.875 60.7342C149.875 60.7342 152.977 72.5634 148.361 75.5206C143.744 78.4779 135.666 73.9338 135.233 72.4912C134.8 70.9765 132.636 58.426 135.666 57.4884C141.003 55.7573 148.288 55.6851 149.875 60.7342Z"
                fill="#D6DAE3"></path>
            <path
                d="M149.305 65.2584C149.615 65.1855 149.808 64.875 149.735 64.5647C149.662 64.2544 149.352 64.0619 149.041 64.1348C148.731 64.2076 148.539 64.5181 148.611 64.8284C148.684 65.1387 148.995 65.3312 149.305 65.2584Z"
                fill="#64748B"></path>
            <path
                d="M148.723 61.022C148.939 61.022 149.805 61.1663 149.66 61.5991C149.588 61.8155 149.228 61.8155 149.083 61.8155C148.867 61.8155 148.723 61.7433 148.506 61.8155C148.578 61.8876 148.578 61.9597 148.651 62.0318C148.723 62.1761 148.29 62.1761 148.218 62.1761C147.785 62.1761 147.208 62.104 146.703 62.4646C146.992 61.1663 148.362 60.9499 148.723 61.022Z"
                fill="#64748B"></path>
            <path
                d="M142.993 66.7219C143.311 66.7043 143.555 66.432 143.537 66.1138C143.519 65.7956 143.247 65.5519 142.929 65.5696C142.61 65.5872 142.367 65.8595 142.385 66.1777C142.402 66.4959 142.674 66.7396 142.993 66.7219Z"
                fill="#64748B"></path>
            <path
                d="M140.572 63.7625C140.14 63.9788 139.779 64.3395 139.851 64.7001C139.923 64.9887 140.212 64.9165 140.356 64.7723C140.644 64.5559 141.005 64.3395 141.366 64.2674C141.221 64.4116 141.149 64.628 141.149 64.8444C142.303 64.7001 143.241 63.7625 143.385 62.6084C143.385 62.6805 142.664 63.1133 142.52 63.1854C141.871 63.4739 141.149 63.4739 140.572 63.7625Z"
                fill="#64748B"></path>
            <path
                d="M146.774 65.2778C146.774 65.2778 148.433 69.3892 147.856 69.7498C147.279 70.1105 145.188 70.0384 145.188 70.0384"
                stroke="#98A3B8" stroke-width="0.724522" stroke-miterlimit="10"></path>
            <path d="M146.774 76.1695C146.774 76.1695 142.086 77.9727 137.975 74.6548" stroke="#98A3B8"
                stroke-width="0.724522" stroke-miterlimit="10"></path>
            <path d="M144.177 73.4287C144.177 73.4287 144.61 71.6255 147.567 71.6255" stroke="#98A3B8"
                stroke-width="0.724522" stroke-miterlimit="10"></path>
            <path
                d="M152.472 54.3865C152.4 53.8095 152.328 53.2325 151.967 52.7997C151.534 52.2948 150.813 52.2227 150.164 52.2227C147.928 52.2227 145.62 52.9439 143.816 54.2423C144.321 53.8095 144.826 53.3767 145.331 52.9439C145.764 52.5833 146.629 52.0784 146.197 51.4292C145.836 50.8522 144.754 51.2128 144.177 51.1407C141.58 51.1407 139.128 52.0063 136.748 52.9439C135.594 53.3767 134.367 53.8816 133.358 54.5308C132.276 55.18 131.338 56.1176 130.833 57.2717C130.4 58.2815 130.328 59.4356 130.328 60.5175C130.328 62.465 130.617 64.4846 130.977 66.4321C131.338 68.596 131.843 72.7794 134.656 72.9958C135.233 73.068 135.81 73.068 136.243 72.7794C136.675 72.4909 136.82 71.8418 136.892 71.3369C137.036 70.4713 136.964 69.5336 136.748 68.6681C137.253 68.9566 137.757 69.4615 138.407 69.3894C138.623 69.3894 138.839 69.2451 138.912 69.0287C138.984 68.8845 138.912 68.8123 138.912 68.6681C138.695 66.6485 138.551 64.6289 138.407 62.5371C141.364 61.888 144.249 61.3831 147.206 60.9503C148.505 60.7339 149.875 60.5175 150.957 59.6519C152.4 58.4258 152.616 56.2619 152.472 54.3865Z"
                fill="#64748B"></path>
            <path
                d="M137.324 69.3893C136.82 68.1631 135.954 66.7205 134.656 67.5861C134.151 67.8746 133.934 68.5238 133.718 69.1008C133.141 71.0483 134.511 74.1498 136.459 73.8613C137.397 73.7171 137.902 72.563 138.334 71.6974"
                fill="#D6DAE3"></path>
            <path d="M58.2695 276.688L81.423 165.609" stroke="#98A3B8" stroke-width="1.08678" stroke-miterlimit="10">
            </path>
            <path d="M104.576 276.688L81.4229 165.609" stroke="#98A3B8" stroke-width="1.08678" stroke-miterlimit="10">
            </path>
            <path d="M292.184 276.688L315.337 165.609" stroke="#98A3B8" stroke-width="1.08678" stroke-miterlimit="10">
            </path>
            <path d="M338.563 276.688L315.338 165.609" stroke="#98A3B8" stroke-width="1.08678" stroke-miterlimit="10">
            </path>
            <path d="M335.678 158.973H58.9912V165.609H335.678V158.973Z" fill="#E7EAF2"></path>
            <path
                d="M322.333 146.279H224.599C222.867 146.279 221.569 144.692 221.858 142.961L233.11 79.7757C233.47 77.7561 235.202 76.3135 237.221 76.3135H334.956C336.687 76.3135 337.985 77.9003 337.697 79.6314L326.517 142.817C326.156 144.836 324.353 146.279 322.333 146.279Z"
                fill="#64748B"></path>
            <path
                d="M324.641 146.278H226.834C225.103 146.278 223.804 144.691 224.093 142.96L235.345 79.7752C235.706 77.7556 237.437 76.313 239.457 76.313H337.264C338.995 76.313 340.293 77.8998 340.004 79.6309L328.752 142.816C328.392 144.836 326.661 146.278 324.641 146.278Z"
                fill="#C0CCD8"></path>
            <path opacity="0.45"
                d="M306.61 146.279H250.132C249.123 146.279 248.329 145.341 248.546 144.403L255.037 107.906C255.254 106.752 256.263 105.886 257.417 105.886H313.895C314.904 105.886 315.698 106.824 315.481 107.762L308.99 144.259C308.846 145.413 307.836 146.279 306.61 146.279Z"
                fill="#8E9DB2"></path>
            <path
                d="M307.981 146.279H251.504C250.494 146.279 249.7 145.341 249.917 144.403L256.408 107.906C256.625 106.752 257.634 105.886 258.789 105.886H315.266C316.275 105.886 317.069 106.824 316.853 107.762L310.361 144.259C310.144 145.413 309.135 146.279 307.981 146.279Z"
                fill="#98A3B8"></path>
            <path d="M295.501 124.784H272.564V146.278H295.501V124.784Z" fill="#8E9DB2"></path>
            <path
                d="M294.131 124.784H272.492V130.915L278.407 155.366H301.344L294.131 124.784ZM288.577 148.947C285.98 148.947 283.312 145.918 282.518 142.239C281.725 138.56 283.167 135.531 285.764 135.531C288.361 135.531 291.029 138.56 291.823 142.239C292.616 145.918 291.174 148.947 288.577 148.947Z"
                fill="#64748B"></path>
            <path d="M259.942 155.439H311.298V158.901H256.48C256.408 157.026 257.995 155.439 259.942 155.439Z"
                fill="#64748B"></path>
            <path d="M311.226 155.439H270.185V158.901H311.226V155.439Z" fill="#C0CCD8"></path>
            <path
                d="M295.502 124.784H272.564L279.777 155.439H302.714L295.502 124.784ZM289.875 148.947C287.279 148.947 284.61 145.918 283.817 142.239C283.023 138.56 284.466 135.531 287.062 135.531C289.659 135.531 292.328 138.56 293.121 142.239C293.843 145.918 292.4 148.947 289.875 148.947Z"
                fill="#C0CCD8"></path>
            <g opacity="0.65">
                <path opacity="0.65" d="M241.909 82.5894L240.538 90.235H241.909L243.279 82.5894H241.909Z"
                    fill="#8E9DB2"></path>
                <path opacity="0.65" d="M245.876 82.5884L244.506 90.2341H245.876L247.247 82.5884H245.876Z"
                    fill="#8E9DB2"></path>
                <path opacity="0.65" d="M249.771 82.5884L248.4 90.2341H249.771L251.141 82.5884H249.771Z" fill="#8E9DB2">
                </path>
                <path opacity="0.65" d="M253.739 82.5884L252.368 90.2341H253.739L255.109 82.5884H253.739Z"
                    fill="#8E9DB2"></path>
                <path opacity="0.65" d="M257.634 82.5884L256.264 90.2341H257.634L259.005 82.5884H257.634Z"
                    fill="#8E9DB2"></path>
            </g>
            <g opacity="0.65">
                <path opacity="0.65" d="M293.555 82.5894L292.185 90.235H293.555L294.853 82.5894H293.555Z"
                    fill="#8E9DB2"></path>
                <path opacity="0.65" d="M297.665 82.5884L296.295 90.2341H297.665L299.036 82.5884H297.665Z"
                    fill="#8E9DB2"></path>
                <path opacity="0.65" d="M301.778 82.5884L300.407 90.2341H301.778L303.148 82.5884H301.778Z"
                    fill="#8E9DB2"></path>
                <path opacity="0.65" d="M305.889 82.5884L304.519 90.2341H305.889L307.259 82.5884H305.889Z"
                    fill="#8E9DB2"></path>
                <path opacity="0.65" d="M310.072 82.5884L308.701 90.2341H310.072L311.37 82.5884H310.072Z"
                    fill="#8E9DB2"></path>
                <path opacity="0.65" d="M314.183 82.5884L312.812 90.2341H314.183L315.553 82.5884H314.183Z"
                    fill="#8E9DB2"></path>
                <path opacity="0.65" d="M318.296 82.5884L316.926 90.2341H318.296L319.667 82.5884H318.296Z"
                    fill="#8E9DB2"></path>
                <path opacity="0.65" d="M322.406 82.5884L321.107 90.2341H322.406L323.776 82.5884H322.406Z"
                    fill="#8E9DB2"></path>
                <path opacity="0.65" d="M326.59 82.5884L325.22 90.2341H326.59L327.961 82.5884H326.59Z" fill="#8E9DB2">
                </path>
                <path opacity="0.65" d="M330.701 82.5884L329.33 90.2341H330.701L332.071 82.5884H330.701Z"
                    fill="#8E9DB2"></path>
            </g>
            <g opacity="0.55">
                <path opacity="0.55"
                    d="M136.959 141.662C136.959 141.662 137.608 141.518 137.825 141.157C138.33 140.436 137.464 139.642 136.887 139.282C136.166 138.777 135.156 138.633 134.795 137.767C134.363 136.757 135.084 135.603 135.949 135.026C136.671 134.521 137.464 134.305 138.33 134.016C139.195 133.656 140.133 133.151 140.638 132.285C141.287 131.275 141.143 129.905 141.215 128.751C141.576 124.135 146.264 121.899 150.231 121.754C152.178 121.682 155.208 122.404 156.578 123.99C157.011 124.495 157.372 125.144 157.516 125.794C157.732 126.803 157.3 127.813 156.65 128.535C156.001 129.328 155.064 129.761 154.27 130.482C153.477 131.203 154.054 131.997 153.837 132.862C153.621 133.8 152.972 134.233 152.178 134.666C150.447 135.675 148.356 135.892 146.408 136.397C145.831 136.541 145.182 136.757 144.749 137.118C144.1 137.623 143.739 138.344 143.307 138.993C142.441 140.147 141.143 141.013 139.7 141.301"
                    fill="url(#paint0_linear_212:517)"></path>
            </g>
            <path
                d="M147.924 153.203H144.173V151.4H147.924C149.15 151.4 150.087 150.39 150.087 149.236V146.134C150.087 144.908 149.078 143.97 147.924 143.97H144.173V142.167H147.924C150.16 142.167 151.891 143.97 151.891 146.134V149.236C151.963 151.4 150.16 153.203 147.924 153.203Z"
                fill="#E8EAEF"></path>
            <path
                d="M142.586 157.963H136.239C133.786 157.963 131.839 156.016 131.839 153.563V139.643H146.986V153.563C146.986 155.944 145.038 157.963 142.586 157.963Z"
                fill="#E8EAEF"></path>
            <path
                d="M129.387 155.367C129.387 155.367 129.531 158.901 135.085 158.901H143.813C149.366 158.901 149.511 155.367 149.511 155.367H129.387Z"
                fill="#98A3B8"></path>
            <path d="M251.36 157.53H171.08V158.973H251.36V157.53Z" fill="#64748B"></path>
            <path d="M179.593 156.376H173.822V157.531H179.593V156.376Z" fill="#64748B"></path>
            <path d="M188.248 156.376H182.478V157.531H188.248V156.376Z" fill="#64748B"></path>
            <path d="M196.975 156.376H191.205V157.531H196.975V156.376Z" fill="#64748B"></path>
            <path d="M205.701 156.376H199.931V157.531H205.701V156.376Z" fill="#64748B"></path>
            <path d="M214.357 156.376H208.587V157.531H214.357V156.376Z" fill="#64748B"></path>
            <path d="M223.086 156.376H217.315V157.531H223.086V156.376Z" fill="#64748B"></path>
            <path d="M231.812 156.376H226.042V157.531H231.812V156.376Z" fill="#64748B"></path>
            <path d="M240.542 156.376H234.771V157.531H240.542V156.376Z" fill="#64748B"></path>
            <path d="M249.196 156.376H243.426V157.531H249.196V156.376Z" fill="#64748B"></path>
            <path d="M252.37 157.53H172.09V158.973H252.37V157.53Z" fill="#C0CCD8"></path>
            <path d="M180.602 156.376H174.832V157.531H180.602V156.376Z" fill="#C0CCD8"></path>
            <path d="M189.328 156.376H183.558V157.531H189.328V156.376Z" fill="#C0CCD8"></path>
            <path d="M198.056 156.376H192.286V157.531H198.056V156.376Z" fill="#C0CCD8"></path>
            <path d="M206.711 156.376H200.94V157.531H206.711V156.376Z" fill="#C0CCD8"></path>
            <path d="M215.439 156.376H209.669V157.531H215.439V156.376Z" fill="#C0CCD8"></path>
            <path d="M224.168 156.376H218.397V157.531H224.168V156.376Z" fill="#C0CCD8"></path>
            <path d="M232.822 156.376H227.052V157.531H232.822V156.376Z" fill="#C0CCD8"></path>
            <path d="M241.552 156.376H235.781V157.531H241.552V156.376Z" fill="#C0CCD8"></path>
            <path d="M250.277 156.376H244.507V157.531H250.277V156.376Z" fill="#C0CCD8"></path>
            <path d="M21.0527 276.688H389.2" stroke="#64748B" stroke-width="1.44904" stroke-miterlimit="10"></path>
        </g>
        <defs>
            <linearGradient id="paint0_linear_212:517" x1="146.066" y1="141.636" x2="146.066" y2="121.725"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="white" stop-opacity="0.1"></stop>
                <stop offset="1" stop-color="white" stop-opacity="0.4"></stop>
            </linearGradient>
        </defs>
    </svg>
</template>