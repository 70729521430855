<template>
  <main
    class="flex min-h-screen w-full items-center  justify-center flex-col sm:px-0 px-3  pb-16 pt-20">
    <a href="/" class="mb-6">
      <img :src="logo" class="flex" alt="logo">
    </a>
    <!-- <router-link :to="{name:'home'}" class="mb-10">
      <img :src="logo" class="flex" alt="logo">
    </router-link> -->
    <section
      class="bg-white dark:bg-base-300 dark:shadow-whiteShadow  py-9 sm:px-13 px-5 rounded-2xl shadow-sm  sm:w-467 w-full">

      <div class="mb-4 text-center">
        <h2 class="sm:text-26 text-22 text-dark-550 dark:text-white font-semibold mb-1">{{ __('Hello buddy!') }}</h2>
        <h6 class="sm:text-15 text-13 text-gray-300 dark:text-gray-210 font-medium">{{ __('Welcome home! If you are a member, log in') }}</h6>
      </div>


        <div>
          <Form @submit="onSubmit" class="h-full flex flex-col px-4" v-slot="{errors}">
            <div class="flex-1">
              <div class="form-control mt-3">
                <div>
                  <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-mediuminput" for="">
                    {{ __('Subscription name') }} <span class="text-error">*</span>
                  </label>
                  <Field type="text" name="tenant_name" v-model="user.tenant_name" :validateOnBlur="false"
                    :validateOnInput="true" rules="required" :class="{ 'input-error': errors.tenant_name }"
                    class="input" />
                </div>
                <div>
                  <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-mediuminput" for="">
                    {{ __('Username') }} <span class="text-error">*</span>
                  </label>
                  <Field type="email" name="email" v-model="user.email" :validateOnBlur="false" :validateOnInput="true"
                    rules="required" :class="{ 'input-error': errors.email }" class="input" />
                </div>
                <div>
                  <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-mediuminput" for="">
                    {{ __('Password') }} <span class="text-error">*</span>
                  </label>
                    <Field class="input flex items-center" :class="{ 'input-error': errors.password }" autocomplete="off" name="password"
                      v-model="user.password" :validateOnBlur="false" :validateOnInput="true" rules="required"  />
                </div>
              </div>
            </div>
            <div class="form-control">
              <button type="submit" class="btn btn-primary mt-3" :disabled="loading">
                <span class="loading loading-spinner" v-if="loading"></span>
                {{ __('login')}}
              </button>
              <router-link :to="{name: 'forget.password'}" class="btn-link text-sm text-error text-center mt-3 no-underline">{{ __('Forgot your password?') }}</router-link>
            </div>
          </Form>
        </div>

    </section>
    <p class="mt-1 text-sm md:text-base">{{ __('Don\'t have a subscription?') }} <router-link :to="{name:'register'}" class="btn-link">{{ __('Register now') }}</router-link></p>
  </main>
</template>
<script setup>
import Auth from "../../composables/auth";
import { Field, Form } from "vee-validate";
import { computed, reactive, ref } from 'vue';
const loading = Auth.loadingBtn;
const errors = Auth.errors;
const user = reactive({});
const onSubmit = () => Auth.login(user)
const logo = window.header.logo;
</script>
