<template>
    <Form @submit="onSubmit" v-slot="{ errors }" @invalid-submit="onInvalidSubmit">
        <div class="flex flex-col">
            <div>
                <div class="mb-10  last:mb-0">
                    <h2 class="text-dark-550  dark:text-white font-bold flex items-center text-xl">
                        <i class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full "></i>
                        {{ state }}
                    </h2>
                    <div class="divider col-span-full mt-0 mb-3"></div>
                    <div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 1">
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Full Name') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="user.name" rules="required" :class="{ 'input-error': errors.name }"
                                    class="input" type="text" name="name" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Username (email address)') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="user.email" rules="required|email"
                                    :class="{ 'input-error': errors.email }" class="input" type="email" name="email" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('User Role') }} <span class="text-error">*</span>
                                </label>
                                <Field as="select" v-model="user.role_id" rules="required" :disabled="user.admin"
                                    :class="{ 'select-error': errors.role_id }" class="select" name="role_id">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in meta.roles" :value="item.id">{{ item.name }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Status') }} <span class="text-error">*</span>
                                </label>
                                <Field as="select" v-model="user.status" rules="required"
                                    :class="{ 'select-error': errors.status }" class="select" name="status">
                                    <option value="active">{{ __('Active') }}</option>
                                    <option value="inactive">{{ __('Inactive') }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Password') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="user.password" :rules="$route.params.id ? '' : 'required'" :class="{ 'input-error': errors.password }"
                                    class="input" type="password" name="password" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Confirm Password') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="user.password_confirmation" :rules="$route.params.id ? '' : 'required'" :class="{ 'input-error': errors.password_confirmation }"
                                    class="input" type="password" name="password_confirmation" />
                            </div>
                            <div class="flex flex-col">
                                <div class="flex justify-between">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium"
                                        for="">
                                        {{ __('Phone number') }}
                                    </label>
                                    <button @click="addNumber(user.numbers, index)" type="button"
                                        class="btn btn-xs btn-link no-underline">{{ __('Adding')
                                        }}</button>
                                </div>
                                <div class="flex mb-2" v-for="(info, index) in user.numbers">
                                    <div class="join w-full">
                                        <select class="select join-item w-auto" v-model="info.type">
                                            <option v-for="item in numberTypes" :value="item">
                                                {{ __(item.capitalize()) }}
                                            </option>
                                        </select>
                                        <Field class="input join-item" name="numbers[value]" type="number" dir="ltr" v-model="info.value" @input="checkNumber('user',$event,user.id)" />
                                    </div>
                                    <div class="mr-1 flex flex-col justify-center">
                                        <button type="button" v-if="user.numbers.length > 1"
                                            class="btn btn-xs btn-error btn-circle" @click="removeNumber(index)">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none"
                                                viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <small class="text-error">{{ message }}</small>
                            </div>
                            <div class="flex flex-col">
                                <div class="flex justify-between">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium"
                                        for="">
                                        {{ __('Email') }}
                                    </label>
                                    <button @click="addEmail(index)" class="btn btn-xs btn-link no-underline" type="button">{{
                                        __('Adding')
                                    }}</button>
                                </div>
                                <div class="flex mb-2" v-for="(info, index) in user.emails">
                                    <input class="input join-item" v-model="info.value" />

                                    <div class="mr-1 flex flex-col justify-center">
                                        <button type="button" v-if="user.emails.length > 1"
                                            class="btn btn-xs btn-error btn-circle" @click="removeEmail(index)">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none"
                                                viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('User image') }}
                                </label>
                                <div class="relative ">
                                    <Field class="file-input pr-0 pl-12" @change="uploadImage($event,'avatar',user)" type="file"
                                        accept=".jpg,.jpeg,.png,.PNG,.JPG,.JPEG,.bmp,.BMP,.webp" name="avatar" />
                                    <img :src="user.avatar" v-if="user.avatar"
                                        class="w-11 h-11 m-0.5 absolute rounded-sm left-0 top-0 object-cover">
                                </div>
                                <div class="flex justify-end" v-if="user.avatar">
                                    <small class="ml-auto line-clamp-1">{{
                                        user.avatar?.replace(/^.*[\\/]/, '')
                                        }}</small>
                                    <label for="modal-image" @click="image = user.avatar"
                                        class="btn-link text-info cursor-pointer text-sm no-underline">{{
                                        __('Show') }}</label>
                                    <button type="button" class="btn-link text-error text-sm mr-3 no-underline"
                                        @click="removeImage('avatar')">{{ __('Delete')
                                        }}</button>
                                </div>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('User Signature') }}
                                </label>
                                <div class="relative ">
                                    <Field class="file-input pr-0 pl-12" @change="uploadImage($event, 'signature',user)" type="file"
                                        accept=".jpg,.jpeg,.png,.PNG,.JPG,.JPEG,.bmp,.BMP,.webp" name="signature" />
                                    <img :src="user.signature" v-if="user.signature"
                                        class="w-11 h-11 m-0.5 absolute rounded-sm left-0 top-0 object-cover">
                                </div>
                                <div class="flex justify-end" v-if="user.signature">
                                    <small class="ml-auto line-clamp-1">{{
                                        user.signature?.replace(/^.*[\\/]/, '')
                                        }}</small>
                                    <label for="modal-image" @click="image = user.signature"
                                        class="btn-link text-info text-sm cursor-pointer no-underline">{{
                                        __('Show') }}</label>
                                    <button type="button" class="btn-link text-error text-sm mr-3 no-underline"
                                        @click="removeImage('signature')">{{ __('Delete')
                                        }}</button>
                                </div>
                            </div>
                        </div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 2">
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Staff status') }}
                                </label>
                                <Field as="select" v-model="user.employee_status" :class="{ 'input-error': errors.employee_status }"
                                    class="select" name="employee_status">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option value="active">{{ __('Active') }}</option>
                                    <option value="terminated">{{ __('Cooperation has been terminated') }}</option>
                                    <option value="leave">{{ __('Leave') }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Title') }}
                                </label>
                                <Field v-model="user.title" :class="{ 'input-error': errors.title }"
                                    class="input" type="text" name="title" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Telephone system internal number') }}
                                </label>
                                <Field v-model="user.extension" :class="{ 'input-error': errors.extension }"
                                    class="input" type="number" name="extension" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Reports to') }}
                                </label>
                                <Field as="select" v-model="user.reports_to_id" :class="{ 'input-error': errors.reports_to_id }"
                                    class="select" name="reports_to_id">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in meta.users" :value="item.id">{{ item.name }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Unit') }}
                                </label>
                                <Field v-model="user.department" :class="{ 'input-error': errors.department }"
                                    class="input" type="text" name="department" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Side') }}
                                </label>
                                <Field v-model="user.side" :class="{ 'input-error': errors.side }"
                                    class="input" type="text" name="side" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Start Date') }}
                                </label>
                                <DatePicker v-model="user.start_date" name="start_date" type="date" inputClass="input" />

                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('The date of the last change of position') }}
                                </label>
                                <DatePicker v-model="user.last_promotion_date" name="last_promotion_date" type="date" inputClass="input" />

                            </div>
                            <div class="flex flex-col col-span-full">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Description') }}
                                </label>
                                <Field as="textarea" v-model="user.description"
                                    :class="{ 'input-error': errors.description }" class="textarea" type="text"
                                    name="description" />
                            </div>
                        </div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 3">
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Country') }}
                                </label>
                                <Field as="select" v-model="user.billing.country"
                                    :class="{ 'input-error': errors.billing_country }" class="select"
                                    name="billing_country">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option :value="__('Iran')">{{ __('Iran') }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('State') }}
                                </label>
                                <Field as="select" v-model="user.billing.state" @change="changeState"
                                    :class="{ 'input-error': errors.billing_state }" class="select" name="billing_state">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in meta.states" :value="item.name">{{ item.name }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('City') }}
                                </label>
                                <Field as="select" v-model="user.billing.city" @change="changeCity"
                                    :class="{ 'input-error': errors.billing_city }" class="select" name="billing_city">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in cities" :value="item.name">{{ item.name }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Postal Code') }}
                                </label>
                                <Field v-model="user.billing.postalcode" class="input" type="number"
                                    name="billing_postalcode" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Address') }}
                                </label>
                                <Field v-model="user.billing.street" class="input" type="text" name="billing_street" />
                            </div>
                            <div class="flex items-end">
                                <div class="flex flex-col flex-1">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium"
                                        for="">
                                        {{ __('Latitude') }}
                                    </label>
                                    <Field v-model="user.billing.latitude" class="input" type="number"
                                        name="billing_latitude" />
                                </div>
                                <div class="flex flex-col mr-1 flex-1">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium"
                                        for="">
                                        {{ __('Longitude') }}
                                    </label>
                                    <Field v-model="user.billing.longitude" class="input" type="number"
                                        name="billing_longitude" />
                                </div>
                                <label class="btn btn-primary mr-1" for="modal-map" @click="setMap">
                                    <MapPinIcon class="w-5 h-5" />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider h-auto w-full"></div>
            <div class="text-end">
                <button type="submit" class="btn btn-primary px-6 mr-2">{{
                    __('Save Changes')
                    }}</button>
            </div>
        </div>
    </Form>
    <input type="checkbox" id="modal-map" class="modal-toggle" />
    <div class="modal">
        <div class="modal-box max-w-5xl">
            <div class="h-[600px]">
                <l-map ref="map" v-model:zoom="zoom" :center="centerLatLng" v-if="showMap" :use-global-leaflet="false"
                    @update:center="updateCenter">
                    <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" layer-type="base"
                        name="OpenStreetMap"></l-tile-layer>
                    <l-marker :lat-lng="markerLatLng"></l-marker>
                </l-map>
            </div>
            <div class="modal-action justify-between items-center">
                <div>
                    <label class="btn btn-error ml-2 btn-sm" for="modal-map">{{ __('Cancel') }}</label>
                    <label class="btn btn-success btn-sm text-white" for="modal-map" @click="setLocation">{{ __('Save')
                        }}</label>
                </div>
            </div>
        </div>
    </div>
    <Teleport to="#app">
        <ImageModal :image="image" />
    </Teleport>
</template>

<script setup>
import { onMounted, ref, watch, reactive, computed } from 'vue';
import useDashboard from '../../composables/dashboard';
import DatePicker from 'vue3-persian-datetime-picker'
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import { Field, Form } from "vee-validate";
import { MapPinIcon } from "@heroicons/vue/24/outline";
import ImageModal from '../modal/ImageModal.vue';
const props = defineProps(['user', 'meta', 'step','state']);
const showMap = ref(false);
const zoom = 11;
const image = ref('');
const centerLatLng = ref([35.7029075623, 51.3497581482]);
const markerLatLng = ref(centerLatLng.value);
const numberTypes = ['office', 'mobile', 'fax', 'factory', 'home', 'whatsapp', 'other'];
const { getCities, storeUser, updateUser,uploadImage,user,message,checkNumber, loading,route, cities } = useDashboard();
const isEdit = route.params.id;
Object.assign(user,props.user);
if(user.billing.state) getCities(user.billing.state);
const addNumber = (index) => user.numbers.splice(index + 1, 0, { type: 'office', value: '' });
const addEmail = (index) => user.emails.splice(index + 1, 0, { value: '' });
const removeNumber = (i) => user.numbers = user.numbers.filter((item, index) => index != i);
const removeEmail = (i) => user.emails = user.emails.filter((item, index) => index != i);
const updateCenter = (latlng) => markerLatLng.value = [latlng.lat,latlng.lng];
const setMap = () => {
    showMap.value = true;
    const billing = user.billing;
    if(billing.latitude && billing.longitude) centerLatLng.value = [billing.latitude,billing.longitude];
    else if(cities.value.find(item => billing.city == item.name)) {
        const city = cities.value.find(item => billing.city == item.name);
        centerLatLng.value = [city.latitude,city.longitude];
    }else if(props.meta.states.find(item => billing.state == item.name)) {
        const state = props.meta.states.find(item => billing.state == item.name);
        centerLatLng.value = [state.latitude,state.longitude];
    }
    markerLatLng.value = centerLatLng.value;
}
const changeState = () => {
    user.billing.city = '';
    user.billing.latitude = '';
    user.billing.longitude = '';
    getCities(user.billing.state);
}
const changeCity = () => {
    user.billing.latitude = '';
    user.billing.longitude = '';
}
const setLocation = () => Object.assign(user.billing, { latitude: markerLatLng.value[0], longitude: markerLatLng.value[1] });
const removeImage = (name) => user[name] = null;
const onSubmit = () => {
    if (isEdit) updateUser();
    else storeUser();
}
const onInvalidSubmit = ({ errors }) => {

};
</script>