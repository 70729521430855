<template>
    <div>
        <div class="dropdown relative lg:inline-block hidden ml-4" tabindex="0" :class="{'pointer-events-none': $route.name == 'cart'}">
            <label @click="$emit('setSideBar',true, $event)"
                class="group w-12 h-12 flex items-center dark:hover:bg-biscay-300 dark:bg-base-100 justify-center rounded-full bg-gray-210 hover:bg-biscay-700 transition relative cursor-pointer">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        class="fill-current text-biscay-700 dark:text-gray-900 dark:group-hover:text-dark-920 group-hover:text-biscay-300"
                        fill-rule="evenodd" clip-rule="evenodd"
                        d="M19.1705 14.9551L18.4657 9.27669C18.0363 7.25046 16.8211 6.41663 15.6626 6.41663H6.35407C5.17937 6.41663 3.92365 7.1921 3.55909 9.27669L2.84616 14.9551C2.26286 19.1243 4.40973 20.1666 7.21282 20.1666H14.8119C17.6069 20.1666 19.689 18.6574 19.1705 14.9551ZM8.33901 11.1362C7.89158 11.1362 7.52887 10.7629 7.52887 10.3024C7.52887 9.84187 7.89158 9.46855 8.33901 9.46855C8.78644 9.46855 9.14915 9.84187 9.14915 10.3024C9.14915 10.7629 8.78644 11.1362 8.33901 11.1362ZM12.8353 10.3024C12.8353 10.7629 13.198 11.1362 13.6454 11.1362C14.0928 11.1362 14.4555 10.7629 14.4555 10.3024C14.4555 9.84187 14.0928 9.46855 13.6454 9.46855C13.198 9.46855 12.8353 9.84187 12.8353 10.3024Z">
                    </path>
                    <path
                        class="fill-current text-biscay-700 dark:text-gray-900 dark:group-hover:text-dark-920 group-hover:text-biscay-300"
                        opacity="0.4"
                        d="M15.5594 6.20972C15.5623 6.28082 15.5486 6.35162 15.5195 6.41658H14.2021C14.1766 6.35053 14.1631 6.28049 14.1622 6.20972C14.1622 4.45201 12.7324 3.0271 10.9686 3.0271C9.20486 3.0271 7.77504 4.45201 7.77504 6.20972C7.78713 6.27815 7.78713 6.34815 7.77504 6.41658H6.42575C6.41367 6.34815 6.41367 6.27815 6.42575 6.20972C6.52827 3.76367 8.54793 1.83325 11.0046 1.83325C13.4612 1.83325 15.4808 3.76367 15.5834 6.20972H15.5594Z">
                    </path>
                </svg>
                <span v-if="cart.items.length"
                    class="absolute text-white bg-red-450 rounded-full w-6 h-6 flex items-center justify-center text-xs -top-2 -right-2">{{
                        cart.items.length
                    }}</span>
            </label>
            <div tabindex="0" v-if="$route.name != 'cart'"
                class="menu menu-compact dropdown-content w-96 pt-5 pb-8 px-7 dark:bg-base-300 bg-white rounded-lg overflow-hidden top-16 -left-4 z-10">
                <div class="px-1 flex items-center justify-between">
                    <span class="dark:hover:text-white text-gray-300 font-medium text-lg">{{
                        cart.items.length
                        + ' ' + __('item')
                    }}</span>
                    <router-link :to="{ name: 'cart' }"
                        class="flex group dark:hover:text-white dark:text-blue-450 text-blue-700 text-sm font-medium group hover:text-biscay-700 transition duration-200">
                        <span class="-mt-1">{{ __('View shopping cart') }}</span>
                        <svg class="mr-1 dark:group-hover:text-white text-blue-700 group-hover:text-biscay-700" width="6"
                            height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.50359 8.71228L0.838135 5.04682L4.50359 1.38136" stroke="currentColor"
                                stroke-width="1.32722" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </router-link>
                </div>
                <hr class="dark:border-gray-300 border-biscay-100 mt-4">
                <div v-if="cart.items.length">
                    <div v-for="item in cart.items"
                        class="group flex items-center justify-between py-5 px-2.5 transition-all duration-200 border-b dark:hover:border-dark-890 dark:border-gray-300 border-biscay-100 rounded-md dark:hover:bg-base-200 hover:bg-blue-60">
                        <div class="flex items-center">
                            <router-link :to="{ name: 'product', params: { slug: item.slug } }">
                                <div class="w-16 rounded-md overflow-hidden ml-4">
                                    <img class="w-full h-full object-cover transition-all duration-200 transform group-hover:scale-125 group-hover:rotate-12"
                                        :src="item.image" :alt="item.title">
                                </div>
                            </router-link>
                            <div>
                                <router-link :to="{ name: 'product', params: { slug: item.slug } }"
                                    class="dark:text-white text-biscay-700 mb-2 text-lg font-medium max-w-sm-m">{{
                                        item.title
                                    }}</router-link>
                                <span class="flex items-center text-gray-300 font-normal text-xl">
                                    {{ item.price.toLocaleString() }}
                                    <div class="mr-1 text-biscay-650 dark:text-gray-200 text-xs">
                                        {{ __('Toman') }}
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div @click="removeCart(item.product_id)" :class="{ 'hidden': loadingDelete }"
                            class="dark:text-white text-biscay-700 hover:bg-blue-700 dark:hover:bg-red-450 hover:text-white cursor-pointer p-1 rounded-full ">
                            <svg class="w-4 h-4" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4"
                                    d="M16.1041 7.89014C16.1041 7.89014 15.6516 13.5026 15.3891 15.8668C15.2641 16.996 14.5666 17.6576 13.4241 17.6785C11.2499 17.7176 9.07326 17.7201 6.89993 17.6743C5.80076 17.6518 5.11493 16.9818 4.99243 15.8726C4.72826 13.4876 4.27826 7.89014 4.27826 7.89014"
                                    stroke="currentColor" stroke-opacity="0.9" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                                <path d="M17.2569 5.19975H3.12518" stroke="currentColor" stroke-opacity="0.9"
                                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path
                                    d="M14.5339 5.19974C13.8797 5.19974 13.3164 4.73724 13.188 4.0964L12.9855 3.08307C12.8605 2.61557 12.4372 2.29224 11.9547 2.29224H8.42719C7.94469 2.29224 7.52136 2.61557 7.39636 3.08307L7.19386 4.0964C7.06552 4.73724 6.50219 5.19974 5.84802 5.19974"
                                    stroke="currentColor" stroke-opacity="0.9" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"></path>
                            </svg>
                        </div>
                        <div class="bg-blue-700 dark:bg-red-450 text-white cursor-pointer p-1 rounded-full"
                            :class="{ 'hidden': !loadingDelete }">
                            <LoadingBar />
                        </div>
                    </div>
                </div>
                <div v-if="cart.items.length">
                    <div class="flex items-center justify-between mb-4 mt-5">
                        <span class="dark:text-white text-dark-550 font-normal text-lg">{{
                            __('Total')
                        }}:</span>
                        <span class="flex items-center dark:text-white text-dark-550 font-medium text-xl">
                            {{ cart.total?.toLocaleString() }}
                            <div class="mr-1 text-dark-550 dark:text-gray-200 text-xs">
                                {{ __('Toman') }}
                            </div>
                        </span>
                    </div>
                    <div>
                        <router-link :to="{ name: 'cart' }"
                            class="flex items-center justify-center w-full py-3 rounded-lg dark:hover:bg-base-200 dark:hover:border-opacity-100 border dark:hover:border-base-200 border-white border-opacity-0 bg-blue-60 hover:bg-blue-200 dark:bg-base-100 text-blue-700 font-semibold text-lg transition">
                            <span class="mt-1">{{ __('Complete the purchase process')
                            }}</span>
                            <svg class="dark:hover:text-white text-blue-700" width="18" height="18" viewBox="0 0 18 18"
                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.4238 14.0734L6.5345 9.18407L11.4238 4.29474" stroke="currentColor"
                                    stroke-width="1.65441" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </router-link>
                    </div>
                </div>
                <div v-else class="mt-5">
                    <span class="text-gray-300 font-medium text-base">{{ __('No products have been added to the cart yet')
                    }}</span>
                    <div>
                        <router-link :to="{ name: 'products' }"
                            class="flex items-center justify-center w-full py-3 rounded-lg dark:hover:bg-base-200 dark:hover:text-white bg-blue-60 dark:bg-base-100 hover:bg-blue-200 text-blue-700 font-semibold text-lg transition">
                            <span class="mt-1">{{ __('View databases') }}</span>
                        </router-link>
                    </div>
                </div>

            </div>
        </div>
        <div class="lg:hidden flex mb-5">
            <div class="group flex items-center ">
                <a href="#"
                    class="group lg:w-12 lg:h-12  w-10 h-10 flex items-center relative justify-center rounded-full bg-gray-210 dark:bg-base-200 hover:bg-biscay-700 transition cursor-pointer">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="fill-current text-biscay-700 dark:text-gray-200 group-hover:text-biscay-300"
                            fill-rule="evenodd" clip-rule="evenodd"
                            d="M19.1705 14.9551L18.4657 9.27669C18.0363 7.25046 16.8211 6.41663 15.6626 6.41663H6.35407C5.17937 6.41663 3.92365 7.1921 3.55909 9.27669L2.84616 14.9551C2.26286 19.1243 4.40973 20.1666 7.21282 20.1666H14.8119C17.6069 20.1666 19.689 18.6574 19.1705 14.9551ZM8.33901 11.1362C7.89158 11.1362 7.52887 10.7629 7.52887 10.3024C7.52887 9.84187 7.89158 9.46855 8.33901 9.46855C8.78644 9.46855 9.14915 9.84187 9.14915 10.3024C9.14915 10.7629 8.78644 11.1362 8.33901 11.1362ZM12.8353 10.3024C12.8353 10.7629 13.198 11.1362 13.6454 11.1362C14.0928 11.1362 14.4555 10.7629 14.4555 10.3024C14.4555 9.84187 14.0928 9.46855 13.6454 9.46855C13.198 9.46855 12.8353 9.84187 12.8353 10.3024Z">
                        </path>
                        <path class="fill-current text-biscay-700 dark:text-gray-200 group-hover:text-biscay-300"
                            opacity="0.4"
                            d="M15.5594 6.20972C15.5623 6.28082 15.5486 6.35162 15.5195 6.41658H14.2021C14.1766 6.35053 14.1631 6.28049 14.1622 6.20972C14.1622 4.45201 12.7324 3.0271 10.9686 3.0271C9.20486 3.0271 7.77504 4.45201 7.77504 6.20972C7.78713 6.27815 7.78713 6.34815 7.77504 6.41658H6.42575C6.41367 6.34815 6.41367 6.27815 6.42575 6.20972C6.52827 3.76367 8.54793 1.83325 11.0046 1.83325C13.4612 1.83325 15.4808 3.76367 15.5834 6.20972H15.5594Z">
                        </path>
                    </svg>
                    <span v-if="cart.items.length"
                        class="absolute text-white bg-red-450 rounded-full w-6 h-6 flex items-center justify-center text-xs -top-2 -right-2">{{
                            cart.items.length
                        }}</span>
                </a>
                <router-link class="lg:hidden mr-2 dark:text-gray-200 text-biscay-700" :to="{ name: 'cart' }">
                    {{ __('Cart') }}
                </router-link>
            </div>
        </div>
    </div>
</template>
<script setup>
import useProducts from '../../composables/product';
import LoadingBar from '../svg/LoadingBar.vue';
defineProps(['cart'])
defineEmits(['setSideBar']);
const { removeCart, loadingDelete } = useProducts();
</script>