<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="errors" @tryAgain="getData" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <section class="lg:grid lg:grid-cols-24 lg:gap-6 flex flex-col">
                <div class="xl:col-span-18 xl:col-start-4 col-span-20 col-start-3">
                    <h2 class="text-3xl font-bold mb-3">{{ $route.params.id ? __('Edit campaign') : __('Generate campaign') }}
                    </h2>
                    <div class="bg-white dark:bg-base-300 rounded-2xl md:px-6 px-3 sm:pb-6 sm:pt-4 py-2">
                        <Form @submit="onSubmit" v-slot="{ errors }">
                            <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4">
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Name') }} <span datean class="text-error">*</span>
                                </label>
                                <Field v-model="campaign.name" rules="required" :class="{ 'input-error': errors.name }"
                                    class="input" type="text" name="name" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Status') }} <span class="text-error">*</span>
                                </label>
                                <Field as="select" v-model="campaign.status" rules="required"
                                    :class="{ 'select-error': errors.status }" class="select" name="status">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in statusTypes" :value="item">{{ __(item.capitalize()) }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Start date') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="campaign.start_date" class="input start_date" name="start_date" rules="required" :class="{'input-error': errors.start_date }" />
                                <DatePicker v-model="campaign.start_date" type="date" custom-input=".start_date" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('End date') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="campaign.end_date" class="input end_date" name="end_date" rules="required" :class="{'input-error': errors.end_date }" />
                                <DatePicker v-model="campaign.end_date" type="date" custom-input=".end_date" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Type') }} <span class="text-error">*</span>
                                </label>
                                <Field as="select" v-model="campaign.type" rules="required"
                                    :class="{ 'select-error': errors.type }" class="select" name="type">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in types" :value="item">{{ __(item.capitalize()) }}</option>
                                </Field>
                            </div>
                            <!-- <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Repetition rate') }}
                                </label>
                                <Field as="select" v-model="campaign.frequency"
                                    :class="{ 'select-error': errors.frequency }" class="select" name="frequency">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in frequencyTypes" :value="item">{{ __(item.capitalize()) }}</option>
                                </Field>
                            </div> -->
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                        {{ __('Sub campaigns') }}
                    </label>
                    <Field as="select" name="parent_id" :class="{ 'select-error': errors.parent_id }" class="select"
                        v-model="campaign.parent_id">
                        <option value="">{{ __('Select...') }}</option>
                        <option v-for="item in meta.campaigns" :value="item.id">{{ item.name }}</option>
                    </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Currency') }}
                                </label>
                                <Field as="select" v-model="campaign.currency"
                                    :class="{ 'select-error': errors.currency }" class="select" name="currency">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in currencyTypes" :value="item">{{ __(item.capitalize()) }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Impressions') }}
                                </label>
                                <Field v-model="campaign.impressions" :class="{ 'input-error': errors.impressions }"
                                    class="input" type="number" name="impressions" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Budget') }}
                                </label>
                                <Field v-model="campaign.budget" :class="{ 'input-error': errors.budget }"
                                    class="input" type="number" name="budget" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Actual cost') }}
                                </label>
                                <Field v-model="campaign.actual_cost" :class="{ 'input-error': errors.actual_cost }"
                                    class="input" type="number" name="actual_cost" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Expected income') }}
                                </label>
                                <Field v-model="campaign.expected_revenue" :class="{ 'input-error': errors.expected_revenue }"
                                    class="input" type="number" name="expected_revenue" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Expected cost') }}
                                </label>
                                <Field v-model="campaign.expected_cost" :class="{ 'input-error': errors.expected_cost }"
                                    class="input" type="number" name="expected_cost" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Expected response') }}
                                </label>
                                <Field as="select" v-model="campaign.expected_response"
                                    :class="{ 'select-error': errors.expected_response }" class="select" name="expected_response">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in responseTypes" :value="item">{{ __(item.capitalize()) }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Target') }}
                                </label>
                                <Field v-model="campaign.objective" :class="{ 'input-error': errors.objective }"
                                    class="input" type="text" name="objective" />
                            </div>
                            <div class="flex flex-col col-span-full">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Description') }}
                                </label>
                                <Field as="textarea" v-model="campaign.description" class="textarea" type="text" name="description" />
                            </div>
                        </div>
                            <div class="divider h-auto w-full"></div>
                            <div class="text-end">
                                <button type="submit" class="btn btn-primary px-6 mr-2">
                                    {{ __('Save Changes') }}
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import useDashboard from '../../composables/dashboard';
import { Field, Form } from "vee-validate";
import DatePicker from 'vue3-persian-datetime-picker'
const { campaign,addCampaign,editCampaign, storeCampaign, updateCampaign, loading,meta, errors, route } = useDashboard();
const statusTypes = ['planning', 'active', 'inactive', 'complete', 'queued', 'progress'];
const types = ['telesales', 'mail', 'email', 'print', 'web', 'radio','television','newsLetter','seminars'];
const responseTypes = ['excellent', 'good', 'average', 'poor'];
const currencyTypes = ['rial','dollar', 'euro'];
const getData = () => {
    if (route.params.id) editCampaign();
    else addCampaign();
}
const onSubmit = () => {
    if (route.params.id) updateCampaign();
    else storeCampaign();
}
onMounted(() => {
    getData();
});
</script>