<template>
  <div v-show="overlayShow" @click="setSideBar(false)"
    class="z-40 w-full h-full fixed top-0 right-0 backdrop-filter blur-sm bg-opacity-60 bg-biscay-700"></div>
  <main :class="{ 'grid lg:grid-cols-24 min-h-screen grid-cols-1 bg-white dark:bg-base-300': isDashboard }">
    <Sidebar v-if="isDashboard" :logo="logo" @setSideBar="setSideBar" />
    <section :class="{ 'xl:col-span-20 h-height-panel lg:col-span-19': isDashboard,'h-[calc(100%-160px)]': auth.user.value.wallet < 1000 }">
      <Header v-if="isDashboard" @setSideBar="setSideBar" />
      <div class="w-full bg-gray-200 dark:bg-base-100 pt-4" v-if="auth.user.value.wallet < 1000">
        <div class="container">
          <Alert type="danger"
            :message="__('Your SMS credit is running out, increase your credit to prevent messages from being sent.')" />
        </div>
      </div>
      <!-- <Navbar v-if="!isDashboard && !isAuthRoute" :logo="logo" @setSideBar="setSideBar" /> -->
      <router-view />
      <!-- <Footer v-if="!isDashboard && !isAuthRoute" :logo="logo" /> -->
    </section>
  </main>
  <LoadingAlert :show="progressDialog" />
  <input type="checkbox" id="logout" class="modal-toggle" v-if="auth.check()" />
  <label class="modal" id="modal-logout" for="logout" v-if="auth.check()">
    <label class="modal-box" for="">
      <h3 class="font-bold text-lg">{{ __('Sign out of the account') }}</h3>
      <p class="py-4">{{ __('Are you logging out of your account?') }}</p>
      <div class="modal-action">
        <label class="btn btn-primary btn-sm ml-2" for="logout">{{ __('Cancel') }}</label>
        <label class="btn btn-error btn-sm text-white" @click="Auth.logout" for="logout">{{ __('Logout') }}</label>
      </div>
    </label>
  </label>
  <div class="modal" id="modal-popup" :class="{ 'modal-open': popup }" v-if="popup">
    <div class="modal-box relative" :class="[popup.type !== 'notice' ? 'p-0 max-w-2xl' : 'max-w-xl']">
      <label @click="removePopup" class="btn btn-sm btn-circle absolute btn-ghost left-2 top-2 pt-1 z-50">✕</label>
      <img :src="popup.image" alt="image" v-if="popup.type === 'only_image'">
      <div class="px-8" v-else-if="popup.type === 'notice'">
        <MegaphoneIcon class="w-20 h-20 md:w-24 md:h-24 mx-auto text-red-500" />
        <h3 class="font-bold text-lg mt-5">{{ popup.title }}</h3>
        <p class="py-2">{{ popup.description }}</p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, provide, ref, watch } from "vue";
import { MegaphoneIcon } from '@heroicons/vue/24/outline';
import Auth from "../../composables/auth";
import useHome from "../../composables/home";
import { useRoute } from 'vue-router'
import Sidebar from "../dashboard/Sidebar.vue";
import Header from "../dashboard/Header.vue";
import { useHead } from '@vueuse/head'
import Alert from "./Alert.vue";
const route = useRoute();
const metaTitle = document.title.split(' | ')[1];
useHead({
  titleTemplate: (title) => title ? title + ' | ' + header.name : header.name + ' | ' + metaTitle
})
const routeName = ref(null);
const isDashboard = ref(false);
const { popup, getPopup, removePopup } = useHome();
const isAuthRoute = ref(false);
const overlayShow = ref(false);
const menuSate = ref(false);
provide('overlayShow', overlayShow)
provide('menuSate', menuSate)
document.addEventListener('focusin', function (e) {
  if (e.target.classList.contains('dropdown')) {
    setSideBar(true, true);
    e.target.classList.add('z-50');
  }
})
document.addEventListener('focusout', function (e) { if (e.target.classList.contains('dropdown')) setSideBar(false) })
const setSideBar = (show = true, overlay = false) => {
  if (!overlay) menuSate.value = show;
  overlayShow.value = show;
  if (!overlayShow.value) document.querySelectorAll('.z-50').forEach(elm => elm.classList.remove('z-50'));
  if (menuSate.value) document.body.classList.add('overflow-hidden');
  else document.body.classList.remove('overflow-hidden');
}
watch(() => route.name, () => {
  routeName.value = route.name.toString();
  isAuthRoute.value = ['register', 'login', 'card'].includes(routeName.value);
  isDashboard.value = routeName.value.includes('user')
  setSideBar(false);
})
const progressDialog = window.progressDialog;
const logo = window.header.logo;
onMounted(() => {
  getPopup();
})

</script>