<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!dashboard.payment_count && errors" @tryAgain="getDashboard" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <div>
                <div class="mb-12 grid grid-cols-24 items-center sm:gap-5 ">
                    <div
                        class="xl:col-span-24 col-span-24 justify-between rounded-lg  grid md:grid-cols-4 grid-cols-2 md:gap-y-0 md:gap-x-4 gap-2 items-center">
                        <div class="flex  items-start dark:bg-base-300 bg-white px-4 py-7 rounded-lg">
                            <div
                                class=" w-10 h-10 transform sm:scale-100 scale-90 bg-blue-700 sm:ml-4 ml-2 rounded-xl flex items-center justify-center">
                                <UserIcon class="w-5 h-5 text-white" />
                            </div>
                            <router-link class="flex flex-col" :to="{ name: 'user.users' }"
                                :class="{ 'pointer-events-none': !can('user.view') }">
                                <span class="text-gray-400 text-xs font-medium mb-2 ">{{
                                    __('User count')
                                    }}</span>
                                <span class="dark:text-white text-gray-800 xl:text-1xl text-xl font-bold">
                                    {{ dashboard.user_count + ' ' + __('User') }}
                                </span>
                            </router-link>
                        </div>
                        <div class="flex items-start dark:bg-base-300 bg-white px-4 py-7 rounded-lg">
                            <div
                                class=" w-10 h-10 transform sm:scale-100 scale-90 bg-red-700 sm:ml-4 ml-2 rounded-xl flex items-center justify-center">
                                <MegaphoneIcon class="w-5 h-5 text-white" />

                            </div>
                            <router-link class="flex flex-col" :to="{ name: 'user.campaigns' }"
                                :class="{ 'pointer-events-none': !can('campaign.view.all || campaign.view.self') }">
                                <span class="text-gray-400 text-xs font-medium mb-2 ">{{
                                    __('Campaign count')
                                    }}</span>
                                <span class="dark:text-white text-gray-800 xl:text-1xl text-xl font-bold">
                                    {{ dashboard.campaign_count + ' ' + __('Campaign') }}
                                </span>
                            </router-link>
                        </div>
                        <div class="flex items-start dark:bg-base-300 bg-white px-4 py-7 rounded-lg">
                            <div
                                class=" w-10 h-10 sm:scale-100 scale-90 bg-purple-700 sm:ml-4 ml-2  rounded-xl flex items-center justify-center">
                                <SignalIcon class="w-5 h-5 text-white"></SignalIcon>
                            </div>
                            <router-link :to="{ name: 'user.clues' }" class="flex flex-col"
                                :class="{ 'pointer-events-none': !can('clue.view.all || clue.view.self') }">
                                <span class="text-gray-400 text-xs font-medium mb-2 ">{{ __('Clue count') }}</span>
                                <span class="dark:text-white text-gray-800 sm:text-21 text-base font-bold">{{
                                    dashboard.clue_count + ' ' + __('Clue')
                                    }}</span>
                            </router-link>
                        </div>
                        <div class="flex  items-start dark:bg-base-300 bg-white px-4 py-7 rounded-lg">
                            <div
                                class=" w-10 h-10 sm:scale-100 scale-90 bg-green-700 sm:ml-4 ml-2  rounded-xl flex items-center justify-center">
                                <BellAlertIcon class="w-5 h-5 text-white" />
                            </div>
                            <router-link :to="{ name: 'user.notification' }" class="flex flex-col">
                                <span class="text-gray-400 text-xs font-medium mb-2 ">{{
                                    __('Unread notifications')
                                    }}</span>
                                <span class="dark:text-white text-gray-800 sm:text-21 text-base font-bold">
                                    {{ dashboard.notification_count + ' ' + __('notification') }}
                                </span>
                            </router-link>
                        </div>

                    </div>
                    <div class="dark:bg-base-300 bg-white rounded-lg xl:p-6 sm:p-4 p-2 mt-6 min-h-[24rem] col-span-12 flex flex-col justify-center">
                        <h2 class="text-dark-550 dark:text-white font-bold flex items-center text-xl"><i class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full"></i> {{ __('The calls I have to make') }}</h2>
                        <div class="divider col-span-full mt-0 mb-3"></div>

                            <div class="rounded-2xl dark:bg-base-300 bg-white flex flex-col flex-1"
                            :class="{ 'justify-center': !dashboard.contacts.length }">
                                <div class=" overflow-auto">
                                    <table class="table table-xs md:table-sm xl:table-md"
                                        v-if="dashboard.contacts.length">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th> {{ __('Subject') }} </th>
                                                <th> {{ __('Time') }} </th>
                                                <th> {{ __('Action') }} </th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in dashboard.contacts">
                                                <td> {{ index + 1 }} </td>
                                                <td> {{ item.subject }} </td>
                                                <td> {{ item.date }}</td>
                                                <td>
                                                    <router-link
                                                        :to="{ name: 'user.contact.edit', params: { id: item.id } }"
                                                        :class="{ 'btn-disabled': !can('contact.edit.all') && !can('contact.edit.self', item.self) }"
                                                        class="btn btn-outline btn-info btn-sm ml-2">
                                                        {{ __('Edit') }}
                                                    </router-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="col-span-12 flex flex-col items-center mt-9" v-else>
                                        <NotFound :small="true" />
                                    </div>
                                </div>
                            </div>
                       
                    </div>
                    <div class="dark:bg-base-300 bg-white rounded-lg xl:p-6 sm:p-4 p-2 mt-6 min-h-[24rem] col-span-12 flex flex-col">
                        <h2 class="text-dark-550 dark:text-white font-bold flex items-center text-xl"><i class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full"></i> {{ __('The meetings I have') }}</h2>
                        <div class="divider col-span-full mt-0 mb-3"></div>

                            <div class="rounded-2xl dark:bg-base-300 bg-white flex flex-col flex-1" :class="{ 'justify-center': !dashboard.appointments.length }">
                                <div class=" overflow-auto">
                                    <table class="table table-xs md:table-sm xl:table-md"
                                        v-if="dashboard.appointments.length">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th> {{ __('Subject') }} </th>
                                                <th> {{ __('Time') }} </th>
                                                <th> {{ __('Action') }} </th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in dashboard.appointments">
                                                <td> {{ index + 1 }} </td>
                                                <td> {{ item.subject }} </td>
                                                <td> {{ item.date }}</td>
                                                <td>
                                                    <router-link
                                                        :to="{ name: 'user.appointment.edit', params: { id: item.id } }"
                                                        :class="{ 'btn-disabled': !can('appointment.edit.all') && !can('appointment.edit.self', item.self) }"
                                                        class="btn btn-outline btn-info btn-sm ml-2">
                                                        {{ __('Edit') }}
                                                    </router-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="col-span-12 flex flex-col items-center mt-9" v-else>
                                        <NotFound :small="true" />
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div
                        class="dark:bg-base-300 bg-white rounded-lg xl:p-6 sm:p-4 p-2 mt-6 min-h-[24rem] col-span-full">
                        <BarChart :options="dashboard.clue_chart" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted } from 'vue'
import useHome from '../../composables/home';
import BarChart from '../layout/BarChart.vue';
import { SignalIcon, MegaphoneIcon, UserIcon, BellAlertIcon } from '@heroicons/vue/24/outline';
const { getDashboard, dashboard, loading, errors } = useHome();
onMounted(() => {
    getDashboard();
});
</script>