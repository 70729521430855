<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!leadSources.length && errors" @tryAgain="getLeadSources" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <div class="flex flex-col md:flex-row justify-between items-center md:items-center mb-6 lg:mb-8">
                <h3 class="flex items-center md:text-26 text-2xl dark:text-white text-biscay-700 font-semibold">
                    <i class="md:flex hidden w-2 h-2 dark:bg-white rounded-full bg-blue-700 ml-2"></i>
                    {{ __('Lead Sources') }}
                </h3>
                <div class="flex mt-6 md:mt-0 flex-col lg:flex-row w-full md:w-auto justify-end">
                    <div class="flex mt-4 lg:mt-0 justify-end">
                        <input
                            class="input w-full h-10 xl:pl-36 placeholder-gray-320 md:w-64 xl:w-80 ml-2 bg-gray-350 bg-opacity-10 rounded-lg  transition duration-200 text-sm text-gray-350 px-3 font-medium dark:!bg-base-300 dark:!text-white  focus:bg-blue-700 focus:bg-opacity-10 border border-blue-700  focus:border-opacity-100"
                            type="text" :placeholder="__('Search...')" v-model="searchValue">
                        <button @click="search"
                            class="bg-blue-600 disabled:opacity-70 disabled:!bg-blue-600 flex items-center h-10 md:px-5 px-3 rounded-md text-white font-semibold text-sm transition duration-200 hover:bg-blue-800 ">
                            {{ __('Search') }}
                        </button>
                    </div>
                    <div class="flex justify-end order-first lg:order-last lg:mr-4" v-if="can('lead_source.create')">
                        <label for="add-edit-modal"
                            class="btn btn-outline btn-info btn-base">
                            <span class="mt-0.5">{{ __('Add lead source') }}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="rounded-2xl dark:bg-base-300 bg-white">
                <div class=" overflow-x-auto">
                    <table class="table table-xs md:table-sm xl:table-md" v-if="leadSources.length">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th> {{ __('Title') }} </th>
                                <th> {{ __('Tag') }} </th>
                                <th> {{ __('User') }} </th>
                                <th> {{ __('Created At') }} </th>
                                <th> {{ __('Action') }} </th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in leadSources">
                                <td> {{ (meta.per_page * (meta.current_page - 1)) + index + 1 }} </td>
                                <td> {{ item.title }} </td>
                                <td> {{ item.tag || '---' }} </td>
                                <td> {{ item.user }} </td>
                                <td> {{ item.created_at }}</td>
                                <td>
                                    <label for="add-edit-modal" @click="setItem(item)" :class="{'btn-disabled': !can('lead_source.edit.all') && !can('lead_source.edit.self',item.self)}"
                                        class="btn btn-outline btn-info btn-sm ml-2">
                                        {{ __('Edit') }}
                                    </label>
                                    <label @click="setItem(item)" for="delete-modal" :class="{'btn-disabled': !can('lead_source.delete.all') && !can('lead_source.delete.self',item.self)}"
                                        class="btn btn-outline btn-error btn-sm">
                                        {{ __('Delete') }}
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                        <Pagination :meta="meta" @setPage="setPage" class="!mt-6" />
                    </table>
                    <div class="col-span-12 flex flex-col items-center mt-9" v-else>
                        <NotFound />
                    </div>
                </div>
            </div>
            <Pagination :meta="meta" @setPage="setPage" class="!mt-6" />
        </div>
    </div>
    <Teleport to="#app">
        <input type="checkbox" id="add-edit-modal" class="modal-toggle" />
        <div class="modal">
            <div class="modal-box bg-base-300">
                <h3 class="font-bold text-lg">{{ __(leadSource.id ? 'Edit lead source' : 'Add lead source') }}</h3>
                <Form @submit="onSubmit" class="h-full flex flex-col px-4" v-slot="{ errors }">
                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium mt-3" for="">
                                {{ __('Title') }} <span class="text-error">*</span>
                            </label>
                    <Field as="input" name="text" :class="{ 'input-error': errors.title }" rules="required"
                        class="input" v-model="leadSource.title" />
                        <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium mt-3" for="">
                                {{ __('Tag') }}
                            </label>
                    <Field name="tag" :class="{ 'input-error': errors.tag }"
                        class="input" v-model="leadSource.tag" />
                    <div class="modal-action">
                        <label for="add-edit-modal" class="btn btn-sm"
                            :class="{ 'pointer-events-none opacity-60': loadingBtn }">{{ __('Refuse')
                            }}</label>
                        <button type="submit" class="btn btn-sm btn-info !ml-0 !mr-2" :class="{ 'loading': loadingBtn }">
                            {{ __(leadSource.id ? 'Edit' : 'Add') }}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </Teleport>

    <Teleport to="#app">
        <input type="checkbox" id="delete-modal" class="modal-toggle" />
        <label class="modal" for="delete-modal">
            <label class="modal-box" for="">
                <h3 class="font-bold text-lg">{{ __('Delete lead source') }}</h3>
                <p class="py-4">{{ __('Are you sure to delete this lead source?') }}</p>
                <div class="modal-action">
                    <label class="btn btn-sm" for="delete-modal" :class="{ 'pointer-events-none opacity-60': loadingBtn }">{{
                        __('Cancel') }}</label>
                    <button class="btn btn-error btn-sm text-white" @click="removeLeadSource" :class="{ 'loading': loadingBtn }">
                        {{ __('Delete') }}
                    </button>
                </div>
            </label>
        </label>
    </Teleport>

</template>
<script setup>
import { ref, onMounted, computed } from 'vue';
import { Field, Form } from "vee-validate";
import useDashboard from '../../composables/dashboard';
const { getLeadSources,leadSource, loading,storeLeadSource,updateLeadSource,removeLeadSource, errors,remove, meta, router, route, leadSources,loadingBtn } = useDashboard();
const searchValue = ref(route.query.search || '');
const setQuery = (key, value) => {
    getLeadSources(window.setQuery(router, route, key, value));
}
const setPage = (p) => {
    setQuery('page', p.toString());
};
const onSubmit = () => {
    if(leadSource.id) updateLeadSource();
    else storeLeadSource();
}
const setItem = (item) => {
    Object.assign(leadSource, item);
    Object.assign(remove, item);
}
const search = () => {
    setQuery('search', searchValue.value)
}
onMounted(() => {
    getLeadSources(route.query);
});
</script>