<template>
    <Form @submit="onSubmit" v-slot="{ errors }" @invalid-submit="onInvalidSubmit">
        <div class="flex flex-col">
            <div>
                <div class="mb-10  last:mb-0">
                    <h2 class="text-dark-550  dark:text-white font-bold flex items-center text-xl">
                        <i class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full "></i>
                        {{ state }}
                    </h2>
                    <div class="divider col-span-full mt-0 mb-3"></div>
                    <div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 1">
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Subject') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="appointment.subject" rules="required"
                                    :class="{ 'input-error': errors.subject }" class="input" type="text"
                                    name="subject" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Parent Type') }} <span class="text-error">*</span>
                                </label>
                                <Field as="select" v-model="appointment.parent_type"
                                    :class="{ 'input-error': errors.parent_type }" class="select" name="parent_type">
                                    <option v-for="item in parentTypes" :value="item">{{
                                        __(item.capitalize()) }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __(appointment.parent_type?.capitalize() || '') }} <span class="text-error">*</span>
                                </label>
                                <Field as="select" v-model="appointment.appointmentable_id" rules="required"
                                    :class="{ 'input-error': errors.appointmentable_id }" class="select" name="appointmentable_id">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in meta.model" :value="item.id">{{ item.name }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Status') }} <span class="text-error">*</span>
                                </label>
                                <Field as="select" v-model="appointment.status" rules="required"
                                    :class="{ 'input-error': errors.status }" class="select" name="status">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in statusTypes" :value="item">{{
                                        __(item.replace('_', ' ').capitalize()) }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __(type == 'task' ? 'Start time' : 'Time') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="appointment.date" name="date" rules="required" readonly class="input date-input cursor-pointer text-end" dir="ltr" :class="{'input-error': errors.date}" />
                                <DatePicker v-model="appointment.date" custom-input=".date-input" rules="required" type="datetime" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Duration') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="appointment.period" name="period" readonly class="input period-input cursor-pointer" rules="required" :class="{'input-error': errors.period}" />
                                <DatePicker v-model="appointment.period" custom-input=".period-input" type="time" simple  :jump-minute="15" initial-value="00:45" :round-minute="true" :showNowBtn="false" />
                            </div>
                            <div class="flex flex-col" v-if="type == 'appointment'">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Meeting place') }}
                                </label>
                                <Field v-model="appointment.location" :class="{ 'input-error': errors.location }"
                                    class="input" type="text" name="location" />
                            </div>
                            <div class="flex flex-col" v-if="type == 'task'">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Deadline') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="appointment.deadline" name="deadline" readonly class="input deadline-input cursor-pointer" :class="{'input-error': errors.deadline}" />
                                <DatePicker v-model="appointment.deadline" custom-input=".deadline-input" type="datetime" />
                            </div>
                            <div class="flex flex-col" v-if="type == 'task'">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Priority') }}
                                </label>
                                <Field as="select" v-model="appointment.priority"
                                    :class="{ 'input-error': errors.priority }" class="select"
                                    name="priority">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in priorityTypes" :value="item">{{
                                        __(item.capitalize()) }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('SMS reminder to the user') }}
                                </label>
                                <Field as="select" v-model="appointment.sms_reminder_time"
                                    :class="{ 'input-error': errors.sms_reminder_time }" class="select"
                                    name="sms_reminder_time">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in reminderTimes" :value="item">{{
                                        __('Before ' + item + ' minute'.capitalize()) }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col" v-if="type != 'task'">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('SMS reminder to invitees') }}
                                </label>
                                <Field as="select" v-model="appointment.sms_reminder_time_invitees"
                                    :class="{ 'input-error': errors.sms_reminder_time_invitees }" class="select"
                                    name="sms_reminder_time_invitees">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in reminderTimes" :value="item">{{
                                        __('Before ' + item + ' minute'.capitalize()) }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Reminder in the software') }}
                                </label>
                                <Field as="select" v-model="appointment.reminder_time"
                                    :class="{ 'input-error': errors.reminder_time }" class="select"
                                    name="reminder_time">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in reminderTimes" :value="item">{{
                                        __('Before ' + item + ' minute'.capitalize()) }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col" v-if="type == 'contact'">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Telephone system internal number') }}
                                </label>
                                <Field v-model="appointment.voip_id"
                                    :class="{ 'input-error': errors.voip_id }" class="input" type="number"
                                    name="voip_id" />
                            </div>
                            <div class="flex flex-col col-span-full">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Description') }}
                                </label>
                                <Field as="textarea" v-model="appointment.description"
                                    :class="{ 'input-error': errors.description }" class="textarea" type="text"
                                    name="description" />
                            </div>
                        </div>
                        <div v-show="step == 2" v-if="type != 'task'">
                            <div class="grid grid-cols-2 lg:grid-cols-3 gap-3" v-if="appointment.invitees.length">
                                <div class="card bg-base-100" v-for="item in appointment.invitees">
                                    <div class="card-body p-4">
                                        <p><span class="text-white">{{ item.fullname }}</span> <span class="text-sm">({{ __(item.type.capitalize()) }})</span></p>
                                        <p class="text-sm">{{ item.phone || item.email }}</p>
                                        <button class="btn btn-outline btn-error btn-sm absolute bottom-0 left-0 m-4" @click="removeInvite(item.id)" type="button">{{ __('Delete') }}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="flex mt-4 justify-end">
                                <input
                                    class="input w-full h-10 xl:pl-36 placeholder-gray-320 md:w-64 xl:w-80 ml-2 bg-gray-350 bg-opacity-10 rounded-lg  transition duration-200 text-sm text-gray-350 px-3 font-medium dark:!bg-base-100 dark:!text-white  focus:bg-blue-700 focus:bg-opacity-10 border border-blue-700  focus:border-opacity-100"
                                    type="text" :placeholder="__('Search...')" v-model="searchValue">
                                <button @click="search" type="button"
                                    class=" bg-blue-600 disabled:opacity-70 disabled:!bg-blue-600 flex items-center h-10 md:px-5 px-3 rounded-md text-white font-semibold text-sm transition duration-200 hover:bg-blue-800 ">
                                    {{ __('Search') }}
                                </button>
                            </div>
                            <div class="rounded-2xl dark:bg-base-300 bg-white mt-3">
                                <div class=" overflow-x-auto">
                                    <table class="table table-xs md:table-sm xl:table-md">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th> {{ __('Type') }} </th>
                                                <th> {{ __('Full Name') }} </th>
                                                <th> {{ __('Phone') }} </th>
                                                <th> {{ __('Email') }} </th>
                                                <th> {{ __('Action') }} </th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in users">
                                                <td> {{ index + 1 }} </td>
                                                <td> {{ __(item.type.capitalize()) }} </td>
                                                <td> {{ item.fullname }} </td>
                                                <td> {{ item.phone || '---' }} </td>
                                                <td> {{ item.email || '---' }} </td>
                                                <td>
                                                    <label @click="addInvite(item)" :class="{'btn-disabled': appointment.invitees.map(user => user.id).includes(item.id)}"
                                                        class="btn btn-outline btn-success btn-sm">
                                                        {{ __('Add') }}
                                                    </label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="col-span-12 flex flex-col items-center mt-9" v-if="!users.length">
                                        <NotFound />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 3">
                            <div class="flex flex-col col-span-full">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('File') }}
                                </label>
                                <Field class="file-input pr-0" @change="addAttachment($event, 'attachment')" type="file"
                                    name="file" />
                            </div>
                            <div class="overflow-x-auto col-span-full">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{{ __('File title') }}</th>
                                            <th>{{ __('File type') }}</th>
                                            <th>{{ __('Status') }}</th>
                                            <th>{{ __('Action') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in appointment.attachments">
                                            <th>{{ index + 1 }}</th>
                                            <th>{{ item.name }}</th>
                                            <td>{{ item.mime_type }}</td>
                                            <td>
                                                <div v-if="isNumeric(item.status)">
                                                    <progress class="progress progress-info w-16" :value="item.status"
                                                        max="100"></progress> <span class="mr-0.5 inline-block w-4">{{
                                                        item.status + '%' }}</span>
                                                </div>
                                                <div v-else>{{ __(item.url ? 'Uploaded' : 'Uploading') }}</div>
                                            </td>
                                            <td>
                                                <button @click="removeFile(appointment.attachments, index)" type="button"
                                                    class="btn btn-outline btn-error btn-sm">
                                                    {{ __('Delete') }}
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="text-center" v-if="!appointment.attachments.length">
                                        <tr>
                                            <td colspan="5">{{ __('No file found to display') }}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider h-auto w-full"></div>
            <div class="text-end">
                <button type="submit" class="btn btn-primary px-6 mr-2">{{
                    __('Save Changes')
                    }}</button>
            </div>
        </div>
    </Form>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import useDashboard from '../../composables/dashboard';
import DatePicker from 'vue3-persian-datetime-picker'
import { Field, Form } from "vee-validate";
const props = defineProps(['appointment', 'meta', 'step', 'state','type']);
const parentTypes = ['account', 'opportunity', 'clue', 'campaign'];
const priorityTypes = ['high', 'medium', 'low'];
const statusTypes = props.type == 'task' ? ['not_started','pending_input', 'in_progress', 'deferred','complete'] : ['planning', 'not_held', 'held'];
const reminderTimes = ['15', '60','120', '360', '720', '1440'];
const searchValue = ref('')
const { uploadFile, removeFile, appointment, storeAppointment,getAccounts,getClues,getOpportunities,getCampaigns, updateAppointment,getAppointmentInvites,users,accounts,clues,campaigns,opportunities,meta, route, loading, message } = useDashboard();
Object.assign(appointment, props.appointment);
appointment.type = props.type
const getParentData = (value) => {
    if(value == 'account') getAccounts({});
    else if(value == 'clue') getClues({});
    else if(value == 'opportunity') getOpportunities({});
    else if(value == 'campaign') getCampaigns({});
}
const addInvite = (item) => {
    appointment.invitees.push(item);
};
const removeInvite = (id) => {
    appointment.invitees = appointment.invitees.filter(item => item.id != id);
};
watch(() => appointment.parent_type,(value) => getParentData(value));
watch(accounts,(value) => meta.model = value.map(item => ({id: item.id,name: item.name})));
watch(clues,(value) => meta.model = value.map(item => ({id: item.id,name: item.fullname})));
watch(opportunities,(value) => meta.model = value.map(item => ({id: item.id,name: item.name})));
watch(campaigns,(value) => meta.model = value.map(item => ({id: item.id,name: item.name})));
getParentData(appointment.parent_type);
function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}
const search = () => {
    getAppointmentInvites({search: searchValue.value})
}
const addAttachment = (event, type) => {
    appointment.attachments = [...appointment.attachments, { title: event.target.files[0].name, type: 'attachment', status: 'uploading' }];
    uploadFile(event, type, appointment.attachments[appointment.attachments.length - 1]);
}
const onSubmit = () => {
    if (route.params.id) updateAppointment();
    else storeAppointment();
}
const onInvalidSubmit = ({ errors }) => {

};
</script>