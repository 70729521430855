<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="errors" @tryAgain="getData" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <section class="lg:grid lg:grid-cols-24 lg:gap-6 flex flex-col">
                <div class="xl:col-span-18 xl:col-start-4 col-span-20 col-start-3">
                    <h2 class="text-3xl font-bold mb-3">
                        {{ $route.params.id ? __('Edit role') : __('Create a role') }}
                    </h2>
                    <div class="bg-white dark:bg-base-300 rounded-2xl md:px-6 px-3 sm:pb-6 sm:pt-4 py-2">
                        <Form @submit="onSubmit" v-slot="{ errors }">
                            <div class="grid grid-cols-1 md:gap-x-6 gap-y-4">
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Name') }} <span class="text-error">*</span>
                                    </label>
                                    <Field v-model="role.name" rules="required" :class="{ 'input-error': errors.name }"
                                        class="input" type="text" name="name" />
                                </div>
                                <div class="flex flex-col">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                        {{ __('Description') }}
                                    </label>
                                    <Field as="textarea" v-model="role.description" class="textarea" type="text"
                                        name="description" />
                                </div>
                                <div class="flex flex-col col-span-full">
                                    <label
                                        class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium flex justify-between"
                                        for="">
                                        <p>{{ __('Permissions') }} <span class="text-error">*</span></p>
                                        <button class="btn btn-xs btn-link" type="button" @click="selectAll"
                                            v-if="!role.default">
                                            {{ __('Select all') }}
                                        </button>
                                    </label>
                                    <div class="overflow-auto">
                                        <table class="table table-xs md:table-sm xl:table-md">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{{ __('Name') }}</th>
                                                    <th> {{ __('Show') }} </th>
                                                    <th> {{ __('Create') }} </th>
                                                    <th> {{ __('Edit') }} </th>
                                                    <th> {{ __('Delete') }} </th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in meta.permissions">
                                                    <td> {{ index + 1 }}
                                                    </td>
                                                    <td> {{ item.group_name }} </td>
                                                    <td v-for="permissions in permissionByTpe(item.permissions)">
                                                        <select class="select min-w-28" @change="changePermission" :disabled="role.default">
                                                            <option :value="permissions[0].name.split('.')[0] + '.' + permissions[0].type + '.' + 'none'" selected>{{ __('None') }}</option>
                                                            <option v-for="item in permissions" :value="item.name"
                                                                :selected="role.permissions.includes(item.name)">{{
                                                                    __(item.name.includes('self') ? 'My self' : 'All')
                                                                }}
                                                            </option>
                                                        </select>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="divider h-auto w-full"></div>
                            <div class="text-end">
                                <button type="submit" class="btn btn-primary px-6 mr-2">{{
                                    __('Save Changes')
                                }}</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import useDashboard from '../../composables/dashboard';
import { Field, Form } from "vee-validate";
const { role, addRole, editRole, storeRole, updateRole, loading, errors, meta, route } = useDashboard();
const getData = () => {
    if (route.params.id) editRole();
    else addRole();
}
const onSubmit = () => {
    if (route.params.id) updateRole();
    else storeRole();
}
const selectAll = () => {
    const permissions = meta.permissions.map(item => item.permissions.map(value => value.name).filter(value => !value.includes('self'))).flat();
    if (permissions.length == role.permissions.filter(item => !item.includes('self')).length) role.permissions = [];
    else role.permissions = [...role.permissions, ...permissions];
}
const changePermission = (e) => {
    const value = e.target.value;
    if(value.includes('none')) role.permissions = role.permissions.filter(item => item.replace('.self', '').replace('.all','') != value.replace('.none', ''))
    else if (value.includes('self')) role.permissions = role.permissions.filter(item => item != value.replace('self', 'all'));
    else if (value.includes('all')) role.permissions = role.permissions.filter(item => item != value.replace('all', 'self'));
    if(!value.includes('none'))role.permissions.push(value)
    console.log(role.permissions);
    
}
const permissionByTpe = (permissions) => Object.groupBy(permissions, (permission) => permission.type);
onMounted(() => {
    getData();
});
</script>