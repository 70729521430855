<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!sessions.length && errors" @tryAgain="getSessions" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <section class="lg:grid lg:gap-6 flex flex-col">
                <div class="xl:col-span-18 col-span-16">
                    <div class="bg-white dark:bg-base-300 rounded-2xl md:px-11 px-3 sm:py-8 py-2">
                        <div class="flex flex-col">
                            <div>
                                <div class="mb-10  last:mb-0 md:overflow-visible overflow-x-scroll">
                                    <div class="mb-4 ">
                                        <h2 class="text-dark-550 dark:text-white font-bold flex items-center text-2xl">
                                            <i class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full "></i>
                                            {{ __('Website login management') }}
                                        </h2>
                                    </div>
                                    <ul class="grid gap-y-2 md:w-auto w-screen min-w-600">
                                        <li v-for="session in sessions"
                                            class="grid grid-cols-24 px-4 py-4 gap-x-2 items-center border border-gray-70 border-opacity-20 rounded-xl md:w-auto w-full">
                                            <div class="flex col-span-9 items-center">

                                                <div
                                                    class="flex items-center text-white justify-center ml-2 w-7 h-7 rounded bg-blue-700">
                                                    <svg width="14" height="13" viewBox="0 0 14 13" fill="none"
                                                        v-if="platformCheck(session.platform) == 'windows'"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.66834 0.429199H0.876953V6.11694H6.66834V0.429199Z"
                                                            fill="white"></path>
                                                        <path d="M13.0941 0.429199H7.30273V6.11694H13.0941V0.429199Z"
                                                            fill="white"></path>
                                                        <path d="M6.66834 6.74146H0.876953V12.4292H6.66834V6.74146Z"
                                                            fill="white"></path>
                                                        <path d="M13.0941 6.74146H7.30273V12.4292H13.0941V6.74146Z"
                                                            fill="white"></path>
                                                    </svg>
                                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                                                        v-else-if="platformCheck(session.platform) == 'android'"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_33:2611)">
                                                            <path
                                                                d="M13.6253 6.35829H13.625C13.1416 6.35829 12.75 6.74985 12.75 7.23329V11.2333C12.75 11.7164 13.1416 12.108 13.625 12.108C14.1081 12.1077 14.4997 11.7161 14.4997 11.2327V7.23267C14.4997 6.74923 14.1078 6.35767 13.6247 6.35767L13.6253 6.35829Z"
                                                                fill="white"></path>
                                                            <path
                                                                d="M2.37531 6.35878H2.375C1.89156 6.35878 1.5 6.75034 1.5 7.23378V11.2338C1.5 11.7169 1.89156 12.1085 2.375 12.1085C2.85813 12.1082 3.24969 11.7166 3.24969 11.2332V7.23315C3.24969 6.74972 2.85781 6.35815 2.37469 6.35815L2.37531 6.35878Z"
                                                                fill="white"></path>
                                                            <path
                                                                d="M3.875 6.35828V12.3583C3.875 12.9105 4.3225 13.358 4.875 13.358H5.5V15.483C5.5 15.9661 5.89156 16.3577 6.375 16.3577C6.85813 16.3573 7.24969 15.9658 7.24969 15.4823V13.3573H8.74969V15.4823C8.74969 15.9655 9.14125 16.357 9.62469 16.357C10.1078 16.3567 10.4994 15.9652 10.4994 15.4817V13.3567H11.1244L11.1241 13.3567C11.6763 13.3567 12.1241 12.9089 12.1241 12.3567V6.35669L3.875 6.35828Z"
                                                                fill="white"></path>
                                                            <path
                                                                d="M12.0063 5.10839C11.7494 4.19308 11.1375 3.4112 10.3119 2.89683C10.2656 2.86808 10.2188 2.83995 10.1713 2.81308C10.1238 2.78589 10.0759 2.75933 10.0281 2.73339L10.1869 2.44964L10.6834 1.55402V1.55371C10.7184 1.48996 10.6956 1.40996 10.6322 1.37464C10.6319 1.37433 10.6319 1.37433 10.6316 1.37433C10.6122 1.36339 10.5909 1.35777 10.5691 1.35746L10.5688 1.35742C10.5206 1.35711 10.4766 1.38305 10.4534 1.42524L9.95031 2.33305L9.79156 2.61899C9.74156 2.5968 9.69094 2.57586 9.63969 2.55649C9.58844 2.53711 9.53719 2.5168 9.485 2.49836L9.48469 2.49805C8.52375 2.15992 7.47656 2.15992 6.51594 2.49805C6.46375 2.51649 6.41219 2.53586 6.36125 2.55617C6.31 2.5768 6.25938 2.59774 6.20938 2.61867L6.05063 2.33274L5.5475 1.42649L5.54719 1.42617C5.51031 1.36305 5.42938 1.34211 5.36656 1.37899C5.30563 1.4143 5.28375 1.49149 5.31625 1.55367L5.81031 2.44742L5.96906 2.73117C5.92031 2.75649 5.8725 2.78274 5.82531 2.80961C5.77781 2.83649 5.73125 2.86461 5.68531 2.89461C4.86031 3.40899 4.24781 4.19086 3.99156 5.10523H3.99125C3.92031 5.34898 3.88125 5.60117 3.875 5.85523H12.1206L12.1203 5.85492C12.1138 5.60117 12.0747 5.3493 12.0038 5.10555L12.0063 5.10839ZM6.12438 4.60839L6.12406 4.60836C5.91688 4.60805 5.74906 4.44023 5.74906 4.23336C5.74906 4.02617 5.91688 3.85836 6.12406 3.85836C6.33094 3.85836 6.49906 4.02617 6.49906 4.23336V4.23305C6.49906 4.43992 6.33094 4.60805 6.12406 4.60805L6.12438 4.60839ZM9.87438 4.60839L9.87406 4.60836C9.66688 4.60805 9.49906 4.44023 9.49906 4.23336C9.49906 4.02617 9.66688 3.85836 9.87406 3.85836C10.0809 3.85836 10.2491 4.02617 10.2491 4.23336V4.23305C10.2491 4.43992 10.0809 4.60805 9.87406 4.60805L9.87438 4.60839Z"
                                                                fill="white"></path>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_33:2611">
                                                                <rect width="16" height="16" fill="white"
                                                                    transform="translate(0 0.858398)"></rect>
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <svg width="16" height="17" viewBox="0 0 14 20" fill="none"
                                                        v-else-if="platformCheck(session.platform) == 'phone'"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            class="stroke-current text-white transition duration-200 hover:text-blue-700"
                                                            d="M1 3.8C1 2.2536 2.2536 1 3.8 1H10.2C11.7464 1 13 2.2536 13 3.8V16.2C13 17.7464 11.7464 19 10.2 19H3.8C2.2536 19 1 17.7464 1 16.2V3.8Z"
                                                            stroke-width="1.5"></path>
                                                        <circle
                                                            class="fill-current text-white transition duration-200 hover:text-blue-700"
                                                            cx="7" cy="16" r="1"></circle>
                                                        <path
                                                            class="stroke-current text-white transition duration-200 hover:text-blue-700"
                                                            d="M5 4L9 4" stroke-width="1.5" stroke-linecap="round">
                                                        </path>
                                                    </svg>
                                                    <svg width="13" height="17" viewBox="0 0 13 17" fill="none" v-else-if="platformCheck(session.platform) == 'ios'"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M8.82734 3.10879C9.12663 2.76384 9.35483 2.36318 9.49884 1.92979C9.64284 1.49641 9.69982 1.03884 9.6665 0.583374C8.74768 0.657543 7.89476 1.08969 7.2915 1.78671C7.00262 2.12077 6.78371 2.5094 6.64773 2.92959C6.51175 3.34978 6.46146 3.79298 6.49984 4.23296C6.94804 4.23669 7.39108 4.13724 7.79469 3.9423C8.19829 3.74737 8.55162 3.46217 8.82734 3.10879ZM10.8223 8.99879C10.8276 8.39217 10.9874 7.7969 11.2866 7.26916C11.5858 6.74142 12.0145 6.29862 12.5323 5.98254C12.2056 5.51169 11.7736 5.12345 11.2706 4.84862C10.7677 4.57378 10.2076 4.41991 9.63484 4.39921C8.39984 4.27254 7.25984 5.11962 6.60275 5.11962C5.94567 5.11962 5.01942 4.41504 3.99026 4.43087C3.31744 4.45304 2.66185 4.6492 2.08744 5.00023C1.51303 5.35126 1.0394 5.84517 0.712755 6.43379C-0.680578 8.85629 0.356505 12.4584 1.74984 14.4138C2.38317 15.3717 3.17484 16.4563 4.21984 16.4167C5.26484 16.3771 5.60525 15.7675 6.8165 15.7675C8.02775 15.7675 8.39984 16.4167 9.429 16.393C10.4582 16.3692 11.1865 15.4113 11.8515 14.4534C12.3226 13.7579 12.6906 12.998 12.944 12.1971C12.3166 11.9296 11.7813 11.4841 11.4042 10.9157C11.0272 10.3473 10.8249 9.68087 10.8223 8.99879Z"
                                                            fill="white"></path>
                                                    </svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                        viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                                        width="16" height="17" v-else>
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                                    </svg>

                                                </div>
                                                <span class="text-gray-800 dark:text-white  text-sm font-semibold"
                                                    href="#">
                                                    {{ session.platform + ' - ' + session.browser }}
                                                </span>
                                            </div>

                                            <span
                                                class="text-gray-350 dark:text-white col-span-5 text-sm flex items-center">
                                                {{ session.ip }}
                                            </span>
                                            <span
                                                class="text-gray-350 dark:text-white col-span-3 text-sm flex items-center">
                                                {{ session.date }}
                                            </span>
                                            <span
                                                class="text-gray-350 dark:text-white col-span-3 text-sm flex items-center">
                                                {{ session.time }}
                                            </span>
                                            <div class="col-span-4 flex justify-center">
                                                <button
                                                    class="px-1 h-7 pt-0.5 min-w-75 rounded border text-green-600 font-medium border-green-600 text-11 transition duration-200 hover:bg-green-600 hover:text-white"
                                                    v-if="session.active">
                                                    {{ __('Current meeting') }}
                                                </button>
                                                <button @click="removeSession(session.id)"
                                                    class="px-1 h-7 pt-0.5  min-w-75 rounded border text-red-600 font-medium border-red-600 text-11 transition duration-200 hover:bg-red-600 hover:text-white"
                                                    v-else>
                                                    {{ __('delete session') }}
                                                </button>
                                            </div>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    </div>
</template>
<script setup>
import { onMounted } from 'vue';
import useProfile from '../../composables/profile';
const { sessions, getSessions, removeSession, loading, errors } = useProfile();
const platformCheck = (platform) => {
    platform = platform?.toLowerCase();
    let icon = '';
    if (['win', 'windows'].includes(platform)) icon = 'windows';
    else if (platform === 'android') icon = 'android';
    else if (['iPad', 'iPod','iPhone','mac'].includes(platform)) icon = 'ios';
    else if (['Nokia', 'BlackBerry'].includes(platform)) icon = 'phone';
    return icon;
}
onMounted(() => {
    getSessions();
})
</script>