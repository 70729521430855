<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!user.name && errors" @tryAgain="Auth.getUser" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <section class="lg:grid lg:grid-cols-24 lg:gap-6 flex flex-col">
                <div class="xl:col-span-18 xl:col-start-4 col-span-20 col-start-3">
                    <div class="bg-white dark:bg-base-300 rounded-2xl md:px-11 px-3 sm:py-8 py-2">
                        <Form @submit="onSubmit" v-slot="{ errors }">
                            <div class="flex flex-col">
                                <div>
                                    <div class="mb-10  last:mb-0">
                                        <div>
                                            <h2
                                                class="text-dark-550  dark:text-white font-bold flex items-center mb-3 text-2xl">
                                                <i class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full "></i>
                                                {{ __('Account Information') }}
                                            </h2>
                                        </div>
                                        <div>
                                            <div class="relative mb-20">
                                                <div>
                                                    <div class="relative rounded-2xl overflow-hidden">

                                                        <div class="h-52 w-full" id="result"> <img
                                                                :src="backgroundImage"
                                                                class="w-full h-full object-cover"
                                                                :alt="__('User profile picture')">
                                                        </div>

                                                        <div
                                                            class="w-full h-full absolute top-0 right-0 bg-biscay-700 bg-opacity-30 z-0">
                                                        </div>
                                                    </div>
                                                </div>

                                                <label for="choose-image">
                                                    <div
                                                        class="absolute z-30  w-24 h-24 rounded-full md:border-4 sm:right-6 right-1/2 transform sm:translate-x-0 translate-x-1/2 -bottom-11 border-2 lg:ml-5  !border-white object-cover bg-gray-300  overflow-hidden  border-solid">
                                                        <img class="w-full h-full object-cover" :alt="__('User image')"
                                                            :src="auth.user.value.image">
                                                        <input accept=".jpg,.jpeg,.png,.PNG,.JPG,.JPEG,.bmp,.BMP,.webp"
                                                            @change="upload"
                                                            class="absolute z-10 top-0 ring-0 w-full h-full opacity-0 cursor-pointer"
                                                            type="file" id="choose-image" name="image">
                                                        <div
                                                            class="w-full h-full absolute top-0 right-0 bg-biscay-700 bg-opacity-30 z-0">
                                                        </div>

                                                        <svg class="absolute top-1/2 right-1/2 transform translate-x-1/2 -translate-y-1/2"
                                                            width="32" height="29" viewBox="0 0 32 29" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M20.8137 2.41488C22.4128 3.05138 22.9021 5.26804 23.556 5.98054C24.2099 6.69304 25.1457 6.93529 25.6634 6.93529C28.4152 6.93529 30.6462 9.16621 30.6462 11.9165V21.0919C30.6462 24.781 27.6537 27.7735 23.9645 27.7735H8.03616C4.34541 27.7735 1.35449 24.781 1.35449 21.0919V11.9165C1.35449 9.16621 3.58541 6.93529 6.33724 6.93529C6.85341 6.93529 7.78916 6.69304 8.44466 5.98054C9.09858 5.26804 9.58624 3.05138 11.1854 2.41488C12.7862 1.77837 19.2145 1.77837 20.8137 2.41488Z"
                                                                stroke="#ECEDEE" stroke-width="2.375"
                                                                stroke-linecap="round" stroke-linejoin="round"></path>
                                                            <path d="M24.7013 11.0417H24.7155" stroke="#ECEDEE"
                                                                stroke-width="3.16667" stroke-linecap="round"
                                                                stroke-linejoin="round"></path>
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M21.0334 16.7863C21.0334 14.006 18.7803 11.7529 16 11.7529C13.2196 11.7529 10.9666 14.006 10.9666 16.7863C10.9666 19.5667 13.2196 21.8198 16 21.8198C18.7803 21.8198 21.0334 19.5667 21.0334 16.7863Z"
                                                                stroke="#ECEDEE" stroke-width="2.375"
                                                                stroke-linecap="round" stroke-linejoin="round"></path>
                                                        </svg>


                                                    </div>
                                                </label>
                                            </div>
                                            <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-14 gap-y-6">
                                                <div class="flex flex-col">
                                                    <label
                                                        class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium"
                                                        for="">
                                                        {{ __('Full Name') }} <span class="text-error">*</span>
                                                    </label>
                                                    <Field v-model="user.name" rules="required"
                                                        :class="{ 'input-error': errors.name }" class="input"
                                                        type="text" name="name" />
                                                </div>
                                                <div class="flex flex-col">
                                                    <label
                                                        class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium"
                                                        for="">
                                                        {{ __('Role') }}
                                                    </label>
                                                    <Field as="select" disabled name="role" class="select">
                                                        <option value="">{{ user.role }}</option>
                                                    </Field>
                                                </div>
                                                <div class="flex flex-col">
                                                    <label
                                                        class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium"
                                                        for="">
                                                        {{ __('Username (email address)') }}
                                                    </label>
                                                    <Field :value="user.email" rules="required|email" disabled
                                                        :class="{ 'input-error': errors.email }" class="input opacity-60"
                                                        type="email" name="email" />
                                                </div>
                                                <div class="flex flex-col">
                                                    <label
                                                        class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium"
                                                        for="">
                                                        {{ __('Birthday') }}
                                                    </label>
                                                    <DatePicker v-model="user.birthday" name="birthday" type="date"
                                                        inputClass="input" />

                                                </div>
                                                <div class="flex flex-col">
                                                    <label
                                                        class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium"
                                                        for="">
                                                        {{ __('Password') }}
                                                    </label>
                                                    <Field v-model="user.password" class="input" type="text"
                                                        name="password" />
                                                </div>
                                                <div class="flex flex-col">
                                                    <label
                                                        class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium"
                                                        for="">
                                                        {{ __('Confirm Password') }}
                                                    </label>
                                                    <Field v-model="user.password_confirmation" class="input"
                                                        type="text" name="password_confirmation" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="divider h-auto w-full"></div>
                                <div class="text-end">
                                <button type="submit" class="btn btn-primary px-6 mr-2">{{
                                    __('Save Changes')
                                }}</button>
                            </div>
                            </div>
                        </Form>

                    </div>

                </div>
            </section>
        </div>
    </div>
</template>
<script setup>
import { onMounted, reactive, computed } from 'vue';
import Auth from '../../composables/auth';
import { Field, Form } from "vee-validate";
import DatePicker from 'vue3-persian-datetime-picker'
const user = Auth.editUser;
const loading = Auth.loading;
const errors = Auth.errors;
const onSubmit = () => {
    Auth.updateUser()
}
const upload = (e) => Auth.uploadImage(e.target.files[0])
onMounted(() => {
    Auth.getUser();
})
const backgroundImage = computed(() => window.APP_URL + '/assets/uploads/basic/profile_header_default.webp');
</script>