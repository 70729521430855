<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!notifications.length && errors" @tryAgain="getNotifications" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <section>
                <div class="notifications">
                    <div class="flex items-center justify-between lg:flex-row flex-col mb-8">
                        <ul
                            class="bg-white dark:bg-base-300  shadow-sm   md:flex hidden  items-center w-fit-content rounded-lg py-2 px-4">
                            <li class="ml-8 last:ml-0">
                                <button @click="controleState($event, 'unread')"
                                    :class="{ '!bg-blue-700 dark:!bg-base-100  !text-white ': filter === 'unread' }"
                                    class="flex items-center dark:hover:text-blue-450 font-medium text-xl text-chambray-400 pt-1 h-12 px-5 rounded-lg hover:text-gray-800 duration-200 transition">
                                    <svg class="ml-2 -mt-1" width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"
                                            d="M10 20C1.765 20 0 18.235 0 10C0 1.765 1.765 0 10 0C18.235 0 20 1.765 20 10C20 18.235 18.235 20 10 20ZM5.83333 9.16667C5.3731 9.16667 5 9.53976 5 10C5 10.4602 5.3731 10.8333 5.83333 10.8333H14.1667C14.6269 10.8333 15 10.4602 15 10C15 9.53976 14.6269 9.16667 14.1667 9.16667H5.83333ZM7.5 5.83333C7.5 5.3731 7.8731 5 8.33333 5H11.6667C12.1269 5 12.5 5.3731 12.5 5.83333C12.5 6.29357 12.1269 6.66667 11.6667 6.66667H8.33333C7.8731 6.66667 7.5 6.29357 7.5 5.83333ZM8.33333 13.3333C7.8731 13.3333 7.5 13.7064 7.5 14.1667C7.5 14.6269 7.8731 15 8.33333 15H11.6667C12.1269 15 12.5 14.6269 12.5 14.1667C12.5 13.7064 12.1269 13.3333 11.6667 13.3333H8.33333Z">
                                        </path>
                                    </svg>
                                    {{ __('Unread notifications') }}
                                </button>
                            </li>
                            <li class="ml-8 last:ml-0">
                                <button @click="controleState($event, 'read')"
                                    :class="{ '!bg-blue-700 dark:!bg-base-100  !text-white ': filter === 'read' }"
                                    class="flex items-center dark:hover:text-blue-450 font-medium text-xl text-chambray-400 pt-1 h-12 px-5 rounded-lg hover:text-gray-800 duration-200 transition">
                                    <svg class="ml-2 -mt-1" width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"
                                            d="M20 8.8889C20 1.48151 18.235 0 10 0C1.765 0 0 1.56889 0 8.8889C0 13.609 0.833537 16.6667 4.58354 16.6667C6.58759 16.6667 7.18374 17.6186 7.73886 18.5051C8.22249 19.2774 8.67499 20 10.0002 20C11.3255 20 11.778 19.2774 12.2616 18.5051C12.8167 17.6186 13.4129 16.6667 15.4169 16.6667C19.1669 16.6667 20 13.7037 20 8.8889ZM6.66667 10C7.1269 10 7.5 9.6269 7.5 9.16667C7.5 8.70643 7.1269 8.33333 6.66667 8.33333C6.20643 8.33333 5.83333 8.70643 5.83333 9.16667C5.83333 9.6269 6.20643 10 6.66667 10ZM10.8333 9.16667C10.8333 9.6269 10.4602 10 10 10C9.53976 10 9.16667 9.6269 9.16667 9.16667C9.16667 8.70643 9.53976 8.33333 10 8.33333C10.4602 8.33333 10.8333 8.70643 10.8333 9.16667ZM13.3333 10C13.7936 10 14.1667 9.6269 14.1667 9.16667C14.1667 8.70643 13.7936 8.33333 13.3333 8.33333C12.8731 8.33333 12.5 8.70643 12.5 9.16667C12.5 9.6269 12.8731 10 13.3333 10Z">
                                        </path>
                                    </svg>
                                    {{ __('Notifications read') }}
                                </button>
                            </li>
                        </ul>

                        <div class="relative group md:hidden lg:w-1/3 w-full">
                            <div
                                class=" bg-white cursor-pointer text-blue-700  dark:bg-base-300  dark:text-white py-2 flex items-center justify-between px-5 h-16 rounded">
                                <span class="flex font-medium text-lg  items-center">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M0.75 11C0.75 13.2475 0.871405 15.0024 1.17704 16.3776C1.48077 17.7443 1.9564 18.6896 2.63339 19.3666C3.31039 20.0436 4.25571 20.5192 5.62241 20.823C6.99762 21.1286 8.75249 21.25 11 21.25C13.2475 21.25 15.0024 21.1286 16.3776 20.823C17.7443 20.5192 18.6896 20.0436 19.3666 19.3666C20.0436 18.6896 20.5192 17.7443 20.823 16.3776C21.1286 15.0024 21.25 13.2475 21.25 11C21.25 8.75249 21.1286 6.99762 20.823 5.62241C20.5192 4.25571 20.0436 3.31039 19.3666 2.63339C18.6896 1.9564 17.7443 1.48077 16.3776 1.17704C15.0024 0.871405 13.2475 0.75 11 0.75C8.75249 0.75 6.99762 0.871405 5.62241 1.17704C4.25571 1.48077 3.31039 1.9564 2.63339 2.63339C1.9564 3.31039 1.48077 4.25571 1.17704 5.62241C0.871405 6.99762 0.75 8.75249 0.75 11Z"
                                            stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                        <path opacity="0.4"
                                            d="M11.0001 6.41663V15.5833M15.5834 10.0833V15.5833M6.41675 11.9166V15.5833"
                                            stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round"></path>
                                    </svg>
                                    <span class="mr-2">{{ state }}</span>
                                </span>
                                <span
                                    class="border-r h-full flex items-center pr-5 dark:border-opacity-10 dark:border-gray-810 border-blue-700 border-opacity-60 ">
                                    <svg width="8" height="5" viewBox="0 0 8 5" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.06597 0.94873L3.95486 4.05984L0.84375 0.94873" stroke="currentColor"
                                            stroke-width="1.23077" stroke-linecap="round" stroke-linejoin="round">
                                        </path>
                                    </svg>
                                </span>
                            </div>

                            <div class="absolute z-10 hidden group-hover:inline-block top-full pt-3 w-full">
                                <ul class="bg-white dark:bg-base-300  px-2 space-y-2 py-3 rounded">
                                    <li>
                                        <button @click="controleState($event, 'unread')" data-filter-id="unread"
                                            :class="{ '!bg-blue-700 dark:!bg-base-100 !text-white ': filter === 'unread' }"
                                            class="flex w-full items-center font-medium  text-chambray-400 pt-1  h-11 px-5 rounded-lg">
                                            <svg class="ml-2 -mt-1" width="20" height="20" viewBox="0 0 20 20"
                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M10 20C1.765 20 0 18.235 0 10C0 1.765 1.765 0 10 0C18.235 0 20 1.765 20 10C20 18.235 18.235 20 10 20ZM5.83333 9.16667C5.3731 9.16667 5 9.53976 5 10C5 10.4602 5.3731 10.8333 5.83333 10.8333H14.1667C14.6269 10.8333 15 10.4602 15 10C15 9.53976 14.6269 9.16667 14.1667 9.16667H5.83333ZM7.5 5.83333C7.5 5.3731 7.8731 5 8.33333 5H11.6667C12.1269 5 12.5 5.3731 12.5 5.83333C12.5 6.29357 12.1269 6.66667 11.6667 6.66667H8.33333C7.8731 6.66667 7.5 6.29357 7.5 5.83333ZM8.33333 13.3333C7.8731 13.3333 7.5 13.7064 7.5 14.1667C7.5 14.6269 7.8731 15 8.33333 15H11.6667C12.1269 15 12.5 14.6269 12.5 14.1667C12.5 13.7064 12.1269 13.3333 11.6667 13.3333H8.33333Z">
                                                </path>
                                            </svg>
                                            {{ __('Unread notifications') }}
                                        </button>
                                    </li>
                                    <li>
                                        <button @click="controleState($event, 'read')" data-filter-id="read"
                                            :class="{ '!bg-blue-700 dark:!bg-base-100 !text-white ': filter === 'read' }"
                                            class="flex items-center w-full font-medium text-chambray-400 pt-1 h-11 px-5 rounded-lg">
                                            <svg class="ml-2 -mt-1" width="20" height="20" viewBox="0 0 20 20"
                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M20 8.8889C20 1.48151 18.235 0 10 0C1.765 0 0 1.56889 0 8.8889C0 13.609 0.833537 16.6667 4.58354 16.6667C6.58759 16.6667 7.18374 17.6186 7.73886 18.5051C8.22249 19.2774 8.67499 20 10.0002 20C11.3255 20 11.778 19.2774 12.2616 18.5051C12.8167 17.6186 13.4129 16.6667 15.4169 16.6667C19.1669 16.6667 20 13.7037 20 8.8889ZM6.66667 10C7.1269 10 7.5 9.6269 7.5 9.16667C7.5 8.70643 7.1269 8.33333 6.66667 8.33333C6.20643 8.33333 5.83333 8.70643 5.83333 9.16667C5.83333 9.6269 6.20643 10 6.66667 10ZM10.8333 9.16667C10.8333 9.6269 10.4602 10 10 10C9.53976 10 9.16667 9.6269 9.16667 9.16667C9.16667 8.70643 9.53976 8.33333 10 8.33333C10.4602 8.33333 10.8333 8.70643 10.8333 9.16667ZM13.3333 10C13.7936 10 14.1667 9.6269 14.1667 9.16667C14.1667 8.70643 13.7936 8.33333 13.3333 8.33333C12.8731 8.33333 12.5 8.70643 12.5 9.16667C12.5 9.6269 12.8731 10 13.3333 10Z">
                                                </path>
                                            </svg>
                                            {{ __('Notifications read') }}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>

                    <div class="bg-white dark:bg-base-300 rounded-2xl lg:px-9 px-4 pt-9 pb-6">
                        <li v-for="notification in notifications"
                            class="flex items-center dark:bg-base-100 dark:border-transparent justify-between lg:flex-row flex-col border border-gray-220 rounded-lg py-3 lg:px-5 px-3 mb-3">
                            <div class="flex items-center self-start">
                                <svg class="text-red-600 flex-shrink-0" v-if="notification.type == 'danger'" width="30"
                                    height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="30" height="30" rx="5" fill="currentColor"></rect>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M22.9881 15.0544C22.9744 15.176 22.9547 15.2588 22.9154 15.4243C21.7287 20.421 17.5155 22.2845 15.7681 22.8529C15.4666 22.951 15.3159 23 15 23C14.6841 23 14.5334 22.951 14.2319 22.8529C12.4845 22.2845 8.27126 20.421 7.08458 15.4243C7.04526 15.2588 7.02559 15.176 7.01193 15.0544C6.99826 14.9328 6.99919 14.8325 7.00106 14.6321C7.05944 8.3602 8.54671 7 15 7C21.4533 7 22.9406 8.3602 22.9989 14.6321C23.0008 14.8325 23.0017 14.9328 22.9881 15.0544ZM15 11.0166C15.3682 11.0166 15.6667 11.3163 15.6667 11.686V15.7026C15.6667 16.0723 15.3682 16.372 15 16.372C14.6318 16.372 14.3333 16.0723 14.3333 15.7026V11.686C14.3333 11.3163 14.6318 11.0166 15 11.0166ZM14.3333 17.7109C14.3333 17.3411 14.6318 17.0414 15 17.0414C15.3682 17.0414 15.6667 17.3411 15.6667 17.7109C15.6667 18.0806 15.3682 18.3803 15 18.3803C14.6318 18.3803 14.3333 18.0806 14.3333 17.7109Z"
                                        fill="white"></path>
                                </svg>
                                <svg class="text-green-600 flex-shrink-0" width="30" height="30" viewBox="0 0 30 30"
                                    fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
                                    <rect width="30" height="30" rx="5" fill="currentColor"></rect>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M15 7C21.588 7 23 8.412 23 15C21.6667 21.6667 15 23 15 23C15 23 8.33333 21.6667 7 15C7 8.412 8.412 7 15 7ZM18.1381 12.5286C18.3984 12.789 18.3984 13.2111 18.1381 13.4714L14.8047 16.8047C14.5444 17.0651 14.1223 17.0651 13.8619 16.8047L12.5286 15.4714C12.2683 15.2111 12.2683 14.789 12.5286 14.5286C12.789 14.2683 13.2111 14.2683 13.4714 14.5286L14.3333 15.3905L17.1953 12.5286C17.4556 12.2683 17.8777 12.2683 18.1381 12.5286Z"
                                        fill="white"></path>
                                </svg>
                                <p class="mx-3 text-chambray-700 dark:text-white font-medium text-15"
                                    v-html="notification.message"></p>
                            </div>
                            <div class="flex items-center mr-auto flex-shrink-0">
                                <span class="text-gray-350 font-normal text-13">
                                    {{ notification.created_at }}
                                </span>
                            </div>
                        </li>
                        <div class="col-span-12 flex flex-col items-center mt-9" v-if="!notifications.length">
                            <NotFound />
                        </div>
                        <Pagination :meta="meta" @setPage="setPage" class="!mt-0" />
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted, onUpdated } from 'vue';
import useProfile from '../../composables/profile';
const { notifications, getNotifications, loading, errors, meta, router, route } = useProfile();
const state = ref(null);
const filter = ref(route.query.filter ||'unread');
const setQuery = (key, value) => {
    getNotifications(window.setQuery(router, route, key, value));
}
const setPage = (p) => {
    setQuery('page', p.toString());
};
const controleState = (e, value) => {
    if (value === filter.value) return false;
    state.value = e.target.textContent.trim();
    filter.value = value;
    setQuery('filter', value);
}
onMounted(() => {
    getNotifications(route.query);
});
onUpdated(() => {
    state.value = document.querySelector(`[data-filter-id=${filter.value}]`)?.textContent?.trim();
});
</script>