<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!messages.length && errors" @tryAgain="getMessages" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-10 pb-14 " v-else>
        <div class="container">
            <div class="flex flex-col md:flex-row justify-between items-center mb-6 lg:mb-8">
                <h3 class="flex items-center md:text-26 text-xl dark:text-white text-biscay-700 font-semibold">
                    <i class="md:flex hidden w-2 h-2 dark:bg-white rounded-full bg-blue-700 ml-2"></i>
                    {{ __('Messages') }}
                </h3>
                <div class="flex mt-6 md:mt-0 flex-col lg:flex-row w-full md:w-auto justify-end">
                    <div class="flex mt-4 lg:mt-0 justify-end">
                        <input
                            class="input w-full h-10 xl:pl-36 placeholder-gray-320 md:w-64 xl:w-80 ml-2 bg-gray-350 bg-opacity-10 rounded-lg  transition duration-200 text-sm text-gray-350 px-3 font-medium dark:!bg-base-300 dark:!text-white  focus:bg-blue-700 focus:bg-opacity-10 border border-blue-700  focus:border-opacity-100"
                            type="text" :placeholder="__('Search...')" v-model="searchValue">
                        <button @click="search"
                            class="bg-blue-600 disabled:opacity-70 disabled:!bg-blue-600 flex items-center h-10 md:px-5 px-3 rounded-md text-white font-semibold text-sm transition duration-200 hover:bg-blue-800 ">
                            {{ __('Search') }}
                        </button>
                    </div>
                    <div class="flex order-first justify-between lg:order-last">
                        <div class="dropdown dropdown-hover lg:mr-4">
                        <div tabindex="0" class="btn btn-info btn-outline btn-base">
                         <span>{{ state}}</span>
                                <i
                                    class="flex md:ml-1 ml-2 md:mr-2 mr-2 h-6 border-l dark:border-blue-450 border-blue-700 border-opacity-30 transition duration-200"></i>
                                <svg width="10" height="7" viewBox="0 0 10 7" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        class="stroke-current dark:text-blue-450 text-blue-700 transition duration-200"
                                        d="M8.58333 1.60425L4.79167 5.39591L1 1.60425" stroke-width="1.5"
                                        stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                        </div>
                        <ul tabindex="0" class="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box lg:w-40">
                            <li class="text-xs lg:text-base" v-for="item in statusTypes"><a :class="{'btn-active': ($route.query.status || 'all') == item}" @click="controleState($event, item)" :data-filter-id="item">{{ __(item.replace('_', ' ').capitalize())  }}</a></li>
                        </ul>
                    </div>
                    </div>
                    <div class="flex justify-end order-first lg:order-last lg:mr-4" v-if="can('sms.create')">
                        <router-link :to="{ name: 'user.message.create' }" class="btn btn-outline btn-info btn-base">
                            <span class="mt-0.5">{{ __('Add message') }}</span>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="rounded-2xl bg-white dark:bg-base-300">
                <div class=" overflow-x-auto">
                    <table class="table table-xs md:table-sm xl:table-md" v-if="messages.length">
                        <thead>
                            <tr>
                                <th>
                                    #
                                </th>
                                <th>
                                    {{ __('Text Message') }}
                                </th>
                                <th>
                                    {{ __('The number of SMS') }}
                                </th>
                                <th>{{ __('Amount paid') }}</th>
                                <th>
                                    {{ __('Status') }}
                                </th>
                                <th>
                                    {{ __('Created At') }}
                                </th>
                                <th>
                                    {{ __('Action') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr v-for="item in messages">
                                <td>
                                    {{ item.id }}
                                </td>
                                <td class="w-36 md:w-44 xl:w-96">
                                    <p class="w-36 md:w-44 xl:w-96 truncate">{{ item.content }}</p>
                                </td>
                                <td>
                                    {{ item.receivers + ' ' + __('Number') }}
                                </td>
                                
                                <td>{{ item.amount.toLocaleString() + ' ' + __('Toman') }}</td>
                                <td
                                    :class="[item.status == 'canceled' ? '!text-red-600' : (item.status == 'pending' ? '!text-orange-600' : '!text-green-600')]">
                                    {{ __(item.status.capitalize()) }}
                                </td>
                                <td>
                                    {{ item.created_at }}
                                </td>
                                <td>
                                    <label for="show-modal" @click="setMessage(item)"
                                        class="btn btn-outline btn-primary btn-sm">
                                        {{ __('detail') }}
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                        <Pagination :meta="meta" @setPage="setPage" class="!mt-6" />
                    </table>
                    <div class="col-span-12 flex flex-col items-center mt-9" v-else>
                        <NotFound />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Teleport to="#app">
        <input type="checkbox" id="show-modal" class="modal-toggle" />
        <div class="modal">
            <div class="modal-box overflow-hidden">
                <div class="modal-content">
                    <h3 class="font-bold text-lg">{{ __('Message details') }}</h3>
                    <div class="mt-4">
                        <table class="table table-hover w-full">
                            <tbody>
                                <tr>
                                    <th class="w-1/2">{{ __('The number of SMS') }}</th>
                                    <td class="w-1/2">{{ sms.receivers + ' ' + __('Number') }}</td>
                                </tr>
                                <tr>
                                    <th class="w-1/2">{{ __('The number of SMS parties') }}</th>
                                    <td class="w-1/2">{{ sms.part_count + ' ' + __('Part') }}</td>
                                </tr>
                                <tr>
                                    <th class="w-1/2">{{ __('Cost per message') }}</th>
                                    <td class="w-1/2">{{ sms.cost?.toLocaleString() + ' ' + __('Toman') }}</td>
                                </tr>
                                <tr v-if="sms.shipping_time">
                                    <th class="w-1/2">{{ __('shipping time') }}</th>
                                    <td class="w-1/2">{{ sms.shipping_time }}</td>
                                </tr>
                                <tr>
                                    <th class="w-1/2">{{ __('Census') }}</th>
                                    <td class="w-1/2">{{ sms.census }}</td>
                                </tr>
                                <tr>
                                    <th class="w-1/2">{{ __('Amount paid') }}</th>
                                    <td class="w-1/2">{{ sms.amount?.toLocaleString() + ' ' + __('Toman') }}</td>
                                </tr>
                                <tr>
                                    <th class="w-1/2">{{ __('Status') }}</th>
                                    <td class="w-1/2">{{ __(sms.status?.capitalize()) }}</td>
                                </tr>
                                <tr>
                                    <th class="w-1/2">{{ __('Send status') }}</th>
                                    <td class="w-1/2">{{ __(sms.situation?.replace('_',' ')?.capitalize()) }}</td>
                                </tr>
                                <tr v-if="sms.situation == 'finish'">
                                    <th class="w-1/2">{{ __('Successful messages') }}</th>
                                    <td class="w-1/2">{{ sms.successful }}</td>
                                </tr>
                                <tr v-if="sms.situation == 'finish'">
                                    <th class="w-1/2">{{ __('Unsuccessful messages') }}</th>
                                    <td class="w-1/2">{{ sms.unsuccessful }}</td>
                                </tr>
                                <tr v-if="sms.situation == 'finish'">
                                    <th class="w-1/2">{{ __('return fee') }}</th>
                                    <td class="w-1/2">{{ sms.return_fee?.toLocaleString() + ' ' + __('Toman') }}</td>
                                </tr>
                                <tr>
                                    <th class="w-1/2">{{ __('Created At') }}</th>
                                    <td class="w-1/2">{{ sms.created_at }}</td>
                                </tr>
                                <tr>
                                    <th colspan="2">{{ __('Text Message') }}</th>
                                </tr>
                                <tr>
                                    <td colspan="2" class="py-0">{{ sms.content  }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-action">
                    <label for="show-modal" class="btn btn-sm">{{ __('Close') }}</label>
                </div>
            </div>
        </div>
    </Teleport>
</template>
<script setup>
import { ref, onMounted,computed } from 'vue';
import useDashboard from '../../composables/dashboard';
const { getMessages, loading, errors, meta, router, route, messages, sms } = useDashboard();
const setQuery = (key, value) => {
    getMessages(window.setQuery(router, route, key, value));
}
const searchValue = ref(route.query.search || '');
const statusTypes = computed(() => ['all','pending', 'confirmed', 'canceled']);
const state = ref('');
const setPage = (p) => {
    setQuery('page', p.toString());
};
const controleState = (e, filter) => {
    state.value = e.target.textContent.trim()
    setQuery('status', filter);
}
const setMessage = (item) => Object.assign(sms, item);
const search = () => {
    setQuery('search', searchValue.value)
}
onMounted(() => {
    getMessages(route.query);
    state.value = document.querySelector(`[data-filter-id=${route.query.status || 'all'}]`)?.textContent?.trim()
});
</script>