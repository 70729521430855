<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!opportunities.length && errors" @tryAgain="getOpportunities" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <div class="flex flex-col md:flex-row justify-between items-center md:items-center mb-6 lg:mb-8">
                <h3 class="flex items-center md:text-26 text-2xl dark:text-white text-biscay-700 font-semibold">
                    <i class="md:flex hidden w-2 h-2 dark:bg-white rounded-full bg-blue-700 ml-2"></i>
                    {{ __('Opportunities') }}
                </h3>
                <div class="flex mt-6 md:mt-0 flex-col lg:flex-row w-full md:w-auto justify-end">
                    <div class="flex mt-4 lg:mt-0 justify-end">
                        <input
                            class="input w-full h-10 xl:pl-36 placeholder-gray-320 md:w-64 xl:w-80 ml-2 bg-gray-350 bg-opacity-10 rounded-lg  transition duration-200 text-sm text-gray-350 px-3 font-medium dark:!bg-base-300 dark:!text-white  focus:bg-blue-700 focus:bg-opacity-10 border border-blue-700  focus:border-opacity-100"
                            type="text" :placeholder="__('Search...')" v-model="searchValue">
                        <button @click="search"
                            class=" bg-blue-600 disabled:opacity-70 disabled:!bg-blue-600 flex items-center h-10 md:px-5 px-3 rounded-md text-white font-semibold text-sm transition duration-200 hover:bg-blue-800 ">
                            {{ __('Search') }}
                        </button>
                    </div>
                    <div class="flex order-first justify-between lg:order-last">
                        <div class="dropdown dropdown-hover lg:mr-4">
                            <div tabindex="0" class="btn btn-info btn-outline btn-base">
                                <span>{{ state }}</span>
                                <i
                                    class="flex md:ml-1 ml-2 md:mr-2 mr-2 h-6 border-l dark:border-blue-450 border-blue-700 border-opacity-30 transition duration-200"></i>
                                <svg width="10" height="7" viewBox="0 0 10 7" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        class="stroke-current dark:text-blue-450 text-blue-700 transition duration-200"
                                        d="M8.58333 1.60425L4.79167 5.39591L1 1.60425" stroke-width="1.5"
                                        stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </div>
                            <ul tabindex="0"
                                class="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box lg:w-52">
                                <li class="text-xs lg:text-base" v-for="item in salesStageTypes"><a
                                        :class="{ 'btn-active': ($route.query.status || 'all') == item }"
                                        @click="controleState($event, item)" :data-filter-id="item">{{
                                            __(item.replaceAll('_', ' ').capitalize()) }}</a></li>
                            </ul>
                        </div>
                        <div class="flex justify-end order-first lg:order-last lg:mr-4" v-if="can('opportunity.create')">
                            <router-link :to="{ name: 'user.opportunity.create' }" class="btn btn-outline btn-info btn-base">
                                <span class="mt-0.5">{{ __('Create opportunity') }}</span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rounded-2xl dark:bg-base-300 bg-white">
                <div class=" overflow-auto">
                    <table class="table table-xs md:table-sm xl:table-md" v-if="opportunities.length">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th> {{ __('Opportunity name') }} </th>
                                <th> {{ __('Account name') }} </th>
                                <th> {{ __('Sales stage') }} </th>
                                <th> {{ __('Amount') }} </th>
                                <th> {{ __('Sale date') }} </th>
                                <th> {{ __('User') }} </th>
                                <th> {{ __('Created At') }} </th>
                                <th> {{ __('Action') }} </th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in opportunities">
                                <td> {{ (meta.per_page * (meta.current_page - 1)) + index + 1 }} </td>
                                <td> {{ item.name }} </td>
                                <td> {{ item.account_name || '---' }} </td>
                                <td> {{ __(item.sales_stage.replaceAll('_',' ').capitalize()) || '---' }} </td>
                                <td> {{ item.amount.toLocaleString() + ' ' + __(item.currency.capitalize()) || '---' }} </td>
                                <td> {{ item.date_closed || '---' }} </td>
                                <td> {{ item.user }} </td>
                                <td> {{ item.created_at }}</td>
                                <td>
                                    <router-link :to="{ name: 'user.opportunity.show', params: { id: item.id } }"
                                        :class="{ 'btn-disabled': !can('opportunity.view.all') && !can('opportunity.view.self', item.self) }"
                                        class="btn btn-outline btn-primary btn-sm ml-2">
                                        {{ __('Show') }}
                                    </router-link>
                                    <router-link :to="{ name: 'user.opportunity.edit', params: { id: item.id } }"
                                        :class="{ 'btn-disabled': !can('opportunity.edit.all') && !can('opportunity.edit.self', item.self) }"
                                        class="btn btn-outline btn-info btn-sm ml-2">
                                        {{ __('Edit') }}
                                    </router-link>
                                    <label @click="setItem(item)" for="delete-modal"
                                        :class="{ 'btn-disabled': !can('opportunity.delete.all') && !can('opportunity.delete.self', item.self) }"
                                        class="btn btn-outline btn-error btn-sm">
                                        {{ __('Delete') }}
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="col-span-12 flex flex-col items-center mt-9" v-else>
                        <NotFound />
                    </div>
                </div>
            </div>
            <Pagination :meta="meta" @setPage="setPage" class="!mt-6" />
        </div>
    </div>
    <Teleport to="#app">
        <input type="checkbox" id="delete-modal" class="modal-toggle" />
        <label class="modal" for="delete-modal">
            <label class="modal-box" for="">
                <h3 class="font-bold text-lg">{{ __('Delete opportunity') }}</h3>
                <p class="py-4">{{ __('Are you sure to delete this opportunity?') }}</p>
                <div class="modal-action">
                    <label class="btn btn-sm" for="delete-modal"
                        :class="{ 'pointer-events-none opacity-60': loadingBtn }">{{
                            __('Cancel') }}</label>
                    <button class="btn btn-error btn-sm text-white" @click="removeOpportunity"
                        :class="{ 'loading': loadingBtn }">
                        {{ __('Delete') }}
                    </button>
                </div>
            </label>
        </label>
    </Teleport>
</template>
<script setup>
import { ref, onMounted, computed, onUpdated } from 'vue';
import useDashboard from '../../composables/dashboard';
const { getOpportunities, loading, errors, meta, router, remove, route,loadingBtn, opportunities, removeOpportunity } = useDashboard();
const searchValue = ref(route.query.search || '');
const salesStageTypes = ['all','prospecting', 'qualification', 'proposal_price_quote', 'negotiation_review', 'closed_won', 'closed_lost'];
const state = ref('');
const setQuery = (key, value) => {
    getOpportunities(window.setQuery(router, route, key, value));
}
const controleState = (e, filter) => {
    state.value = e.target.textContent.trim()
    setQuery('status', filter);
}
const setPage = (p) => {
    setQuery('page', p.toString());
};
const setItem = (item) => Object.assign(remove, item);
const search = () => {
    setQuery('search', searchValue.value)
}
onMounted(() => {
    getOpportunities(route.query);
});
onUpdated(() => state.value = document.querySelector(`[data-filter-id=${route.query.status || 'all'}]`)?.textContent?.trim())
</script>