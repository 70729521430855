<template>
    <Form @submit="onSubmit" v-slot="{ errors }" @invalid-submit="onInvalidSubmit">
        <div class="flex flex-col">
            <div>
                <div class="mb-10  last:mb-0">
                    <h2 class="text-dark-550  dark:text-white font-bold flex items-center text-xl">
                        <i class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full "></i>
                        {{ state }}
                    </h2>
                    <div class="divider col-span-full mt-0 mb-3"></div>
                    <div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 1">
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Type') }} <span class="text-error">*</span>
                                </label>
                                <Field as="select" v-model="account.type" rules="required"
                                    :class="{ 'input-error': errors.type }" class="select" name="type">
                                    <option value="genuine">{{ __('Genuine') }}</option>
                                    <option value="legal">{{ __('Legal') }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Name') }} <span class="text-error">*</span>
                                </label>
                                <Field v-model="account.name" rules="required"
                                    :class="{ 'input-error': errors.name }" class="input" type="text"
                                    name="name" />
                            </div>
                            <div class="flex flex-col">
                                <div class="flex justify-between">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium"
                                        for="">
                                        {{ __('Phone number') }}
                                    </label>
                                    <button @click="addNumber(account.numbers, index)" type="button"
                                        class="btn btn-xs btn-link no-underline">{{ __('Adding')
                                        }}</button>
                                </div>
                                <div class="flex mb-2" v-for="(info, index) in account.numbers">
                                    <div class="join w-full">
                                        <select class="select join-item w-auto" v-model="info.type">
                                            <option v-for="item in numberTypes" :value="item">
                                                {{ __(item.capitalize()) }}
                                            </option>
                                        </select>
                                        <Field class="input join-item" dir="ltr" v-model="info.value" name="numbers[value]" @input="checkNumber('account',$event,account.id)" type="number" />
                                    </div>
                                    <div class="mr-1 flex flex-col justify-center">
                                        <button type="button" v-if="account.numbers.length > 1"
                                            class="btn btn-xs btn-error btn-circle" @click="removeNumber(index)">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none"
                                                viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>

                                </div>
                                <small class="text-error">{{ message }}</small>
                            </div>
                            <div class="flex flex-col">
                                <div class="flex justify-between">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium"
                                        for="">
                                        {{ __('Email') }}
                                    </label>
                                    <button @click="addEmail(index)" class="btn btn-xs btn-link no-underline" type="button">{{
                                        __('Adding')
                                        }}</button>
                                </div>
                                <div class="flex mb-2" v-for="(info, index) in account.emails">
                                    <input class="input join-item" v-model="info.value" />

                                    <div class="mr-1 flex flex-col justify-center">
                                        <button type="button" v-if="account.emails.length > 1"
                                            class="btn btn-xs btn-error btn-circle" @click="removeEmail(index)">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none"
                                                viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                    d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>

                                </div>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Website') }}
                                </label>
                                <Field v-model="account.website" :class="{ 'input-error': errors.website }" class="input"
                                    type="text" name="website" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Account type') }}
                                </label>
                                <Field as="select" v-model="account.account_type"
                                    :class="{ 'input-error': errors.account_type }" class="select" name="account_type">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in accountTypes" :value="item">{{ __(item?.capitalize()) }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Account ID') }}
                                </label>
                                <Field v-model="account.account_id" :class="{ 'input-error': errors.account_id }" class="input"
                                    type="text" name="account_id" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Customer code') }}
                                </label>
                                <Field v-model="account.account_code" :class="{ 'input-error': errors.account_code }" class="input"
                                    type="text" name="account_code" />
                            </div>
                            <div class="flex flex-col col-span-full">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Description') }}
                                </label>
                                <Field as="textarea" v-model="account.description"
                                    :class="{ 'input-error': errors.description }" class="textarea"
                                    name="description" />
                            </div>
                        </div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 2">
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('The level of attractiveness') }}
                                </label>
                                <Field as="select" v-model="account.rating" :class="{ 'input-error': errors.rating }"
                                    class="select" name="rating">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option value="hot">{{ __('Hot') }}</option>
                                    <option value="warm">{{ __('Warm') }}</option>
                                    <option value="cold">{{ __('Cold') }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Campaign') }}
                                </label>
                                <Field as="select" v-model="account.campaign_id"
                                    :class="{ 'input-error': errors.campaign_id }" class="select" name="campaign_id">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in meta.campaigns" :value="item.id">{{ item.name }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Industry') }}
                                </label>
                                <Field as="select" v-model="account.industry" @change="changeIndustry"
                                    :class="{ 'input-error': errors.industry }" class="select" name="industry">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in meta.industries" :value="item.title">{{ item.title }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Sub industry') }}
                                </label>
                                <Field as="select" v-model="account.subindustry"
                                    :class="{ 'input-error': errors.subindustry }" class="select" name="subindustry">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in industries" :value="item.title">{{ item.title }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Member') }}
                                </label>
                                <Field as="select" v-model="account.parent_id"
                                    :class="{ 'input-error': errors.parent_id }" class="select" name="parent_id">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in meta.accounts" :value="item.id">{{ item.name }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Another name') }}
                                </label>
                                <Field v-model="account.name_alias" :class="{ 'input-error': errors.name_alias }"
                                    class="input" type="text" name="name_alias" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Ownership') }}
                                </label>
                                <Field v-model="account.ownership" :class="{ 'input-error': errors.ownership }"
                                    class="input" type="text" name="ownership" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Economic code') }}
                                </label>
                                <Field v-model="account.tax_id" :class="{ 'input-error': errors.tax_id }" class="input"
                                    type="number" name="tax_id" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Registration number') }}
                                </label>
                                <Field v-model="account.registration_number"
                                    :class="{ 'input-error': errors.registration_number }" class="input" type="number"
                                    name="registration_number" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('National Number/National ID') }}
                                </label>
                                <Field v-model="account.national_number"
                                    :class="{ 'input-error': errors.national_number }" class="input" type="number"
                                    name="national_number" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Following') }}
                                </label>
                                <Field as="select" v-model="account.is_foreigner" :class="{ 'input-error': errors.is_foreigner }"
                                    class="select" name="is_foreigner">
                                    <option value="1">{{ __('Yes') }}</option>
                                    <option value="0" selected>{{ __('No') }}</option>
                                </Field>
                            </div>
                        </div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 3">
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Country') }}
                                </label>
                                <Field as="select" v-model="account.billing.country"
                                    :class="{ 'input-error': errors.billing_country }" class="select"
                                    name="billing_country">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option :value="__('Iran')">{{ __('Iran') }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('State') }}
                                </label>
                                <Field as="select" v-model="account.billing.state" @change="changeState"
                                    :class="{ 'input-error': errors.billing_state }" class="select" name="billing_state">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in meta.states" :value="item.name">{{ item.name }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('City') }}
                                </label>
                                <Field as="select" v-model="account.billing.city" @change="changeCity"
                                    :class="{ 'input-error': errors.billing_city }" class="select" name="billing_city">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option v-for="item in cities" :value="item.name">{{ item.name }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Postal Code') }}
                                </label>
                                <Field v-model="account.billing.postalcode"
                                    :class="{ 'input-error': errors.billing_postalcode }" class="input" type="number"
                                    name="billing_postalcode" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Address') }}
                                </label>
                                <Field v-model="account.billing.street" :class="{ 'input-error': errors.billing_street }"
                                    class="input" type="text" name="billing_street" />
                            </div>
                            <div class="flex items-end">
                                <div class="flex flex-col flex-1">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium"
                                        for="">
                                        {{ __('Latitude') }}
                                    </label>
                                    <Field v-model="account.billing.latitude"
                                        :class="{ 'input-error': errors.billing_latitude }" class="input" type="number"
                                        name="billing_latitude" />
                                </div>
                                <div class="flex flex-col mr-1 flex-1">
                                    <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium"
                                        for="">
                                        {{ __('Longitude') }}
                                    </label>
                                    <Field v-model="account.billing.longitude"
                                        :class="{ 'input-error': errors.billing_longitude }" class="input" type="number"
                                        name="billing_longitude" />
                                </div>
                                <label class="btn btn-primary mr-1" for="modal-map" @click="setMap">
                                    <MapPinIcon class="w-5 h-5" />
                                </label>
                            </div>
                        </div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 4">
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Credit status') }}
                                </label>
                                <Field as="select" v-model="account.credit.status" :class="{ 'input-error': errors.credit_status }"
                                    class="select" name="credit_status">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option value="has_credit">{{ __('Has credit') }}</option>
                                    <option value="no_credit">{{ __('No credit') }}</option>
                                    <option value="not_calculated">{{ __('Not calculated') }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Credit type') }}
                                </label>
                                <Field as="select" v-model="account.credit.type" :class="{ 'input-error': errors.credit_type }"
                                    class="select" name="credit_type">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option value="cheque">{{ __('Cheque') }}</option>
                                    <option value="open_account">{{ __('Open account') }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Expiration date') }}
                                </label>
                                <DatePicker v-model="account.credit.expiration_date" name="credit_expiration_date" inputClass="input" type="date" />

                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Credit source') }}
                                </label>
                                <Field as="select" v-model="account.credit.source" :class="{ 'input-error': errors.credit_source }"
                                    class="select" name="credit_source">
                                    <option value="">{{ __('Select...') }}</option>
                                    <option value="referral">{{ __('Referral') }}</option>
                                    <option value="guarantee_letter">{{ __('Guarantee letter') }}</option>
                                    <option value="title_deed">{{ __('Title deed') }}</option>
                                </Field>
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Credit limit') }}
                                </label>
                                <Field v-model="account.credit.limit" :class="{ 'input-error': errors.credit_limit }"
                                    class="input" type="number" name="credit_limit" />
                            </div>
                            <div class="flex flex-col">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Sales discount (%)') }}
                                </label>
                                <Field v-model="account.credit.sales_discount"
                                    :class="{ 'input-error': errors.credit_sales_discount }" class="input" type="number"
                                    name="credit_sales_discount" />
                            </div>
                            <div class="flex flex-col col-span-full">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('Credit source description') }}
                                </label>
                                <Field as="textarea" v-model="account.credit.source_description" :class="{ 'textarea-error': errors.credit_source_description }" class="textarea"
                                     name="credit_source_description" />
                            </div>
                        </div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 5">
                            <div class="flex flex-col" v-for="item in socialTypes">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __(item.capitalize()) }}
                                </label>
                                <Field v-model="account.social[item]" class="input" type="text" :name="item" />
                            </div>
                        </div>
                        <div class="grid md:grid-cols-2 grid-cols-1 md:gap-x-6 gap-y-4" v-show="step == 6">
                            <div class="flex flex-col col-span-full">
                                <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-medium" for="">
                                    {{ __('File') }}
                                </label>
                                <Field class="file-input pr-0" @change="addAttachment($event,'attachment')" type="file" name="file" />
                            </div>
                            <div class="overflow-x-auto col-span-full">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{{ __('File title') }}</th>
                                            <th>{{ __('File type') }}</th>
                                            <th>{{ __('Status') }}</th>
                                            <th>{{ __('Action') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,index) in account.attachments">
                                            <th>{{ index + 1 }}</th>
                                            <th>{{ item.name }}</th>
                                            <td>{{ item.mime_type }}</td>
                                            <td>
                                                <div v-if="isNumeric(item.status)">
                                                    <progress class="progress progress-info w-16" :value="item.status" max="100"></progress> <span class="mr-0.5 inline-block w-4">{{ item.status + '%' }}</span>
                                                </div>
                                                <div v-else>{{ __(item.url ? 'Uploaded' : 'Uploading') }}</div>
                                            </td>
                                            <td>
                                    <button @click="removeFile(account.attachments,index)" type="button"
                                        class="btn btn-outline btn-error btn-sm">
                                        {{ __('Delete') }}
                                    </button>
                                </td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="text-center" v-if="!account.attachments.length">
                                        <tr>
                                            <td colspan="5">{{ __('No file found to display') }}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider h-auto w-full"></div>
            <div class="text-end">
                <button type="submit" class="btn btn-primary px-6 mr-2">{{
                    __('Save Changes')
                }}</button>
            </div>
        </div>
    </Form>
    <input type="checkbox" id="modal-map" class="modal-toggle" />
    <div class="modal">
        <div class="modal-box max-w-5xl">
            <div class="h-[600px]">
            <l-map ref="map" v-model:zoom="zoom" :center="centerLatLng" v-if="showMap" :use-global-leaflet="false" @update:center="updateCenter">
                <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" layer-type="base"
                    name="OpenStreetMap"></l-tile-layer>
                    <l-marker :lat-lng="markerLatLng"></l-marker>
            </l-map>
        </div>
            <div class="modal-action justify-between items-center">
                <div>
                    <label class="btn btn-error ml-2 btn-sm" for="modal-map">{{ __('Cancel') }}</label>
                    <label class="btn btn-success btn-sm text-white" for="modal-map" @click="setLocation">{{ __('Save')
                        }}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import useDashboard from '../../composables/dashboard';
import DatePicker from 'vue3-persian-datetime-picker'
import { LMap, LTileLayer,LMarker } from "@vue-leaflet/vue-leaflet";
import { Field, Form } from "vee-validate";
import { MapPinIcon } from "@heroicons/vue/24/outline";
const props = defineProps(['account','meta','step','state']);
const showMap = ref(false);
const zoom = 11;
const centerLatLng = ref([35.7029075623, 51.3497581482]);
const markerLatLng = ref(centerLatLng.value);
const numberTypes = ['office', 'mobile', 'fax', 'factory', 'home', 'whatsapp', 'other'];
const accountTypes = ['customer', 'vendor', 'partner', 'competitor', 'investor', 'press','prospect','reseller','other'];
const socialTypes = ['linkedin', 'instagram', 'blog', 'facebook', 'twitter', 'telegram'];
const { uploadFile, removeFile,account, getCities,storeAccount,updateAccount,getSubIndustries,checkNumber,route,loading,message,industries, cities} = useDashboard();
Object.assign(account,props.account);
if(account.industry) getSubIndustries(account.industry);
if(account.billing.state) getCities(account.billing.state);
const addNumber = (index) => account.numbers.splice(index + 1, 0, { type: 'office', value: '' });
const addEmail = (index) => account.emails.splice(index + 1, 0, { value: '' });
const removeNumber = (i) => account.numbers = account.numbers.filter((item, index) => index != i);
const removeEmail = (i) => account.emails = account.emails.filter((item, index) => index != i);
const updateCenter = (latlng) => markerLatLng.value = [latlng.lat,latlng.lng];
const addAttachment = (event,type) => {
    account.attachments = [...account.attachments,{title: event.target.files[0].name,type: 'attachment',status: 'uploading'}];
    uploadFile(event,type,account.attachments[account.attachments.length - 1]);
}
function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
const changeIndustry = () => {
    account.subindustry = '';
    getSubIndustries(account.industry);
}
const changeState = () => {
    account.billing.city = '';
    account.billing.latitude = '';
    account.billing.longitude = '';
    getCities(account.billing.state);
}
const changeCity = () => {
    account.billing.latitude = '';
    account.billing.longitude = '';
}
const setMap = () => {
    showMap.value = true;
    const billing = account.billing;
    if(billing.latitude && billing.longitude) centerLatLng.value = [billing.latitude,billing.longitude];
    else if(cities.value.find(item => billing.city == item.name)) {
        const city = cities.value.find(item => billing.city == item.name);
        centerLatLng.value = [city.latitude,city.longitude];
    }else if(props.meta.states.find(item => billing.state == item.name)) {
        const state = props.meta.states.find(item => billing.state == item.name);
        centerLatLng.value = [state.latitude,state.longitude];
    }
    markerLatLng.value = centerLatLng.value;
}
const setLocation = () => Object.assign(account.billing, { latitude: markerLatLng.value[0], longitude: markerLatLng.value[1] })
const onSubmit = () => {
    if(route.params.id) updateAccount();
    else storeAccount();
}
const onInvalidSubmit = ({ errors }) => {
   
};
</script>