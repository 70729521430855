<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!calendar.length && errors" @tryAgain="getCalendar" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container dark:bg-base-300 bg-white p-3">
            <FullCalendar class='app-calenda' :options='calendarOptions'>
                <template v-slot:eventContent='arg'>
                    <VTooltip>
                        <div class="flex items-center px-1 cursor-pointer">
                            <ClipboardDocumentCheckIcon class="w-6 h-6" v-if="arg.event.extendedProps.type == 'task'" />
                            <PhoneIcon class="w-6 h-6" v-else-if="arg.event.extendedProps.type == 'contact'" />
                            <BriefcaseIcon class="w-6 h-6" v-else />
                            <div class="mr-1">
                                <span class="block text-sm font-semibold">{{ arg.event.title }}</span>
                                <span class="block text-sm">{{ arg.event.extendedProps.status }}</span>
                            </div>
                        </div>
                        <template #popper>
                            <div class='flex flex-col'>
                                <span class="text-sm">{{ __('Subject') }}: <span>{{ arg.event.title }}</span></span>
                                <span class="text-sm">{{ __('Type') }}: {{ __(arg.event.extendedProps.type.capitalize())
                                    }}</span>
                                <span class="text-sm">{{ __('Parent Type') }}: <a class="btn-link" target="_blank"
                                        :href="arg.event.extendedProps.model.link">{{ arg.event.extendedProps.model.name
                                        }}</a></span>
                                <span class="text-sm">{{ __('Status') }}: {{ arg.event.extendedProps.status }}</span>
                            </div>
                        </template>
                    </VTooltip>
                </template>
            </FullCalendar>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue';
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list';
import momentPlugin from '@fullcalendar/moment';
import useDashboard from '../../composables/dashboard';
import { PhoneIcon, BriefcaseIcon,ClipboardDocumentCheckIcon } from '@heroicons/vue/24/outline';
const { getCalendar, loading, calendar, errors, router, route } = useDashboard();
const handleEventClick = (info) => {
    router.push({ name: 'user.' + info.event.extendedProps.type + '.edit', params: { id: info.event.id } });
}
const calendarOptions = computed(() => ({
    plugins: [
        dayGridPlugin,
        timeGridPlugin,
        listPlugin,
        momentPlugin,
    ],
    headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'listWeek,dayGridMonth,timeGridWeek'
    },
    buttonText: {
        today: window.i18n['Today'],
        month: window.i18n['Month'],
        week: window.i18n['Week'],
        day: window.i18n['Day'],
        listWeek: window.i18n['Programs']
    },
    eventDisplay: 'block',
    eventMinHeight: 45,
    allDaySlot: false,
    allDayText: window.i18n['All day'],
    navLinks: true,
    dayMaxEvents: true,
    locale: 'fa',
    direction: 'rtl',
    initialView: 'dayGridMonth',
    // initialEvents: INITIAL_EVENTS,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    events: calendar.value,
    firstDay: -1,
    // select: handleDateSelect,
    eventClick: handleEventClick,
    // eventsSet: this.handleEvents
}))

onMounted(() => {
    getCalendar(route.date || new Date().toISOString().slice(0, 10));
});
</script>