<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!settings?.sms_credit_limit && errors" @tryAgain="getSettings" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <section class="lg:grid lg:grid-cols-24 lg:gap-6 flex flex-col">
                <div class="xl:col-span-18 xl:col-start-4 col-span-20 col-start-3">
                    <div class="bg-white dark:bg-base-300 rounded-2xl md:px-11 px-3 sm:py-8 py-2">
                        <Form @submit="onSubmit" v-slot="{ errors }">
                            <div class="flex flex-col">
                                <div>
                                    <div class="mb-10  last:mb-0">
                                        <div>
                                            <h2
                                                class="text-dark-550  dark:text-white font-bold flex items-center mb-3 text-2xl">
                                                <i class="w-2 h-2 bg-gray-350 dark:bg-white ml-2 rounded-full "></i>
                                                {{ __('Settings') }}
                                            </h2>
                                        </div>
                                        <div>
                                            <div class="relative mb-6">
                                                <div>
                                                    <div class="relative rounded-2xl overflow-hidden">

                                                        <div class="h-52 w-full" id="result"> <img
                                                                :src="backgroundImage"
                                                                class="w-full h-full object-cover"
                                                                :alt="__('User profile picture')">
                                                        </div>

                                                        <div
                                                            class="w-full h-full absolute top-0 right-0 bg-biscay-700 bg-opacity-30 z-0">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="form-control">
                                                        <label class="label cursor-pointer">
                                                            <span class="label-text">{{ __('Account login SMS')
                                                                }}</span>
                                                            <input type="checkbox" class="toggle" name="sms_login"
                                                                v-model="settings.sms_login" :class="{ 'checkbox-error': errors.sms_login }" />
                                                        </label>
                                                    </div>
                                                    <div class="form-control">
                                                        <label class="label cursor-pointer">
                                                            <span class="label-text">{{ __('User birthday greetings SMS')
                                                                }}</span>
                                                            <input type="checkbox" class="toggle" name="sms_birthday"
                                                                v-model="settings.sms_birthday" :class="{ 'checkbox-error': errors.sms_birthday }" />
                                                        </label>
                                                    </div>
                                                    <div class="form-control">
                                                        <label class="label cursor-pointer">
                                                            <span class="label-text">{{ __('User check date SMS')
                                                                }}</span>
                                                            <input type="checkbox" class="toggle" name="sms_check_date"
                                                                v-model="settings.sms_check_date" :class="{ 'checkbox-error': errors.sms_check_date }" />
                                                        </label>
                                                    </div>
                                                    <label class="form-control">
                                                        <div class="label">
                                                            <span class="label-text">
                                                                {{ __('Charge completion notification limit (toman)') }}
                                                            </span>
                                                        </div>
                                                        <Field type="number" class="input input-bordered w-full" rules="required"
                                                            v-model="settings.sms_credit_limit" name="sms_credit_limit" :class="{ 'input-error': errors.sms_credit_limit }" />
                                                        <div class="label">
                                                            <span class="label-text-alt">{{ __('SMS to mobile number') +
                                                                ' ' + settings.phone + ' ' + __('It will be sent.')
                                                                }}</span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="divider h-auto w-full"></div>
                                <div class="text-end">
                                    <button type="submit" class="btn btn-primary px-6 mr-2">{{
                                        __('Save Changes')
                                        }}</button>
                                </div>
                            </div>
                        </Form>

                    </div>

                </div>
            </section>
        </div>
    </div>
</template>
<script setup>
import { onMounted, computed } from 'vue';
import { Field, Form } from "vee-validate";
import useDashboard from '../../composables/dashboard';
const { loading, getSettings,updateSettings,settings, errors, meta } = useDashboard();
const onSubmit = () => {
    updateSettings()
}
onMounted(() => {
    getSettings();
})
const backgroundImage = computed(() => window.APP_URL + '/assets/uploads/basic/setting_header.jpg');
</script>