<template>
    <ul :class="[first ? 'mt-6 mr-0' : 'menu z-1 !static lg:!absolute']"
        class="bg-gray-50 dark:bg-base-300 lg:dark:!shadow-whiteShadow rounded-lg lg:shadow-nav-link w-52 lg:w-auto">
        <li v-for="child in children" v-bind="{ 'tabindex': child.children ? '0' : undefined }"
            class="h-full lg:!static block lg:flex w-full">
            <router-link :to="`/${child.slug}`" v-if="child.slug" active-class=""
                class="flex !bg-transparent text-sm 2xl:text-base font-bold 2xl:font-medium text-biscay-700 dark:hover:text-gray-20 dark:text-white hover:text-gray-800">
                {{ child.title }}
                <span class="mr-auto caret" v-if="child.children || child.items" @click.prevent="dropdownOpen($event)">
                    <svg class="text-biscay-700 dark:hover:text-gray-20 dark:text-white lg:rotate-90 w-2.5 h-1.5 2xl:w-3 2xl:h-2"
                        viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.2466 1.81357C12.2466 2.09063 12.1407 2.36913 11.929 2.58091L6.89555 7.63898C6.69102 7.8435 6.41397 7.9581 6.12386 7.9581C5.8352 7.9581 5.55814 7.8435 5.35362 7.63898L0.317309 2.58091C-0.106252 2.1559 -0.106252 1.46834 0.32021 1.04333C0.746671 0.619768 1.43568 0.621218 1.85924 1.04623L6.12386 5.3297L10.3885 1.04623C10.812 0.621218 11.4996 0.619768 11.9261 1.04333C12.1407 1.25511 12.2466 1.53506 12.2466 1.81357Z"
                            fill="currentColor" fill-opacity="0.9"></path>
                    </svg>
                </span>
            </router-link>
            <a :href="(child.link || child.slug)" active-class="" v-else
                class="flex !bg-transparent text-sm font-bold text-biscay-700 dark:hover:text-gray-20 dark:text-white hover:text-gray-800">
                {{ child.title }}
                <span class="mr-auto caret" v-if="child.children || child.mega" @click.prevent="dropdownOpen($event)">
                    <svg class="text-biscay-700 dark:hover:text-gray-20 dark:text-white lg:rotate-90 w-2.5 h-1.5 2xl:w-3 2xl:h-2"
                        viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.2466 1.81357C12.2466 2.09063 12.1407 2.36913 11.929 2.58091L6.89555 7.63898C6.69102 7.8435 6.41397 7.9581 6.12386 7.9581C5.8352 7.9581 5.55814 7.8435 5.35362 7.63898L0.317309 2.58091C-0.106252 2.1559 -0.106252 1.46834 0.32021 1.04333C0.746671 0.619768 1.43568 0.621218 1.85924 1.04623L6.12386 5.3297L10.3885 1.04623C10.812 0.621218 11.4996 0.619768 11.9261 1.04333C12.1407 1.25511 12.2466 1.53506 12.2466 1.81357Z"
                            fill="currentColor" fill-opacity="0.9"></path>
                    </svg>
                </span>
            </a>
            <VerticalMenu v-if="child.children" :children="child.children" />
        </li>
    </ul>
</template>
<script setup>
import VerticalMenu from "./VerticalMenu.vue";
defineProps(['children', 'first']);
const dropdownOpen = (event) => {
    event.currentTarget.parentElement.parentElement.focus();
};
</script>