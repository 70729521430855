<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!industries.length && errors" @tryAgain="getIndustries" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <div
                class="flex flex-col md:flex-row justify-between items-center md:items-center mb-6 lg:mb-8">
                <h3 class="flex items-center md:text-26 text-2xl dark:text-white text-biscay-700 font-semibold">
                    <i class="md:flex hidden w-2 h-2 dark:bg-white rounded-full bg-blue-700 ml-2"></i>
                    {{ __('Industries') }}
                </h3>
                <div class="flex mt-6 md:mt-0 flex-col lg:flex-row w-full md:w-auto justify-end">
                    <div class="flex mt-4 lg:mt-0 justify-end">
                        <input
                            class="input w-full h-10 xl:pl-36 placeholder-gray-320 md:w-64 xl:w-80 ml-2 bg-gray-350 bg-opacity-10 rounded-lg  transition duration-200 text-sm text-gray-350 px-3 font-medium dark:!bg-base-300 dark:!text-white  focus:bg-blue-700 focus:bg-opacity-10 border border-blue-700  focus:border-opacity-100"
                            type="text" :placeholder="__('Search...')" v-model="searchValue">
                        <button @click="search"
                            class="bg-blue-600 disabled:opacity-70 disabled:!bg-blue-600 flex items-center h-10 md:px-5 px-3 rounded-md text-white font-semibold text-sm transition duration-200 hover:bg-blue-800 ">
                            {{ __('Search') }}
                        </button>
                    </div>
                    <div class="flex justify-end order-first lg:order-last lg:mr-4" v-if="can('industry.create')">
                        <router-link :to="{name: 'user.industry.create'}" class="btn btn-outline btn-info btn-base">
                            <span class="mt-0.5">{{ __('Add industry') }}</span>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="rounded-2xl dark:bg-base-300 bg-white">
                <div class=" overflow-x-auto">
                    <table class="table table-xs md:table-sm xl:table-md" v-if="industries.length">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th> {{ __('Title') }} </th>
                                <th> {{ __('User') }} </th>
                                <th class="hidden xl:block"> {{ __('Description') }} </th>
                                <th> {{ __('Created At') }} </th>
                                <th> {{ __('Action') }} </th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in industries">
                                <td> {{ (meta.per_page * (meta.current_page - 1)) + index + 1 }} </td>
                                <td> {{ item.title }} </td>
                                <td class="hidden xl:block"> {{ item.description || '---' }} </td>
                                <td> {{ item.user || '---' }} </td>
                                <td> {{ item.created_at }}</td>
                                <td>
                                    <router-link :to="{name: 'user.industry.show',params: {id: item.id}}" :class="{'btn-disabled': !can('industry.view.all') && !can('industry.view.self',item.self)}"
                                        class="btn btn-outline btn-primary btn-sm">
                                        {{ __('Show') }}
                                    </router-link>
                                    <router-link :to="{name: 'user.industry.edit',params: {id:item.id}}" :class="{'btn-disabled': !can('industry.edit.all') && !can('industry.edit.self',item.self)}"
                                        class="btn btn-outline btn-info btn-sm mx-2">
                                        {{ __('Edit') }}
                                    </router-link>
                                    <label @click="setItem(item)" for="delete-modal" :class="{'btn-disabled': !can('industry.delete.all') && !can('industry.delete.self',item.self)}"
                                        class="btn btn-outline btn-error btn-sm">
                                        {{ __('Delete') }}
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="col-span-12 flex flex-col items-center mt-9" v-else>
                        <NotFound />
                    </div>
                </div>
            </div>
            <Pagination :meta="meta" @setPage="setPage" class="!mt-6" />
        </div>
    </div>
    <Teleport to="#app">
        <input type="checkbox" id="delete-modal" class="modal-toggle" />
        <label class="modal" for="delete-modal">
            <label class="modal-box" for="">
                <h3 class="font-bold text-lg">{{ __('Delete industry') }}</h3>
                <p class="py-4">{{ __('Are you sure to delete this industry?') }}</p>
                <div class="modal-action">
                    <label class="btn btn-sm" for="delete-modal"
                        :class="{ 'pointer-events-none opacity-60': loadingBtn }">{{
                            __('Cancel') }}</label>
                    <button class="btn btn-error btn-sm text-white" @click="removeIndustry"
                        :class="{ 'loading': loadingBtn }">
                        {{ __('Delete') }}
                    </button>
                </div>
            </label>
        </label>
    </Teleport>

</template>
<script setup>
import { ref, onMounted, computed } from 'vue';
import useDashboard from '../../composables/dashboard';
const { getIndustries,industries, loading, removeIndustry, errors,remove, meta, router, route, loadingBtn } = useDashboard();
const searchValue = ref(route.query.search || '');
const setQuery = (key, value) => {
    getIndustries(window.setQuery(router, route, key, value));
}
const setPage = (p) => {
    setQuery('page', p.toString());
};
const setItem = (item) => Object.assign(remove, item);

const search = () => {
    setQuery('search', searchValue.value)
}
onMounted(() => {
    getIndustries(route.query);
});
</script>