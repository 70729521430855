<template>
    <Progressbar v-if="loading && !user.name" />
    <NetworkError v-else-if="!user.title && errors" @tryAgain="getData" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <div class="flex justify-between items-center mb-6">
                <h3 class="flex items-center md:text-26 text-2xl dark:text-white text-biscay-700 font-semibold">
                    <i class="md:flex hidden w-2 h-2 dark:bg-white rounded-full bg-blue-700 ml-2"></i>
                    {{ __('User details') }}
                </h3>
                <div class="flex justify-end order-last">
                        <router-link :to="{name: 'user.user.edit',params: {id: user.id}}" class="btn btn-outline btn-info btn-sm"
                        :class="{'btn-disabled': !can('user.edit') }">
                            {{ __('Edit') }}
                        </router-link>
                        <label @click="setItem(user)" for="delete-modal" :class="{'btn-disabled': !can('user.delete') || user.admin}"
                                        class="btn btn-outline btn-error btn-sm mr-2">
                                        {{ __('Delete') }}
                        </label>
                    </div>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse-plus collapse">
                <input type="checkbox" checked />
                <div class="collapse-title text-xl font-medium">
                    {{ __('personal information') }}
                </div>
                <div class="collapse-content">
                    <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                        <p class="text-gray-70">{{ __('Full Name') }}: <span class="text-black dark:text-white">{{
                            user.name
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Username') }}: <span class="text-black dark:text-white">{{
                            user.email
                                }}</span></p>
                                <p class="text-gray-70">{{ __('Email') }}: <span class="text-black dark:text-white" v-for="item in user.emails.filter(item => item.value)">{{
                            item.value
                                }}</span></p>
                        <p class="text-gray-70">{{ __('User Role') }}: <span class="text-black dark:text-white">{{
                            user.roles_name
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Status') }}: <span  :class="[user.status == 'active' ? 'text-success' : 'text-error']">{{
                            __(user.status.capitalize()) }}</span></p>
                        <p class="text-gray-70" v-if="user.avatar">{{ __('User image') }}: <label class="btn-link text-info cursor-pointer text-sm no-underline" for="modal-image" @click="setImage(user.avatar)">{{ __('Show image') }}</label></p>
                        <p class="text-gray-70" v-if="user.signature">{{ __('User Signature') }}: <label class="btn-link text-info cursor-pointer text-sm no-underline" for="modal-image" @click="setImage(user.signature)">{{ __('Show image') }}</label></p>
                        <p class="text-gray-70">{{ __('Created At') }}: <span class="text-black dark:text-white">{{
                            user.created_at }}</span></p>
                        <p class="text-gray-70">{{ __('Update date') }}: <span class="text-black dark:text-white">{{
                            user.updated_at }}</span></p>
                              <div class="divider h-auto col-span-full my-0" v-if="user.numbers.filter(item => item.value).length"></div>
                        <p class="text-gray-70" v-for="item in user.numbers.filter(item => item.value)">{{ __(item.type.capitalize()) }}: <span class="text-black dark:text-white">{{ item.value }}</span></p>
                    </div>
                </div>
            </div>
            <div class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('User information') }}
                </div>
                <div class="collapse-content">
                    <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                        <p class="text-gray-70">{{ __('Staff status') }}: <span class="text-black dark:text-white">{{
                            __(user.employee_status?.capitalize()) || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Title') }}: <span class="text-black dark:text-white">{{
                            user.title || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Telephone system internal number') }}: <span
                                class="text-black dark:text-white">{{ user.extension || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Reports to') }}: <span class="text-black dark:text-white">{{
                            user.reports_to_name || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Unit') }}: <span class="text-black dark:text-white">{{
                            user.department || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Side') }}: <span class="text-black dark:text-white">{{
                            user.side || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Start Date') }}: <span class="text-black dark:text-white">{{
                            user.start_date || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('The date of the last change of position') }}: <span
                                class="text-black dark:text-white">{{
                                    user.last_promotion_date || '---' }}</span></p>
                        <div class="divider h-auto col-span-full my-0"></div>
                        <p class="text-gray-70">{{ __('Description') }}: <span class="text-black dark:text-white">{{
                            user.description || '---' }}</span></p>
                    </div>
                </div>
            </div>
            <div class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('Address') }}
                </div>
                <div class="collapse-content">
                    <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                        <p class="text-gray-70">{{ __('Country') }}: <span class="text-black dark:text-white">{{
                            user.billing.country || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('State') }}: <span class="text-black dark:text-white">{{
                            user.billing.state || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('City') }}: <span
                                class="text-black dark:text-white">{{ user.billing.city || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Postal Code') }}: <span class="text-black dark:text-white">{{
                            user.billing.postalcode || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Address') }}: <span class="text-black dark:text-white">{{
                            user.billing.street || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Latitude') }}: <span class="text-black dark:text-white">{{
                            user.billing.latitude || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Longitude') }}: <span class="text-black dark:text-white">{{
                            user.billing.longitude || '---' }}</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Teleport to="#app">
        <input type="checkbox" id="delete-modal" class="modal-toggle" />
        <label class="modal" for="delete-modal">
            <label class="modal-box" for="">
                <h3 class="font-bold text-lg">{{ __('Delete user') }}</h3>
                <p class="py-4">{{ __('Are you sure to delete this user?') }}</p>
                <div class="modal-action">
                    <label class="btn btn-sm" for="delete-modal"
                        :class="{ 'pointer-events-none opacity-60': loadingBtn }">{{
                            __('Cancel') }}</label>
                    <button class="btn btn-error btn-sm text-white" @click="removeUser"
                        :class="{ 'loading': loadingBtn }">
                        {{ __('Delete') }}
                    </button>
                </div>
            </label>
        </label>
    </Teleport>
    <Teleport to="#app">
        <ImageModal :image="image" />
    </Teleport>
</template>
<script setup>
import useDashboard from "../../composables/dashboard";
import ImageModal from '../modal/ImageModal.vue';
import { computed, onMounted, reactive, ref } from 'vue';
const { getUser, user, route, loading, meta,remove, errors, removeUser, loadingBtn } = useDashboard();
const setItem = (item) => Object.assign(remove,{id: item.id,single: true});
const image = ref('');
const setImage = (value) => image.value = value;
const getData = () => {
    getUser();
}
onMounted(() => {
    getData();
});
</script>