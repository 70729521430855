<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!clue.name && errors" @tryAgain="getData" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <section class="lg:grid lg:grid-cols-24 lg:gap-6 flex flex-col">
                <div class="xl:col-span-6 col-span-8 lg:mb-0 mb-5">
                    <ul class="text-lg hidden lg:block" id="steps">
                        <li class="mb-2 last:mb-0">
                            <button
                                :class="{ '!text-blue-700 !border-blue-700 !border-opacity-100 dark:!border-blue-700': step === 1 }"
                                @click="setStep($event.target, 1)"
                                class="px-6 w-full font-medium py-4 transition duration-200 border-opacity-25 text-gray-60  border-gray-300 group  border-2 bg-white dark:bg-base-300 dark:border-transparent  rounded-2xl flex items-center">
                                <span :class="{ '!bg-blue-700 !bg-opacity-100': step == 1 }"
                                    class="w-9 bg-gray-350 group-hover:bg-opacity-100 transition duration-200 h-9 ml-4 flex items-center justify-center rounded-lg bg-opacity-50">
                                    <UserIcon class="w-5 h-5 text-white" />
                                </span>
                                {{ __('Create person') }}
                            </button>
                        </li>
                        <li class="mb-2 last:mb-0" v-if="convert.types.includes('account')">
                            <button
                                :class="{ '!text-blue-700 !border-blue-700 !border-opacity-100 dark:!border-blue-700': step === 2 }"
                                @click="setStep($event.target, 2)"
                                class="px-6  w-full font-medium py-4 transition duration-200 border-opacity-25 text-gray-60  border-gray-300  group groupbg-white dark:bg-base-300 dark:border-transparent border-2 rounded-2xl  flex items-center">
                                <span
                                    :class="{ '!bg-blue-700 !bg-opacity-100': step == 2, 'group-hover:bg-opacity-100': step > 2 }"
                                    class="w-9 bg-gray-350 transition duration-200  h-9 ml-4 flex items-center justify-center rounded-lg bg-opacity-50">
                                    <IdentificationIcon class="w-5 h-5 text-white" />
                                </span>
                                {{ __('Create account') }}
                            </button>
                        </li>
                        <li class="mb-2 last:mb-0" v-if="convert.types.includes('opportunity')">
                            <button
                                :class="{ '!text-blue-700 !border-blue-700 !border-opacity-100 dark:!border-blue-700': step === 3 }"
                                @click="setStep($event.target, 3)"
                                class="px-6  w-full font-medium py-4 transition duration-200 border-opacity-25 text-gray-60  border-gray-300  group group bg-white dark:bg-base-300 dark:border-transparent border-2 rounded-2xl  flex items-center">
                                <span
                                    :class="{ '!bg-blue-700 !bg-opacity-100': step == 3, 'group-hover:bg-opacity-100': step > 3 }"
                                    class="w-9 bg-gray-350 transition duration-200  h-9 ml-4 flex items-center justify-center rounded-lg bg-opacity-50">
                                    <LightBulbIcon class="w-5 h-5 text-white" />
                                </span>
                                {{ __('Create opportunity') }}
                            </button>
                        </li>
                        <li class="mb-2 last:mb-0" v-if="convert.types.includes('appointment')">
                            <button
                                :class="{ '!text-blue-700 !border-blue-700 !border-opacity-100 dark:!border-blue-700': step === 4 }"
                                @click="setStep($event.target, 4)"
                                class="px-6  w-full font-medium py-4 transition duration-200 border-opacity-25 text-gray-60  border-gray-300  group group bg-white dark:bg-base-300 dark:border-transparent rounded-2xl border-2  flex items-center">
                                <span
                                    :class="{ '!bg-blue-700 !bg-opacity-100': step == 4, 'group-hover:bg-opacity-100': step > 4 }"
                                    class="w-9 bg-gray-350 transition duration-200  h-9 ml-4 flex items-center justify-center rounded-lg bg-opacity-50">
                                    <BriefcaseIcon class="w-5 h-5 text-white" />
                                </span>
                                {{ __('Make an appointment') }}
                            </button>
                        </li>
                    </ul>
                    <div class="relative md:hidden group mb-6 w-full" id="courses-page">
                        <div
                            class="dark:bg-base-300 bg-white cursor-pointer dark:text-white text-blue-700  py-3 flex items-center justify-between px-5 h-16 rounded">
                            <span class="flex font-medium text-lg items-center">
                                <span class="mr-2">{{ state }}</span>
                            </span>
                            <span class="border-r h-full flex items-center pr-5 border-blue-700 border-opacity-30 ">
                                <UserIcon class="w-5 h-5 text-white" />
                            </span>
                        </div>
                        <div class="absolute z-40 hidden group-hover:inline-block top-full pt-3 w-full">
                            <ul class="bg-white dark:bg-base-300  px-2 space-y-2 py-3 rounded">
                                <li>
                                    <button @click="setStep($event.target, 1)"
                                        :class="{ '!bg-blue-700 dark:!bg-base-100 !text-white ': step === 1 }"
                                        class="flex w-full items-center font-medium  text-chambray-400 pt-1  h-11 px-5 rounded-lg">
                                        <UserIcon class="w-5 h-5 text-white ml-2 -mt-1" />
                                        {{ __('Create person') }}
                                    </button>
                                </li>
                                <li v-if="convert.types.includes('account')">
                                    <button @click="setStep($event.target, 2)"
                                        :class="{ '!bg-blue-700 dark:!bg-base-100 !text-white ': step === 2 }"
                                        class="flex items-center w-full font-medium text-chambray-400 pt-1 h-11 px-5 rounded-lg">
                                        <IdentificationIcon class="w-5 h-5 text-white ml-2 -mt-1" />
                                        {{ __('Create account') }}
                                    </button>
                                </li>
                                <li v-if="convert.types.includes('opportunity')">
                                    <button @click="setStep($event.target, 3)"
                                        :class="{ '!bg-blue-700 dark:!bg-base-100 !text-white ': step === 3 }"
                                        class="flex items-center w-full font-medium text-chambray-400 pt-1 h-11 px-5 rounded-lg">
                                        <LightBulbIcon class="w-5 h-5 text-white ml-2 -mt-1" />
                                        {{ __('Create opportunity') }}
                                    </button>
                                </li>
                                <li v-if="convert.types.includes('appointment')">
                                    <button @click="setStep($event.target, 4)"
                                        :class="{ '!bg-blue-700 dark:!bg-base-100 !text-white ': step === 4 }"
                                        class="flex items-center w-full font-medium text-chambray-400 pt-1 h-11 px-5 rounded-lg">
                                        <BriefcaseIcon class="w-5 h-5 text-white ml-2 -mt-1" />
                                        {{ __('Make an appointment') }}
                                    </button>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div class="xl:col-span-18 col-span-16">
                    <h2 class="text-3xl font-bold mb-3">{{ __('Clue convert') }}</h2>
                    <div class="grid grid-cols-2 md:grid-cols-4 mb-3 gap-4">
                        <div class="form-control">
                            <label class="label cursor-pointer justify-start">
                                <input type="checkbox" value="person" v-model="convert.types" class="checkbox checkbox-primary"
                                    disabled />
                                <span class="label-text mr-3">{{ __('Become an person') }}</span>
                            </label>
                        </div>
                        <div class="form-control">
                            <label class="label cursor-pointer justify-start">
                                <input type="checkbox" value="account" v-model="convert.types"
                                    class="checkbox checkbox-primary" />
                                <span class="label-text mr-3">{{ __('Become an account') }}</span>
                            </label>
                        </div>
                        <div class="form-control">
                            <label class="label cursor-pointer justify-start">
                                <input type="checkbox" value="opportunity" v-model="convert.types"
                                    class="checkbox checkbox-primary" />
                                <span class="label-text mr-3">{{ __('Become an opportunity') }}</span>
                            </label>
                        </div>
                        <div class="form-control">
                            <label class="label cursor-pointer justify-start">
                                <input type="checkbox" value="appointment" v-model="convert.types"
                                    class="checkbox checkbox-primary" />
                                <span class="label-text mr-3">{{ __('Convert to dating') }}</span>
                            </label>
                        </div>
                    </div>
                    <div class="bg-white dark:bg-base-300 rounded-2xl md:px-6 px-3 sm:pb-6 sm:pt-4 py-2">
                        <ConvertClue :clue="clue" :meta="meta" :step="step" :state="state" :convert="convert" @setStep="setStep" />
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive, ref, watch } from 'vue';
import useDashboard from '../../composables/dashboard';
import ConvertClue from '../form/ConvertClue.vue';
import { UserIcon, IdentificationIcon, LightBulbIcon, BriefcaseIcon } from "@heroicons/vue/24/outline";
const step = ref(1);
const convert = reactive({types: ['person', 'account']});
const state = ref(window.i18n['Create person']);
const { clue, meta, loading, converterClue, errors, route } = useDashboard();
const setStep = (e, value) => {
    step.value = value;
    state.value = e.textContent.trim();
};
watch(() => convert.types,(value) => {
    if(!value.includes(step.value.toString().replace(2,'account').replace(3,'opportunity').replace(4,'appointment'))) step.value = 1;
})
const getData = () => {
    converterClue();
}
onMounted(() => getData());
</script>