import { ref, reactive, inject } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { useHead, useSeoMeta } from "@vueuse/head";
import Auth from "./auth";

export default function useHome() {
  const home = reactive({});
  const dashboard = reactive({clue_chart: {},contacts: [],appointments:[]});
  const popup = ref(null);
  const faq = ref([]);
  const page = reactive({});
  const contact = reactive({});
  const formData = reactive({});
  const inlineSearches = ref([]);
  const searches = ref([]);
  const loading = ref(false);
  const loadingButton = ref(false);
  const controller = ref(null);
  const email = ref(null);
  const isSearch = ref(false);
  const errors = ref(null);
  const card = reactive({});
  const meta = reactive({});
  const toast = useToast();
  const route = useRoute();
  const router = useRouter();
  const theme = ref(localStorage.getItem("theme") || "automatic");
  const pageTheme = ref(localStorage.getItem("page-theme"));
  const themes = ["light", "cupcake", "valentine", "winter"];

  const getFaq = async () => {
    try {
      loading.value = true;
      let response = await axios.get("faq");
      const data = response.data.data;
      faq.value = data;
      loading.value = false;
      const meta = response.data.meta;
      window.head = useHead({ title: meta.title, script: meta.schema });
      window.seo = useSeoMeta(window.setSeo(meta));
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getPage = async () => {
    try {
      loading.value = true;
      let response = await axios.get("page/" + route.params.pathMatch);
      Object.assign(page, response.data.data);
      loading.value = false;
      const meta = response.data.meta;
      window.head = useHead({ title: meta.title, script: meta.schema });
      window.seo = useSeoMeta(window.setSeo(meta));
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getContact = async () => {
    try {
      loading.value = true;
      let response = await axios.get("contact");
      const meta = response.data.meta;
      delete response.data.meta;
      Object.assign(contact, response.data);
      loading.value = false;
      window.head = useHead({ title: meta.title, script: meta.schema });
      window.seo = useSeoMeta(window.setSeo(meta));
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getPopup = async () => {
    try {
      let response = await axios.get("popup");
      const data = response.data.data;
      if (localStorage.getItem("observed_popup") !== data.name)
        setTimeout(() => (popup.value = data), data.delay_time);
    } catch (e) {}
  };

  const removePopup = () => {
    localStorage.setItem("observed_popup", popup.value.name);
    popup.value = null;
  };

  const getHome = async () => {
    try {
      loading.value = true;
      let response = await axios.get("home");
      const meta = response.data.meta;
      Object.assign(home, response.data);
      loading.value = false;
      window.head = useHead({ script: meta.schema });
      window.seo = useSeoMeta(window.setSeo(meta));
    } catch (e) {
      loading.value = false;
      errors.value = e;
    }
  };

  const getDashboard = async () => {
    try {
      loading.value = true;
      let response = await axios.get("dashboard");
      Auth.user.value.credit = response.data.credit;
      Object.assign(dashboard, response.data);
      loading.value = false;
    } catch (e) {
      loading.value = false;
      errors.value = e;
    }
  };

  const getInlineSearch = async (value) => {
    try {
      isSearch.value = true;
      controller.value = new AbortController();
      let response = await axios.get("search/" + value, {
        signal: controller.value.signal,
      });
      isSearch.value = false;
      inlineSearches.value = response.data.data;
    } catch (e) {
      isSearch.value = false;
    }
  };

  const getCard = async (isPreview) => {
    try {
      loading.value = true;
      let response = await axios.get("card/" + (isPreview ? route.params.id + '/preview?p=1' : route.params.pathMatch));
      const meta = response.data.meta;
      Object.assign(card, response.data.data);
      const head = {title: meta.title,script: meta.schema};
      contact.fields = response.data.contact;
      card.types = response.data.types;
      if(!card.meta_index)head['meta'] = [{name: `robots`,content: 'noindex, nofollow'}];
      formData.card_id = card.id;
      window.head = useHead(head);
      window.seo = useSeoMeta(window.setSeo(meta));
      loading.value = false;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getSearch = async (query) => {
    try {
      loading.value = true;
      let response = await axios.get(
        (query.type || "product") +
          "?search=" +
          query.value +
          "&page=" +
          (query.page || 0)
      );
      searches.value = response.data.data;
      Object.assign(meta, response.data.meta);
      loading.value = false;
    } catch (e) {
      loading.value = false;
    }
  };

  const subscribeNewsletter = async () => {
    try {
      let response = await axios.post("newsletter/subscribe", {
        email: email.value,
      });
      toast.success(response.data.message);
      email.value = "";
    } catch (e) {
      window.networkError(e);
    }
  };

  const sendContactMessage = async (progress = true) => {
    try {
      if(progress) progressDialog.value = true;
      else loadingButton.value = true
      let response = await axios.post("contact", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success(response.data.message);
      if(progress) progressDialog.value = false;
      else loadingButton.value = false
      contact.fields.map((field) => (formData[field.name] = ""));
      const fileElm = document.querySelector(".contact-page input[type=file]");
      if(fileElm)fileElm.value = "";
      document.getElementById('modal-contact').checked = false;
    } catch (e) {
      if(progress) progressDialog.value = false;
      else loadingButton.value = false
      window.networkError(e);
    }
  };

  const setTheme = () => {
    let html = document.documentElement;
    if (theme.value !== "automatic") {
      if (html.dataset.theme !== theme.value) html.dataset.theme = theme.value;
      localStorage.setItem("theme", theme.value);
    } else {
      if (window.matchMedia("(prefers-color-scheme: dark)").matches)
        html.dataset.theme = "dark";
      else html.dataset.theme = "light";
      localStorage.removeItem("theme");
    }
  };

  const setPageTheme = (value, apply) => {
    let html = document.documentElement;
    pageTheme.value = themes.includes(value) ? "light" : "dark";
    document.querySelector(".card-container").dataset.theme = value;
    if (apply) {
      localStorage.setItem("page-theme", pageTheme.value);
      html.dataset.theme = pageTheme.value;
    }
    return pageTheme.value;
  };

  return {
    loading,
    loadingButton,
    getPopup,
    sendContactMessage,
    email,
    setPageTheme,
    pageTheme,
    getFaq,
    meta,
    faq,
    themes,
    contact,
    formData,
    toast,
    route,
    router,
    getContact,
    removePopup,
    theme,
    getDashboard,
    setTheme,
    subscribeNewsletter,
    page,
    getPage,
    getSearch,
    popup,
    card,
    isSearch,
    getInlineSearch,
    getCard,
    inlineSearches,
    searches,
    home,
    errors,
    dashboard,
    controller,
    getHome,
  };
}
