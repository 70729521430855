<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="errors" @tryAgain="getData" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-10 pb-14 " v-else>
        <div class="container">
            <div class="flex justify-between items-center mb-6">
                <h3 class="flex items-center md:text-26 text-xl dark:text-white text-biscay-700 font-semibold">
                    <i class="md:flex hidden w-2 h-2 dark:bg-white rounded-full bg-blue-700 ml-2"></i>
                    {{ __('Industry details') }}
                </h3>
                <div class="flex justify-end order-first lg:order-last">
                        <router-link :to="{name: 'user.industry.edit',params: {id: industry.id}}" class="btn btn-outline btn-info btn-sm"
                        :class="{'btn-disabled': !can('industry.edit.all') && !can('industry.edit.self',industry.self)}">
                            {{ __('Edit') }}
                        </router-link>
                        <label @click="setItem(industry)" for="delete-modal" :class="{'btn-disabled': !can('industry.delete.all') && !can('industry.delete.self',industry.self)}"
                                        class="btn btn-outline btn-error btn-sm mr-2">
                                        {{ __('Delete') }}
                        </label>
                    </div>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm sm:px-6 sm:py-6 px-3 py-4  my-5">

                <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                    <p class="text-gray-70">{{ __('Title') }}: <span class="text-black dark:text-white">{{ industry.title }}</span></p>
                    <p class="text-gray-70">{{ __('User') }}: <span class="text-black dark:text-white">{{ industry.user }}</span></p>
                    <p class="text-gray-70">{{ __('Created At') }}: <span class="text-black dark:text-white">{{ industry.created_at }}</span></p>
                    <p class="text-gray-70">{{ __('Update date') }}: <span class="text-black dark:text-white">{{ industry.updated_at }}</span></p>
                    <div class="divider h-auto col-span-full my-0"></div>
                    <p class="text-gray-70">{{ __('Description') }}: <span class="text-black dark:text-white">{{ industry.description || '---' }}</span></p>
                </div>
            </div>

            <div class="flex justify-between items-center mt-8 mb-6">
                <h3 class="flex items-center md:text-26 text-xl dark:text-white text-biscay-700 font-semibold">
                    <i class="md:flex hidden w-2 h-2 dark:bg-white rounded-full bg-blue-700 ml-2"></i>
                    {{ __('Sub industries') }}
                </h3>
                <div class="flex justify-end order-first lg:order-last" v-if="can('industry.create')">
                        <router-link :to="{name: 'user.industry.create',query:{p_id: industry.id}}" class="btn btn-outline btn-info btn-base">
                            <span class="mt-0.5">{{ __('Add industry') }}</span>
                        </router-link>
                    </div>
            </div>
            <div class="rounded-2xl dark:bg-base-300 bg-white">
                <div class=" overflow-x-auto">
                    <table class="table" v-if="industries.length">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th> {{ __('Title') }} </th>
                                <th class="hidden xl:block"> {{ __('Description') }} </th>
                                <th> {{ __('User') }} </th>
                                <th> {{ __('Created At') }} </th>
                                <th> {{ __('Action') }} </th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in industries">
                                <td> {{ (meta.per_page * (meta.current_page - 1)) + index + 1 }} </td>
                                <td> {{ item.title }} </td>
                                <td class="hidden xl:block"> {{ item.description || '---' }} </td>
                                <td> {{ item.user || '---' }} </td>
                                <td> {{ item.created_at }}</td>
                                <td>
                                    <router-link :to="{name: 'user.industry.show',params: {id: item.id}}" :class="{'btn-disabled': !can('industry.view.all') && !can('industry.view.self',item.self)}"
                                        class="btn btn-outline btn-primary btn-sm">
                                        {{ __('Show') }}
                                    </router-link>
                                    <router-link :to="{name: 'user.industry.edit',params: {id:item.id}}" :class="{'btn-disabled': !can('industry.edit.all') && !can('industry.edit.self',item.self)}"
                                        class="btn btn-outline btn-info btn-sm mx-2">
                                        {{ __('Edit') }}
                                    </router-link>
                                    <label @click="setItem(item,false)" for="delete-modal" :class="{'btn-disabled': !can('industry.delete.all') && !can('industry.delete.self',item.self)}"
                                        class="btn btn-outline btn-error btn-sm">
                                        {{ __('Delete') }}
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="col-span-12 flex flex-col items-center mt-9" v-else>
                        <NotFound />
                    </div>
                </div>
            </div>
            <Pagination :meta="meta" @setPage="setPage" class="!mt-6" />
        </div>
    </div>
    <Teleport to="#app">
        <input type="checkbox" id="delete-modal" class="modal-toggle" />
        <label class="modal" for="delete-modal">
            <label class="modal-box" for="">
                <h3 class="font-bold text-lg">{{ __('Delete industry') }}</h3>
                <p class="py-4">{{ __('Are you sure to delete this industry?') }}</p>
                <div class="modal-action">
                    <label class="btn btn-sm" for="delete-modal"
                        :class="{ 'pointer-events-none opacity-60': loadingBtn }">{{
                            __('Cancel') }}</label>
                    <button class="btn btn-error btn-sm text-white" @click="removeIndustry"
                        :class="{ 'loading': loadingBtn }">
                        {{ __('Delete') }}
                    </button>
                </div>
            </label>
        </label>
    </Teleport>
</template>
<script setup>
import useDashboard from "../../composables/dashboard";
import { computed, onMounted, onUpdated, reactive, ref } from 'vue';
const { getIndustry,getIndustries, industries,industry, loading,remove, meta, errors,router, route,removeIndustry,loadingBtn } = useDashboard();
const setPage = (p) => {
    setQuery('page', p.toString());
};
const setQuery = (key, value) => {
    getIndustries(window.setQuery(router, route, key, value));
}
const setItem = (item,self = true) => Object.assign(remove,{id: item.id,single: self});
const getData = () => {
    getIndustry();
    getIndustries(route.query);
}
onMounted(() => {
    route.query.parent_id = route.params.id;
    getData();
});
onUpdated(() => {
    if (route.query.parent_id !== route.params.id) {
        route.query.parent_id = route.params.id;
        getData();
    }
});
</script>
  