import { ref, reactive, inject } from "vue";
import axios from "axios";
import { useRoute,useRouter } from 'vue-router'
import { useToast } from "vue-toastification";
import { useHead,useSeoMeta } from '@vueuse/head'
export default function useProducts() {
  const page = ref(1);
  const product = reactive({});
  const products = ref([]);
  const categories = ref([]);
  const latestProducts = ref([]);
  const loading = ref(false);
  const loadingDelete = ref(false);
  const controller = ref(null);
  const cart = Object.assign(window.cart,{address: {}});
  const coupon = reactive({code :null,discount: 0});
  const progressDialog = window.progressDialog;
  const meta = reactive({});
  const errors = ref("");
  const toast = useToast();
  const route = useRoute()
  const router = useRouter()

  const getProducts = async (query = '',p = 1,push = false) => {
    loading.value = true;
    try {
      errors.value = "";
      page.value = p;
      const saerch = !_.isEmpty(query) ? '?' + new URLSearchParams(query).toString() : '';
      let response = await axios.get("product" + saerch);
      Object.assign(meta, response.data.meta);
      if(push)products.value.push(...response.data.data);
      else products.value = response.data.data;
      if(response.data.categories) categories.value = response.data.categories;
      loading.value = false;
      window.head = useHead({title: meta.title,script: meta.schema})
      window.seo = useSeoMeta(window.setSeo(meta))
    } catch (e) {
      if(!meta.current_page)errors.value = e;
      loading.value = false;
    }
  };

  const getProduct = async () => {
    loading.value = true;
    try {
      let response = await axios.get("product/" + route.params.slug);
      Object.assign(product, response.data.data);
      latestProducts.value = response.data.latest_products;
      loading.value = false;
      window.head = useHead({title: meta.title,script: meta.schema})
      window.seo = useSeoMeta(window.setSeo(meta))
    } catch (e) {
      errors.value = true;
      loading.value = false;
    }
  };

  const getCities = async (name) => {
    try {
      progressDialog.value = true;
      let response = await axios.get("city/" + cart.states.find(item => item.name == name).id);
      cart.cities = response.data.data;
      progressDialog.value = false;
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const getCategories = async () => {
    try {
      progressDialog.value = true;
      let response = await axios.get("product/category");
      progressDialog.value = false;
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const getCart = async () => {
    loading.value = true;
    try {
      errors.value = "";
      let response = await axios.get("cart");
      Object.assign(cart,response.data);
      loading.value = false;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeCart = async (productId,updateId = null) => {
    try {
      progressDialog.value = true;
      let response = await axios.post("cart", {
        product_id: productId,
        update_id: updateId,
      });
      Object.assign(cart,response.data);
      progressDialog.value = false;
      toast.success(response.data.message);
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeCart = async (id) => {
    try {
      loadingDelete.value = true;
      let response = await axios.delete("cart/" + id);
      Object.assign(cart,response.data);
      loadingDelete.value = false;
      document.querySelector('input#delete-modal').checked = false;
    } catch (e) {
      window.networkError(e);
      loadingDelete.value = false;
    }
  };

  const applyCouponCode = async () => {
    try {
      if(!coupon.code.trim().length)return true;
      progressDialog.value = true;
      let response = await axios.post("coupon/apply", {
        code: coupon.code,
      });
      const data = response.data;
      coupon.discount = data.discount;
      progressDialog.value = false;
      toast.success(data.message);
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeCouponCode = () => Object.assign(coupon,{code: null,discount: 0});

  return {
    errors,
    loading,
    progressDialog,
    meta,
    page,
    latestProducts,
    applyCouponCode,
    removeCouponCode,
    route,
    router,
    coupon,
    products,
    product,
    cart,
    controller,
    categories,
    loadingDelete,
    getCategories,
    getProducts,
    getCities,
    getProduct,
    getCart,
    storeCart,
    removeCart,
    toast,
  };
}
