import { ref, reactive } from "vue";
import axios from "axios";
import router from "../router";
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";

export default function useDashboard() {
  const user = reactive({
    numbers: [{type: 'office', value: ''}],
    billing: {},
    roles : [],
    emails: [{value: ''}],
    status: 'active'
  });
  const clue = reactive({
    type: 'genuine',
    numbers: [{type: 'office', value: ''}],
    billing: {},
    emails: [{value: ''}],
    social: {},
    attachments: [],
    status: 'new'
  });
  const sendMessage = reactive({content: "",type:'test',date:'immediately',shipping_time:'',census:'',excel: [],manual: true,block_number: false,manual_number: '',numbers: [],number_count: 0,clues: [],accounts: []});
  const person = reactive({
    account_id: '',
    numbers: [{type: 'office', value: ''}],
    billing: {},
    emails: [{value: ''}],
    social: {},
    attachments: [],
    status: 'new'
  });
  const discount = reactive({
    parent_type: 'clue',
    // discount_type: 'amount',
    code: Math.random().toString(36).replace('.','').slice(1, 9),
    status: 'active'
  });
  const account = reactive({
    type: 'genuine',
    numbers: [{type: 'office', value: ''}],
    billing: {},
    account_type: '',
    is_foreigner: '0',
    emails: [{value: ''}],
    credit: {},
    social: {},
    attachments: [],
    status: 'new'
  });
  const opportunity = reactive({
    attachments: [],
  });

  const appointment = reactive({
    invitees: [],
    parent_type: 'account',
    attachments: [],
  });

  const loading = ref(false);
  const message = ref('');
  const sms = reactive({});
  const step = ref(1);
  const loadMore = ref(false);
  const loadingBtn = ref(false);
  const loadingSelect = ref(false);
  const progressDialog = window.progressDialog;
  const errors = ref("");
  const states = ref([]);
  const calendar = ref([]);
  const cities = ref([]);
  const clues = ref([]);
  const persons = ref([]);
  const discounts = ref([]);
  const accounts = ref([]);
  const permissions = ref([]);
  const leadSources = ref([]);
  const messages = ref([]);
  const saveMessages = ref([]);
  const industries = ref([]);
  const opportunities = ref([]);
  const appointments = ref([]);
  const numbers = ref([]);
  const industry = reactive({});
  const leadSource = reactive({});
  const saveMessage = reactive({content: ''});
  const remove = reactive({});
  const settings = reactive({});
  const users = ref([]);
  const roles = ref([]);
  const controller = ref(null);
  const role = reactive({permissions: []});
  const campaigns = ref([]);
  const campaign = reactive({});
  const meta = reactive({});
  const toast = useToast();
  const route = useRoute();

  const getSettings = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/settings");
      loading.value = false;
      Object.assign(settings,{...response.data.data,phone: response.data.phone})
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const updateSettings = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put("user/settings", settings);
      progressDialog.value = false;
      toast.success(response.data.message);
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const getCalendar = async (date) => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/calendar?date=" + date);
      loading.value = false;
      calendar.value = response.data.data;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getRoles = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const search = isObjectNotEmpty(query) ? "?" + new URLSearchParams(query).toString() : "";
      let response = await axios.get("user/role" + search);
      loading.value = false;
      roles.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getRole = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/role/" + route.params.id);
      loading.value = false;
      Object.assign(role,response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const addRole = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/role/create");
      loading.value = false;
      meta.permissions =  response.data.permissions;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editRole = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/role/"+route.params.id+"/edit");
      loading.value = false;
      Object.assign(role,response.data.data);
      meta.permissions =  response.data.permissions;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeRole = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/role",role);
      progressDialog.value = false;
      toast.success(response.data.message);
      router.push({name: 'user.roles'});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateRole = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put("user/role/" + route.params.id, role);
      progressDialog.value = false;
      router.push({name: 'user.roles'});
      toast.success(response.data.message);
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeRole = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/role/" + role.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      getRoles(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };


  const getUsers = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const search = isObjectNotEmpty(query) ? "?" + new URLSearchParams(query).toString() : "";
      let response = await axios.get("user/user" + search);
      loading.value = false;
      users.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getUser = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/user/" + route.params.id);
      loading.value = false;
      Object.assign(user,response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const addUser = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/user/create");
      loading.value = false;
      Object.assign(meta,{...response.data});
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editUser = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/user/"+route.params.id+"/edit");
      loading.value = false;
      Object.assign(user,response.data.data);
      Object.assign(meta,{...response.data});
      if(user.billing.state && meta.states.indexOf(item => item.name == user.billing.state) == -1) meta.states.push({name: user.billing.state});
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeUser = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/user",user);
      progressDialog.value = false;
      toast.success(response.data.message);
      router.push({name: 'user.users'});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateUser = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put("user/user/" + route.params.id, user);
      progressDialog.value = false;
      if(history.state.back) router.back();
      else router.push({name: 'user.users'});
      toast.success(response.data.message);
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeUser = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/user/" + remove.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      if(remove.single){
        if(history.state.back) router.back();
        else router.push({name: 'user.users'});
      }else getUsers(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  const getLeadSources = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const search = isObjectNotEmpty(query) ? "?" + new URLSearchParams(query).toString() : "";
      let response = await axios.get("user/lead-source" + search);
      loading.value = false;
      leadSources.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getLeadSource = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/lead-source/" + route.params.id);
      loading.value = false;
      Object.assign(leadSource,response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeLeadSource = async () => {
    try {
      errors.value = "";
      loadingBtn.value = true;
      let response = await axios.post("user/lead-source",leadSource);
      loadingBtn.value = false;
      toast.success(response.data.message);
      document.getElementById("add-edit-modal").checked = false;
      getLeadSources();
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  const updateLeadSource = async () => {
    try {
      errors.value = "";
      loadingBtn.value = true;
      let response = await axios.put("user/lead-source/" + leadSource.id, leadSource);
      const index = leadSources.value.findIndex((item) => item.id === leadSource.id);
      leadSources.value[index] = response.data.data;
      loadingBtn.value = false;
      toast.success(response.data.message);
      document.getElementById("add-edit-modal").checked = false;
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  const removeLeadSource = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/lead-source/" + leadSource.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      getLeadSources(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  const checkMessage = async () => {
    try {
      errors.value = "";
      loading.value = true;
      const type = sendMessage.type;
      const manual = type == 'test' || sendMessage.manual ? sendMessage.manual_number.split("\n").filter(value => /^[09][1-9]\d{9}$/g.test(value)) : [];
      manual.push(...sendMessage.excel);
      let response = await axios.post("user/message/check",{type, values: type == 'test' ? [] : sendMessage[type + 's'],manual,block_number: sendMessage.block_number});
      loading.value = false;
      sendMessage.numbers = response.data.data;
      sendMessage.number_count = response.data.count;
      numbers.value = response.data.lines;
      sendMessage.census = numbers.value[0];
      sendMessage.cost = response.data.cost;
      if(step.value <= 3)step.value = 3;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const importContact = async (excel) => {
    try {
      if(!excel.target.files.length) return false;
      errors.value = "";
      loadingBtn.value = true;
      const file = new FormData();
      file.append('excel',excel.target.files[0]);
      let response = await axios.post("user/message/import",file, { headers: {'Content-Type': 'multipart/form-data'}});
      sendMessage.excel = response.data.data;
      loadingBtn.value = false;
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  const storeMessage = async () => {
    try {
      errors.value = "";
      loading.value = true;
      if(sendMessage.date == 'immediately') sendMessage.shipping_time = '';
      const {content,type,census,shipping_time,manual_number,excel} = sendMessage;
      const manual = type == 'test' || sendMessage.manual ? manual_number.split("\n") : [];
      manual.push(...excel);
      let response = await axios.post("user/message",{content,type,census,shipping_time,manual,block_number: sendMessage.block_number,values: type == 'test' ? [] : sendMessage[type + 's']});
      loading.value = false;
      step.value = 5;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getMessages = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const search = isObjectNotEmpty(query) ? "?" + new URLSearchParams(query).toString() : "";
      let response = await axios.get("user/message" + search);
      loading.value = false;
      messages.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      if (!meta.current_page) errors.value = e;
      loading.value = false;
    }
  };


  const getSaveMessages = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const search = isObjectNotEmpty(query) ? "?" + new URLSearchParams(query).toString() : "";
      let response = await axios.get("user/save-message" + search);
      loading.value = false;
      saveMessages.value = response.data.data;
      Object.assign(meta, response.data.meta);
      if(query.per_page) document.getElementById('message-modal').checked = true;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeSaveMessage = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/save-message",saveMessage);
      progressDialog.value = false;
      toast.success(response.data.message);
      document.getElementById("add-edit-modal").checked = false;
      getSaveMessages();
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateSaveMessage = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put("user/save-message/" + saveMessage.id, saveMessage);
      const index = saveMessages.value.findIndex((item) => item.id === saveMessage.id);
      saveMessages.value[index] = response.data.data;
      progressDialog.value = false;
      toast.success(response.data.message);
      document.getElementById("add-edit-modal").checked = false;
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeSaveMessage = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/save-message/" + saveMessage.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      getSaveMessages(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };
  
  const getIndustries = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const search = isObjectNotEmpty(query) ? "?" + new URLSearchParams(query).toString() : "";
      let response = await axios.get("user/industry" + search);
      loading.value = false;
      industries.value = response.data.data;
      Object.assign(meta, response.data.meta);
      meta.industries = response.data.industries;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getSubIndustries = async (name) => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/industry/" + name + "/children");
      loading.value = false;
      industries.value = response.data.data;
      if(clue.subindustry && industries.value.indexOf(item => item.title == clue.subindustry) == -1) industries.value.push({title: clue.subindustry});
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getIndustry = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/industry/" + route.params.id);
      loading.value = false;
      Object.assign(industry,response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const addIndustry = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/industry/create");
      loading.value = false;
      meta.industries = response.data.industries;
      if(route.query.p_id) industry.parent_id = route.query.p_id
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editIndustry = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/industry/"+route.params.id+"/edit");
      loading.value = false;
      Object.assign(industry,response.data.data);
      meta.industries = response.data.industries;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeIndustry = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/industry",industry);
      progressDialog.value = false;
      toast.success(response.data.message);
      if(history.state.back) router.back();
      else router.push({name: 'user.industries'});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateIndustry = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put("user/industry/" + industry.id, industry);
      const index = industries.value.findIndex((item) => item.id === industry.id);
      industries.value[index] = response.data.data;
      progressDialog.value = false;
      toast.success(response.data.message);
      if(history.state.back) router.back();
      else router.push({name: 'user.industries'});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeIndustry = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/industry/" + remove.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      if(remove.single){
        if(history.state.back) router.back();
        else router.push({name: 'user.industries'});
      }else getIndustries(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  const getDiscounts = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const search = isObjectNotEmpty(query) ? "?" + new URLSearchParams(query).toString() : "";
      let response = await axios.get("user/discount" + search);
      loading.value = false;
      discounts.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editDiscount = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/discount/"+route.params.id+"/edit");
      loading.value = false;
      Object.assign(discount,response.data.data);
      meta.options = [discount.user];
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeDiscount = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/discount",discount);
      progressDialog.value = false;
      toast.success(response.data.message);
      if(history.state.back) router.back();
      else router.push({name: 'user.discounts'});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateDiscount = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put("user/discount/" + discount.id, discount);
      const index = industries.value.findIndex((item) => item.id === discount.id);
      industries.value[index] = response.data.data;
      progressDialog.value = false;
      toast.success(response.data.message);
      if(history.state.back) router.back();
      else router.push({name: 'user.discounts'});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeDiscount = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/discount/" + remove.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      if(remove.single){
        if(history.state.back) router.back();
        else router.push({name: 'user.discounts'});
      }else getDiscounts(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  const getCampaigns = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const search = isObjectNotEmpty(query) ? "?" + new URLSearchParams(query).toString() : "";
      let response = await axios.get("user/campaign" + search);
      loading.value = false;
      campaigns.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getCampaign = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/campaign/" + route.params.id);
      loading.value = false;
      Object.assign(campaign,response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const addCampaign = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/campaign/create");
      loading.value = false;
      meta.campaigns =  response.data.campaigns;
      if(route.query.p_id) campaign.parent_id = route.query.p_id
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editCampaign = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/campaign/"+route.params.id+"/edit");
      loading.value = false;
      Object.assign(campaign,response.data.data);
      meta.campaigns = response.data.campaigns;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeCampaign = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/campaign",campaign);
      progressDialog.value = false;
      toast.success(response.data.message);
      if(history.state.back) router.back();
      else router.push({name: 'user.campaigns'});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateCampaign = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put("user/campaign/" + campaign.id, campaign);
      progressDialog.value = false;
      toast.success(response.data.message);
      if(history.state.back) router.back();
      else router.push({name: 'user.campaigns'});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeCampaign = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/campaign/" + remove.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      if(remove.single){
        if(history.state.back) router.back();
        else router.push({name: 'user.campaigns'});
      }else getCampaigns(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  const getStates = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("city");
      loading.value = false;
      states.value = response.data.data;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getCities = async (state) => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("city/" + state);
      loading.value = false;
      cities.value = response.data.data;
      if(user.billing.city && cities.value.indexOf(item => item.name == user.billing.city) == -1) cities.value.push({name: user.billing.city});
      else if(clue.billing.city && cities.value.indexOf(item => item.name == clue.billing.city) == -1) cities.value.push({name: clue.billing.city});
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };


  const getClues = async (query = {}) => {
    try {
      errors.value = "";
      if(query.inline)loadingBtn.value = true;
      else loading.value = true;
      const saerch = isObjectNotEmpty(query) ? "?" + new URLSearchParams(query).toString() : "";
      let response = await axios.get("user/clue" + saerch);
      if(query.inline)loadingBtn.value = false;
      else loading.value = false;
      clues.value = response.data.data;
      Object.assign(meta, response.data.meta);
      Object.assign(sendMessage,{type: 'clue',manual: false});
    } catch (e) {
      errors.value = e;
      if(query.inline)loadingBtn.value = false;
      else loading.value = false;
    }
  };

  const getValues = async (type,query = {}) => {
    try {
      errors.value = "";
      loadingBtn.value = true;
      const saerch = isObjectNotEmpty(query) ? "?" + new URLSearchParams(query).toString() : "";
      let response = await axios.get("user/" + type + saerch);
      loadingBtn.value = false;
      meta.options = response.data.data;
    } catch (e) {
      errors.value = e;
      loadingBtn.value = false;
    }
  };

  const getClue = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/clue/" + route.params.id);
      loading.value = false;
      Object.assign(clue, response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const addClue = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/clue/create");
      loading.value = false;
      Object.assign(meta,{...response.data});
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editClue = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/clue/"+route.params.id+"/edit");
      loading.value = false;
      Object.assign(clue,response.data.data);
      Object.assign(meta,{...response.data});
      if(clue.industry && meta.industries.indexOf(item => item.title == clue.industry) == -1) meta.industries.push({title: clue.industry});
      if(clue.billing.state && meta.states.indexOf(item => item.name == clue.billing.state) == -1) meta.states.push({name: clue.billing.state});
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeClue = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/clue",clue);
      progressDialog.value = false;
      toast.success(response.data.message);
      router.push({name: 'user.clues'});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateClue = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put("user/clue/" + route.params.id, clue);
      progressDialog.value = false;
      toast.success(response.data.message);
      if(history.state.back) router.back();
      else router.push({name: 'user.clues'});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const converterClue = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/clue/"+route.params.id+"/converter");
      loading.value = false;
      Object.assign(clue,response.data.data);
      Object.assign(meta,{...response.data});
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };


  const convertClue = async (data) => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/clue/" + route.params.id + "/convert", data);
      progressDialog.value = false;
      toast.success(response.data.message);
      if(history.state.back) router.back();
      else router.push({name: 'user.clue.show',params: {id: route.params.id}});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeClue = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/clue/" + remove.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      if(remove.single){
        if(history.state.back) router.back();
        else router.push({name: 'user.clues'});
      }else getClues(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  const getPersons = async (query = {}) => {
    try {
      errors.value = "";
      if(query.inline)loadingBtn.value = true;
      else loading.value = true;
      const saerch = isObjectNotEmpty(query) ? "?" + new URLSearchParams(query).toString() : "";
      let response = await axios.get("user/person" + saerch);
      if(query.inline)loadingBtn.value = false;
      else loading.value = false;
      persons.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e;
      if(query.inline)loadingBtn.value = false;
      else loading.value = false;
    }
  };

  const getPerson = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/person/" + route.params.id);
      loading.value = false;
      Object.assign(person, response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const addPerson = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/person/create");
      loading.value = false;
      Object.assign(meta,{...response.data});
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editPerson = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/person/"+route.params.id+"/edit");
      loading.value = false;
      Object.assign(person,response.data.data);
      Object.assign(meta,{...response.data});
      if(clue.industry && meta.industries.indexOf(item => item.title == clue.industry) == -1) meta.industries.push({title: clue.industry});
      if(clue.billing.state && meta.states.indexOf(item => item.name == clue.billing.state) == -1) meta.states.push({name: clue.billing.state});
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storePerson = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/person",person);
      progressDialog.value = false;
      toast.success(response.data.message);
      router.push({name: 'user.persons'});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updatePerson = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put("user/person/" + route.params.id, person);
      progressDialog.value = false;
      toast.success(response.data.message);
      if(history.state.back) router.back();
      else router.push({name: 'user.persons'});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removePerson = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/person/" + remove.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      if(remove.single){
        if(history.state.back) router.back();
        else router.push({name: 'user.persons'});
      }else getPersons(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  const getAccounts = async (query = {}) => {
    try {
      errors.value = "";
      if(query.inline)loadingBtn.value = true;
      else loading.value = true;
      const saerch = isObjectNotEmpty(query) ? "?" + new URLSearchParams(query).toString() : "";
      let response = await axios.get("user/account" + saerch);
      if(query.inline)loadingBtn.value = false;
      else loading.value = false;
      accounts.value = response.data.data;
      Object.assign(meta, response.data.meta);
      Object.assign(sendMessage,{type: 'account',manual: false});
    } catch (e) {
      errors.value = e;
      if(query.inline)loadingBtn.value = false;
      else loading.value = false;
    }
  };

  const getAccount = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/account/" + route.params.id);
      loading.value = false;
      Object.assign(account, response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const addAccount = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/account/create");
      loading.value = false;
      Object.assign(meta,{...response.data});
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editAccount = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/account/"+route.params.id+"/edit");
      loading.value = false;
      Object.assign(account,response.data.data);
      Object.assign(meta,{...response.data});
      if(account.industry && meta.industries.indexOf(item => item.title == account.industry) == -1) meta.industries.push({title: account.industry});
      if(account.billing.state && meta.states.indexOf(item => item.name == account.billing.state) == -1) meta.states.push({name: account.billing.state});
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeAccount = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/account",account);
      progressDialog.value = false;
      toast.success(response.data.message);
      router.push({name: 'user.accounts'});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateAccount = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put("user/account/" + route.params.id, account);
      progressDialog.value = false;
      toast.success(response.data.message);
      if(history.state.back) router.back();
      else router.push({name: 'user.accounts'});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeAccount = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/account/" + remove.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      if(remove.single){
        if(history.state.back) router.back();
        else router.push({name: 'user.accounts'});
      }else getAccounts(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  const getOpportunities = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const saerch = isObjectNotEmpty(query) ? "?" + new URLSearchParams(query).toString() : "";
      let response = await axios.get("user/opportunity" + saerch);
      loading.value = false;
      opportunities.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getOpportunity = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/opportunity/" + route.params.id);
      loading.value = false;
      Object.assign(opportunity, response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const addOpportunity = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/opportunity/create");
      loading.value = false;
      Object.assign(meta,{...response.data});
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editOpportunity = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/opportunity/"+route.params.id+"/edit");
      loading.value = false;
      Object.assign(opportunity,response.data.data);
      Object.assign(meta,{...response.data});
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeOpportunity = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/opportunity",opportunity);
      progressDialog.value = false;
      toast.success(response.data.message);
      router.push({name: 'user.opportunities'});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateOpportunity = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put("user/opportunity/" + route.params.id, opportunity);
      progressDialog.value = false;
      toast.success(response.data.message);
      if(history.state.back) router.back();
      else router.push({name: 'user.opportunities'});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeOpportunity = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/opportunity/" + remove.id);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      if(remove.single){
        if(history.state.back) router.back();
        else router.push({name: 'user.opportunities'});
      }else getOpportunities(route.query);
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  const getAppointments = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      query.type = route.path.split("/").pop();
      const saerch = isObjectNotEmpty(query) ? "?" + new URLSearchParams(query).toString() : "";
      let response = await axios.get("user/appointment" + saerch);
      loading.value = false;
      appointments.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getAppointmentInvites = async (query = {}) => {
    try {
      errors.value = "";
      loading.value = true;
      const saerch = isObjectNotEmpty(query) ? "?" + new URLSearchParams(query).toString() : "";
      let response = await axios.get("user/appointment/invite/users" + saerch);
      loading.value = false;
      users.value = response.data.data;
      Object.assign(meta, response.data.meta);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const getAppointment = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/appointment/" + route.params.id);
      loading.value = false;
      Object.assign(appointment, response.data.data);
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const addAppointment = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/appointment/create");
      loading.value = false;
      Object.assign(meta,{...response.data});
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const editAppointment = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/appointment/"+route.params.id+"/edit");
      loading.value = false;
      Object.assign(appointment,response.data.data);
      Object.assign(meta,{...response.data});
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const storeAppointment = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.post("user/appointment",appointment);
      progressDialog.value = false;
      toast.success(response.data.message);
      router.push({name: 'user.' + appointment.type + 's'});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const updateAppointment = async () => {
    try {
      errors.value = "";
      progressDialog.value = true;
      let response = await axios.put("user/appointment/" + route.params.id, appointment);
      progressDialog.value = false;
      toast.success(response.data.message);
      if(history.state.back) router.back();
      else router.push({name: 'user.' + appointment.type + 's'});
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const removeAppointment = async () => {
    try {
      loadingBtn.value = true;
      let response = await axios.delete("user/appointment/" + remove.id + '?type=' + remove.type);
      toast.success(response.data.message);
      loadingBtn.value = false;
      document.getElementById("delete-modal").checked = false;
      if(remove.single){
        if(history.state.back) router.back();
        else router.push({name: 'user.' + appointment.type + 's'});
      }else getAppointments(Object.assign(route.query,{type: appointment.type}));
    } catch (e) {
      window.networkError(e);
      loadingBtn.value = false;
    }
  };

  const uploadFile = async (event,type,item) => {
    try {
      if(!event.target.files.length) return true;
      controller.value = new AbortController();
      const form = new FormData();
      form.append("file", event.target.files[0]);
      form.append("type", type);
      const response = await axios.post("user/file", form, {
        signal: controller.value.signal,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: function(progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          item.status = percentCompleted;
        }
      });
      Object.assign(item,response.data.media)
      toast.success(response.data.message);
    } catch (error) {
      window.networkError(error);
    }
  };

  const uploadImage = async (event,type,item) => {
    try {
      if(!event.target.files.length) return true;
      window.progressDialog.value = true;
      const file = new FormData();
      file.append("image", event.target.files[0]);
      file.append('type',type)
      const response = await axios.post("user/image", file, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      item[type] = response.data.image;
      window.progressDialog.value = false;
      toast.success(response.data.message);
    } catch (error) {
      window.networkError(error);
      window.progressDialog.value = false;
    }
  };

  const removeImage = async (type = 'user') => {
    try {
      window.progressDialog.value = true;
      const file = new FormData();
      file.append("card_id", card.id);
      file.append("type", type);
      const response = await axios.post("user/upload/image/delete", file);
      window.progressDialog.value = false;
      toast.success(response.data.message);
    } catch (error) {
      window.networkError(error);
      window.progressDialog.value = false;
    }
  };

  const removeFile = async (files,index) => {
      const file = files[index];
      if(!file.id)controller.value?.abort();
      files.splice(index,1);
  };

  const checkNumber = async (model = 'user',event,id = null) => {
    try {
      const value = event.target.value;
      if(!value || value.length != 11){
        message.value = '';
        return;
      }
      let response = await axios.post('user/' + model + "/number",{value,id});
      message.value = response.data?.message;
    } catch (e) {
      
    }
  };

  return {
    errors,
    meta,
    step,
    route,
    router,
    loading,
    loadMore,
    loadingBtn,
    calendar,
    cities,
    clues,
    persons,
    accounts,
    account,
    appointments,
    appointment,
    states,
    industries,
    industry,
    leadSources,
    leadSource,
    campaigns,
    message,
    campaign,
    users,
    user,
    opportunities,
    opportunity,
    discounts,
    discount,
    sendMessage,
    messages,
    saveMessages,
    saveMessage,
    roles,
    role,
    sms,
    numbers,
    remove,
    settings,
    permissions,
    loadingSelect,
    getValues,
    getSettings,
    updateSettings,
    importContact,
    checkMessage,
    getCalendar,
    getDiscounts,
    editDiscount,
    storeDiscount,
    updateDiscount,
    removeDiscount,
    storeUser,
    updateUser,
    removeUser,
    getClue,
    addClue,
    editClue,
    updateClue,
    converterClue,
    convertClue,
    storeClue,
    getClues,
    removeClue,
    getPerson,
    addPerson,
    editPerson,
    updatePerson,
    removePerson,
    storePerson,
    getPersons,
    removeClue,
    getAccount,
    addAccount,
    editAccount,
    updateAccount,
    storeAccount,
    getAccounts,
    removeAccount,
    getOpportunity,
    addOpportunity,
    editOpportunity,
    updateOpportunity,
    storeOpportunity,
    getOpportunities,
    removeOpportunity,
    getAppointment,
    addAppointment,
    editAppointment,
    updateAppointment,
    storeAppointment,
    getAppointments,
    removeAppointment,
    getAppointmentInvites,
    getUser,
    addUser,
    editUser,
    getUsers,
    storeRole,
    updateRole,
    removeRole,
    getRole,
    addRole,
    editRole,
    getRoles,
    checkNumber,
    getStates,
    getIndustries,
    getIndustry,
    getSubIndustries,
    addIndustry,
    editIndustry,
    storeIndustry,
    updateIndustry,
    removeIndustry,
    getLeadSources,
    getLeadSource,
    storeLeadSource,
    updateLeadSource,
    removeLeadSource,
    getSaveMessages,
    storeSaveMessage,
    updateSaveMessage,
    removeSaveMessage,
    storeMessage,
    getMessages,
    getCampaigns,
    getCampaign,
    addCampaign,
    editCampaign,
    storeCampaign,
    updateCampaign,
    removeCampaign,
    getCities,
    uploadImage,
    uploadFile,
    removeImage,
    removeFile,
    clue,
    person,
    toast,
  };
}
