<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!clue.first_name && errors" @tryAgain="getData" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <div class="flex justify-between items-center mb-6">
                <h3 class="flex items-center md:text-26 text-2xl dark:text-white text-biscay-700 font-semibold">
                    <i class="md:flex hidden w-2 h-2 dark:bg-white rounded-full bg-blue-700 ml-2"></i>
                    {{ __('Clue details') }}
                </h3>
                <div class="flex justify-end order-first lg:order-last">
                    <details class="dropdown">
                        <summary class="btn btn-outline btn-info btn-sm">{{ __('Action') }}</summary>
                        <ul class="menu dropdown-content bg-base-100 rounded-box z-[2] w-36 p-2 shadow">
                            <li>
                                <router-link :to="{ name: 'user.clue.edit', params: { id: clue.id } }"
                                    :class="{ 'btn-disabled': !can('clue.edit.all') && !can('clue.edit.self', clue.self) }">
                                    {{ __('Edit') }}
                                </router-link>
                            </li>
                            <li> <router-link :to="{ name: 'user.clue.convert', params: { id: clue.id } }"
                                    :class="{ 'btn-disabled': !can('clue.edit.all') && !can('clue.edit.self', clue.self) }">
                                    {{ __('Lead conversion') }}
                                </router-link></li>
                        </ul>
                    </details>

                    <label @click="setItem(clue)" for="delete-modal"
                        :class="{ 'btn-disabled': !can('clue.delete.all') && !can('clue.delete.self', clue.self) }"
                        class="btn btn-outline btn-error btn-sm mr-2">
                        {{ __('Delete') }}
                    </label>
                </div>
            </div>
            <div class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm p-4 mb-4" v-if="clue.person || clue.account || clue.opportunity || clue.appointment">
            <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                        <p class="text-gray-70" v-if="clue.person">{{ __('Person created') }}: <router-link :to="{ name: 'user.person.show', params: { id: clue.person.id } }" class="text-blue-500 hover:text-blue-600">{{
                            clue.person.fullname
                                }}</router-link></p>
                                 <p class="text-gray-70" v-if="clue.account">{{ __('Account created') }}: <router-link :to="{name: 'user.account.show',params:{id:clue.account.id}}" class="text-blue-500 hover:text-blue-600">{{
                          clue.account.name
                                }}</router-link></p>
                                 <p class="text-gray-70" v-if="clue.opportunity">{{ __('Opportunity created') }}: <router-link :to="{name: 'user.opportunity.show',params:{id:clue.opportunity.id}}" class="text-blue-500 hover:text-blue-600">{{
                           clue.opportunity.name
                                }}</router-link></p>
                                            <p class="text-gray-70" v-if="clue.appointment">{{ __('Appointment created') }}: <router-link :to="{name: 'user.'+clue.appointment.type+'.show',params:{id:clue.appointment.id}}" class="text-blue-500 hover:text-blue-600">{{
                           clue.appointment.subject
                                }}</router-link></p>
                    </div>
                    </div>
            <div class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse-plus collapse">
                <input type="checkbox" checked />
                <div class="collapse-title text-xl font-medium">
                    {{ __('personal information') }}
                </div>
                <div class="collapse-content">
                    <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                        <p class="text-gray-70">{{ __('Nickname') }}: <span class="text-black dark:text-white">{{
                            __(clue.salutation?.capitalize())
                                }}</span></p>
                        <p class="text-gray-70">{{ __('First name') }}: <span class="text-black dark:text-white">{{
                            clue.first_name
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Last name') }}: <span class="text-black dark:text-white">{{
                            clue.last_name
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Type') }}: <span class="text-black dark:text-white">{{
                            __(clue.type?.capitalize())
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Account name') }}: <span class="text-black dark:text-white">{{
                            clue.account_name || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Lead source') }}: <span class="text-black dark:text-white">{{
                            clue.lead_source || '---' }}</span></p>
                        <p class="text-gray-70" v-if="clue.emails.filter(item => item.value).length">{{ __('Email') }}:
                            <span class="text-black dark:text-white"
                                v-for="item in clue.emails.filter(item => item.value)">{{
                                    item.value
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Website') }}: <span class="text-black dark:text-white">{{
                            clue.website || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('National number') }}: <span class="text-black dark:text-white">{{
                            clue.national_number || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Side') }}: <span class="text-black dark:text-white">{{
                            clue.title || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Status') }}: <span class="text-black dark:text-white">{{
                            __(clue.status?.replace('_', ' ')?.capitalize()) || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('User') }}: <span class="text-black dark:text-white">{{
                            clue.user }}</span></p>
                        <p class="text-gray-70">{{ __('Created At') }}: <span class="text-black dark:text-white">{{
                            clue.created_at }}</span></p>
                        <p class="text-gray-70">{{ __('Update date') }}: <span class="text-black dark:text-white">{{
                            clue.updated_at }}</span></p>
                        <div class="divider h-auto col-span-full my-0"
                            v-if="clue.numbers.filter(item => item.value).length"></div>
                        <p class="text-gray-70" v-for="item in clue.numbers.filter(item => item.value)">{{
                            __(item.type.capitalize()) }}: <span class="text-black dark:text-white">{{ item.value
                                }}</span></p>
                        <div class="divider h-auto col-span-full my-0"></div>
                        <p class="text-gray-70 col-span-full">{{ __('Description') }}: <span class="text-black dark:text-white">{{
                            clue.description || '---' }}</span></p>
                    </div>
                </div>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('More information') }}
                </div>
                <div class="collapse-content">
                    <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                        <p class="text-gray-70">{{ __('The level of attractiveness') }}: <span
                                class="text-black dark:text-white">{{
                                    __(clue.rating?.capitalize()) || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Title') }}: <span class="text-black dark:text-white">{{
                            clue.title || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Campaign') }}: <span class="text-black dark:text-white">{{
                            clue.campaign_name || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Industry') }}: <span class="text-black dark:text-white">{{
                            clue.industry || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Sub industry') }}: <span class="text-black dark:text-white">{{
                            clue.subindustry || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Referred by') }}: <span class="text-black dark:text-white">{{
                            clue.refered_by || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Date of birth') }}: <span class="text-black dark:text-white">{{
                            clue.birthdate || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Economic code') }}: <span class="text-black dark:text-white">{{
                            clue.tax_id || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Registration number') }}: <span
                                class="text-black dark:text-white">{{
                                    clue.registration_number || '---' }}</span></p>
                                      <div class="divider h-auto col-span-full my-0" v-if="clue.message"></div>
                        <p class="text-gray-70 col-span-full" v-if="clue.message">{{ __('Ready message') }}: <span class="text-black dark:text-white">{{
                            clue.message || '---' }}</span></p>
                    </div>
                </div>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('Address') }}
                </div>
                <div class="collapse-content">
                    <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                        <p class="text-gray-70">{{ __('Country') }}: <span class="text-black dark:text-white">{{
                            clue.billing.country || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('State') }}: <span class="text-black dark:text-white">{{
                            clue.billing.state || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('City') }}: <span class="text-black dark:text-white">{{
                            clue.billing.city || '---'
                                }}</span></p>
                        <p class="text-gray-70">{{ __('Postal Code') }}: <span class="text-black dark:text-white">{{
                            clue.billing.postalcode || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Address') }}: <span class="text-black dark:text-white">{{
                            clue.billing.street || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Latitude') }}: <span class="text-black dark:text-white">{{
                            clue.billing.latitude || '---' }}</span></p>
                        <p class="text-gray-70">{{ __('Longitude') }}: <span class="text-black dark:text-white">{{
                            clue.billing.longitude || '---' }}</span></p>
                    </div>
                </div>
            </div>
            <div v-if="clue.social.length"
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('Social') }}
                </div>
                <div class="collapse-content">
                    <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                        <p class="text-gray-70" v-for="(item, key) in clue.social">{{ __(key?.capitalize()) }}: <span
                                class="text-black dark:text-white">{{
                                    item || '---'
                                }}</span></p>
                    </div>
                </div>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm collapse collapse-plus mt-4">
                <input type="checkbox" />
                <div class="collapse-title text-xl font-medium">
                    {{ __('Appendices') }}
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table table-xs md:table-sm xl:table-md">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{{ __('File title') }}</th>
                                    <th>{{ __('File type') }}</th>
                                    <th>{{ __('Status') }}</th>
                                    <th>{{ __('Action') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in clue.attachments">
                                    <th>{{ index + 1 }}</th>
                                    <th>{{ item.name }}</th>
                                    <td>{{ item.mime_type }}</td>
                                    <td>
                                        {{ __(item.url ? 'Uploaded' : 'Uploading') }}
                                    </td>
                                    <td>
                                        <a :href="item.url" target="_blank" class="btn btn-outline btn-info btn-sm">
                                            {{ __('Show') }}
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="text-center" v-if="!clue.attachments.length">
                                <tr>
                                    <td colspan="5">{{ __('No file found to display') }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Teleport to="#app">
        <input type="checkbox" id="delete-modal" class="modal-toggle" />
        <label class="modal" for="delete-modal">
            <label class="modal-box" for="">
                <h3 class="font-bold text-lg">{{ __('Delete clue') }}</h3>
                <p class="py-4">{{ __('Are you sure to delete this clue?') }}</p>
                <div class="modal-action">
                    <label class="btn btn-sm" for="delete-modal"
                        :class="{ 'pointer-events-none opacity-60': loadingBtn }">{{
                            __('Cancel') }}</label>
                    <button class="btn btn-error btn-sm text-white" @click="removeClue"
                        :class="{ 'loading': loadingBtn }">
                        {{ __('Delete') }}
                    </button>
                </div>
            </label>
        </label>
    </Teleport>
</template>
<script setup>
import useDashboard from "../../composables/dashboard";
import { computed, onMounted, reactive, ref } from 'vue';
const { getClue, clue, route, loading, meta, errors, remove, removeClue, loadingBtn } = useDashboard();
const setItem = (item) => Object.assign(remove, { id: item.id, single: true });
const getData = () => {
    getClue();
}
onMounted(() => {
    getData();
});
</script>