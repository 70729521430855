/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Auth from "./composables/auth";
import Toast from "vue-toastification";
import './bootstrap';
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import {createApp} from "vue";
import router from './router'
import { defineRule,configure } from 'vee-validate';
import { required, email, min,max,digits } from '@vee-validate/rules';
import { createHead } from "@vueuse/head"
import vSelect from "vue-select";
import LoadingAlert from './components/layout/LoadingAlert.vue';
import Loading from 'vue-loading-overlay';
import Navbar from './components/layout/Navbar.vue';
import Footer from './components/layout/Footer.vue';
import Pagination from './components/layout/Pagination.vue';
import Progressbar from './components/layout/Progressbar.vue';
import NotFound from './components/layout/NotFound.vue';
import LoadMore from './components/layout/LoadMore.vue';
import NetworkError from './components/layout/NetworkError.vue';
import FloatingVue from 'floating-vue'
import {localize} from '@vee-validate/i18n';
defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('max', max);
defineRule('digits', digits);
import "vue-toastification/dist/index.css";
import "leaflet/dist/leaflet.css";
import 'floating-vue/dist/style.css'
import "vue-select/dist/vue-select.css";
import fa from '@vee-validate/i18n/dist/locale/fa.json';
localize({fa});
configure({
    generateMessage: localize('fa',{
        names: {
            time: window.i18n['Time'],
            email: window.i18n['Email'],
            name: window.i18n['Name'],
            status: window.i18n['Status'],
            username: window.i18n['Username'],
            password: window.i18n['Password'],
        }
    }),
});

// if ('serviceWorker' in navigator) {
//     window.deferredPrompt = null;
//     navigator.serviceWorker.register(window.APP_URL + '/serviceworker.js', {
//         scope: '/app'
//     }).then(registration => {
//         window.addEventListener('beforeinstallprompt',(event) => {
//             event.preventDefault();
//             if (/Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
//                 window.deferredPrompt = event;
//             }
//             return false;
//         });
//     }, err => {
//         console.log('Laravel PWA: ServiceWorker registration failed: ', err);
//     });
// }

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

import App from './components/layout/App.vue'

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
const options = {
    position: "bottom-right",
    timeout: 4000,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: true,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    toastClassName: '!rounded-[8px]',
    rtl: true,
    maxToasts: 3,
};
const head = createHead()
const app = createApp(App);

app.config.globalProperties.__ = str => _.get(window.i18n, str);
app.config.globalProperties.auth = Auth;
app.config.globalProperties.can = function(value,bool = true){
    let permissions = Auth.user.value.permissions;
    let _return = false
    if(!Array.isArray(permissions)){
        return false
    }
    if(value.includes('|')){
        value.split('|').forEach(function (item) {
            if(permissions.includes(item.trim())){
                _return = true
            }
        })
    }else if(value.includes('&')){
        _return = true
        value.split('&').forEach(function (item) {
            if(!permissions.includes(item.trim())){
                _return = false
            }
        })
    }else if(bool){
        _return = permissions.includes(value.trim())
    }
    return _return
}
const config = {
    themes: {
      tooltip: {
        placement: 'right',
        triggers: ['hover', 'focus','touch'],
        popperTriggers: ['hover', 'focus'],
        html: true,
      },
    },
  }
app.component('v-select', vSelect)
app.component('Loading',Loading);
app.component('Progressbar',Progressbar);
app.component('LoadingAlert',LoadingAlert);
app.component('NotFound',NotFound);
app.component('NetworkError',NetworkError);
app.component('LoadMore',LoadMore);
app.component('Navbar',Navbar);
app.component('Pagination',Pagination);
app.component('Footer',Footer);
app.use(router).use(head).use(FloatingVue,config).use(LoadingPlugin,{
    'background-color': 'rgb(51,65,85)',
    color: 'rgb(255,45,32)',
    'lock-scroll': true,
    opacity: .6
}).use(Toast, options).mount('#app');