import { ref, reactive } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import Auth from "../composables/auth";
import { useToast } from "vue-toastification";
export default function useProfile() {
  const paymentLogs = ref([]);
  const sessions = ref([]);
  const comments = ref([]);
  const header = ref([]);
  const loadingButton = ref(false);
  const product = reactive({});
  const orders = ref([]);
  const order = reactive({});
  const loading = ref(true);
  const meta = reactive({});
  const progressDialog = window.progressDialog;
  const payment = reactive({ success: 0, error: 0});
  const notifications = ref([]);
  const errors = ref("");
  const route = useRoute();
  const router = useRouter();
  const toast = useToast();

  const getPaymentLogs = async (query) => {
    try {
      errors.value = "";
      loading.value = true;
      const saerch = !_.isEmpty(query)
        ? "?" + new URLSearchParams(query).toString()
        : "";
      let response = await axios.get("user/payment-log" + saerch);
      loading.value = false;
      paymentLogs.value = response.data.data;
      Object.assign(meta, response.data.meta);
        const { payment_success, payment_error,credit } = response.data;
        Object.assign(payment, {
          credit,
          success: payment_success,
          error: payment_error,
        });
    } catch (e) {
      if (!meta.current_page) errors.value = e;
      loading.value = false;
    }
  };

  const getOrders = async (query) => {
    try {
      errors.value = "";
      loading.value = true;
      const saerch = !_.isEmpty(query)
        ? "?" + new URLSearchParams(query).toString()
        : "";
      let response = await axios.get("user/order" + saerch);
      const data = response.data;
      loading.value = false;
      orders.value = data.data;
      Object.assign(meta,data.meta);
      if (data.order_count)Object.assign(meta,{order_count: data.order_count,order_success: data.order_success,order_cancel: data.order_cancel});
    } catch (e) {
      if (!meta.current_page) errors.value = e;
      loading.value = false;
    }
  };

  const getOrder = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/order/" + route.params.id);
      loading.value = false;
      const {order_count,order_success,order_cancel} = response.data;
      Object.assign(order, response.data.data);
      Object.assign(meta,{order_count,order_success,order_cancel});
    } catch (e) {
      loading.value = false;
    }
  };

  const getNotifications = async (query = "") => {
    try {
      errors.value = "";
      loading.value = true;
      const saerch = !_.isEmpty(query)
        ? "?" + new URLSearchParams(query).toString()
        : "";
      let response = await axios.get("notification" + saerch);
      loading.value = false;
      notifications.value = response.data.data;
      Object.assign(meta, response.data.meta);
      if (!query.filter) mark();
    } catch (e) {
      if (!meta.current_page) errors.value = e;
      loading.value = false;
    }
  };

  const mark = async () => {
    try {
      let response = await axios.put("notification/mark");
      Auth.user.value.notification_count = response.data.count;
    } catch (e) {}
  };

  const getSessions = async () => {
    try {
      errors.value = "";
      loading.value = true;
      let response = await axios.get("user/session");
      loading.value = false;
      sessions.value = response.data.data;
    } catch (e) {
      errors.value = e;
      loading.value = false;
    }
  };

  const removeSession = async (id) => {
    try {
      progressDialog.value = true;
      let response = await axios.delete("user/session/" + id);
      toast.success(response.data.message);
      const index = sessions.value.findIndex((item) => item.id === id);
      sessions.value.splice(index, 1);
      progressDialog.value = false;
    } catch (e) {
      window.networkError(e);
      progressDialog.value = false;
    }
  };

  const getComments = async (query) => {
    try {
      errors.value = "";
      loading.value = true;
      const saerch = query ? "?" + new URLSearchParams(query).toString() : "";
      let response = await axios.get("user/comment" + saerch);
      Object.assign(meta, response.data.meta);
      comments.value = response.data.data;
      loading.value = false;
    } catch (e) {
      if (!meta.current_page) errors.value = e;
      loading.value = false;
    }
  };

  const increaseCredit = async (cash) => {
    try {
      if(!cash.length) return false;
      errors.value = "";
      loadingButton.value = true;
      let response = await axios.post("user/credit", { cash });
      const link = document.createElement("a");
      link.href = response.data.payment_link;
      link.click();
    } catch (e) {
      window.networkError(e);
      loadingButton.value = false;
    }
  };


  return {
    errors,
    loading,
    paymentLogs,
    sessions,
    orders,
    order,
    product,
    getSessions,
    getOrders,
    getOrder,
    removeSession,
    header,
    increaseCredit,
    comments,
    getComments,
    loadingButton,
    notifications,
    getNotifications,
    getComments,
    payment,
    getPaymentLogs,
    route,
    router,
    meta,
  };
}
