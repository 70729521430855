<template>
  <Progressbar v-if="loading" />
  <NetworkError v-else-if="!meta.states && errors" @tryAgain="getData()" />
  <main class="flex min-h-screen w-full items-center justify-center flex-col sm:px-0 px-3  pb-16 pt-20" v-else>
    <a href="/" class="mb-6">
      <img :src="logo" class="flex" alt="logo">
    </a>
    <!-- <router-link :to="{name:'home'}" class="mb-10">
      <img :src="logo" class="flex" alt="logo">
    </router-link> -->
    <section
      class="bg-white dark:bg-base-300 dark:shadow-whiteShadow py-4 px-5 rounded-2xl shadow-sm sm:w-[640px] w-full">

      <div class="text-center">
        <h2 class="sm:text-26 text-22 text-dark-550 dark:text-white font-semibold mb-1">{{ __('Register in your number')
          }}</h2>
        <h6 class="sm:text-15 text-13 text-gray-300 dark:text-gray-210 font-medium">
          {{ __('Welcome to your number! To join, complete the form below') }}
        </h6>
      </div>
      <div>
        <Form @submit="onSubmit" class="p-4 h-full flex flex-col" v-slot="{ errors }">
          <div class="flex-1">
            <div class="form-control mt-3">
              <div class="grid md:grid-cols-2 gap-3">
                <div>
                  <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-mediuminput" for="">
                    {{ __('Full Name') }} <span class="text-error">*</span>
                  </label>
                  <Field type="text" name="name" v-model="user.name" :validateOnBlur="false" :validateOnInput="true"
                    rules="required" :class="{ 'input-error': errors.name }" class="input" />
                </div>
                <div class="tooltip text-start" :data-tip="__('A confirmation code will be sent to this number')">
                  <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-mediuminput" for="">
                    {{ __('phone number') }} <span class="text-error">*</span>
                  </label>
                  <Field type="number" dir="ltr" name="phone" v-model="user.phone" :validateOnBlur="false"
                    :validateOnInput="true" rules="required" :class="{ 'input-error': errors.phone }" class="input" />
                </div>

                <div>
                  <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-mediuminput" for="">
                    {{ __('Company Name') }} <span class="text-error">*</span>
                  </label>
                  <Field type="text" name="company_name" v-model="user.company_name" :validateOnBlur="false"
                    :validateOnInput="true" rules="required" :class="{ 'input-error': errors.company_name }"
                    class="input" />
                </div>
                <div class="tooltip text-start" :data-tip="__('The subscription name specifies which company the user is logging in on behalf of. This name can only contain English letters and numbers and must start with a letter')">
                  <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-mediuminput" for="">
                    {{ __('Subscription name') }} <span class="text-error">*</span>
                  </label>
                  <Field type="text" name="tenant_name" v-model="user.tenant_name" :validateOnBlur="false"
                    :validateOnInput="true" rules="required" :class="{ 'input-error': errors.tenant_name }"
                    class="input" />
                </div>
                <div>
                  <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-mediuminput" for="">
                    {{ __('Username (email address)') }} <span class="text-error">*</span>
                  </label>
                  <Field type="email" name="email" v-model="user.email" :validateOnBlur="false" :validateOnInput="true"
                    rules="required" :class="{ 'input-error': errors.email }" class="input" />
                </div>
                <div>
                  <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-mediuminput" for="">
                    {{ __('Password') }} <span class="text-error">*</span>
                  </label>
                  <label class="input flex items-center" :class="{ 'input-error': errors.password }">
                    <Field class="h-full" :type="showPassword ? 'text' : 'password'" autocomplete="off" name="password"
                      v-model="user.password" :validateOnBlur="false" :validateOnInput="true" rules="required" />
                    <button type="button" class="btn btn-xs btn-ghost btn-circle" @click="showPassword = !showPassword">
                      <EyeIcon class="w-4 h-4" />
                    </button>
                  </label>
                </div>
                <div class="divider col-span-full my-1 h-auto w-full"></div>
                <div>
                  <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-mediuminput" for="">
                    {{ __('State') }}
                  </label>
                  <Field as="select" name="state" v-model="user.state" class="select">
                    <option value="">{{ __('Select...') }}</option>
                    <option v-for="item in meta.states" :value="item">{{ item }}</option>
                  </Field>
                </div>
                <div>
                  <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-mediuminput" for="">
                    {{ __('Number of users?') }}
                  </label>
                  <Field as="select" name="company_size" v-model="user.company_size" class="select">
                    <option value="">{{ __('Select...') }}</option>
                    <option v-for="item in meta.company_sizes" :value="item">{{ item }}</option>
                  </Field>
                </div>
                <div class="col-span-full">
                  <label class="text-dark-550 dark:text-gray-810 text-sm mb-1 font-mediuminput" for="">
                    {{ __('Why CRM?') }}
                  </label>
                  <Field as="select" name="why_crm" v-model="user.why_crm" class="select">
                    <option value="">{{ __('Select...') }}</option>
                    <option v-for="item in meta.why_crm" :value="item">{{ item }}</option>
                  </Field>
                </div>
                <div class="form-control col-span-full">
                  <label class="label cursor-pointer justify-start">
                    <input type="checkbox" class="checkbox checkbox-secondary" rules="required" v-model="user.privacy" name="privacy" />
                    <span class="label-text mr-3"><a class="btn-link" :href="privacyUrl">{{ __('Terms and Conditions') }}</a> 
                    {{ __('I read and accept')}}
                  </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-control">
            <button type="submit" class="btn btn-primary mt-3" :disabled="enterCode || loadingButton">
              <span class="loading loading-spinner" v-if="!enterCode && loadingButton"></span>
              {{ __('Registration and authentication') }}</button>
          </div>
        </Form>
      </div>
    </section>
    <p class="mt-1 text-sm md:text-base">{{ __('Have you registered?') }} <router-link class="btn-link" :to="{name: 'login'}">{{ __('Login to account') }}</router-link></p>
  </main>
  <div class="modal modal-open" v-if="enterCode">
    <div class="modal-box relative bg-base-300">
      <button @click="enterCode = false"
                class="btn btn-sm btn-circle absolute btn-ghost left-2 top-2 pt-1 z-50">✕</button>
      <Form @submit="onVerification" class="p-4 h-full flex flex-col" v-slot="{ errors }">
        <p class="text-sm">
          {{ __('Verification code for mobile number') + ' ' + user.phone + ' ' + __('submited') }}
        </p>
        <Field type="number" dir="ltr" name="verification_code" :placeholder="__('Verification code')"
          v-model="verificationCode" :class="{ 'input-error': errors.verification_code }" rules="required"
          class="input mt-3 text-center" />
        <button v-if="enterCode" type="button" class="btn btn-ghost mt-2 mx-auto btn-sm"
          :class="{ 'pointer-events-none': timeout }" @click="onSubmit">
          {{
            timeout ? timeout : __('Request code again')
          }}
        </button>
        <div class="form-control">
          <button type="submit" class="btn btn-primary mt-3" :disabled="loadingButton">
            <span class="loading loading-spinner" v-if="loadingButton"></span>
            {{ __('Send verification code') }}</button>
        </div>
      </Form>
    </div>
  </div>
</template>
<script setup>
import Auth from "../../composables/auth";
import { Field, Form } from "vee-validate";
import { computed, onMounted, reactive, ref } from "vue";
import { EyeIcon } from "@heroicons/vue/24/outline";
const loading = Auth.loading;
const loadingButton = Auth.loadingBtn;
const errors = Auth.errors;
const showPassword = ref(false);
const meta = Auth.meta;
const user = reactive({privacy: false});
const verificationCode = ref(null);
const enterCode = Auth.enterCode;
const timeout = Auth.timeout;
const privacyUrl = APP_URL + "/privacy-policy";
const onSubmit = () => Auth.register(user);
const onVerification = () => Auth.verification(user, verificationCode.value)
const logo = window.header.logo;
const getData = () => Auth.create();
onMounted(() => getData());
</script>