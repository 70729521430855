<template>
    <div>
        <section class="pt-4">
            <div class="container "></div>
        </section>
        <header class="pt-4 relative">
            <div class="container">
                <div
                    class="flex items-center justify-between bg-white dark:bg-base-300 sm:px-11 sm:py-9 p-5 rounded-2xl shadow-headerSecOne relative">
                    <div class="lg:hidden" @click="setSideBar">
                        <svg width="24" class="text-biscay-700 dark:text-white" height="18" viewBox="0 0 24 18"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 2.5H21.5M2 9H21.5M2 15.5H21.5" stroke="currentColor" stroke-width="3.65625"
                                stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </div>
                    <div>
                        <router-link :to="{ name: 'home' }">
                            <img :src="logo" class="md:w-52 w-40 sm:ml-0 -ml-6">
                        </router-link>
                    </div>
                    <form @submit.prevent="setSaerch" class="items-center relative w-6/12 mx-auto lg:flex hidden mb-0">
                        <input name="search" @keyup="search($event)" type="text" @click="setSideBar(true, $event)"
                            :placeholder="__('what are you looking for ?')"
                            class="w-full py-4 bg-gray-210 rounded-xl pr-12  dark:text-white dark:!bg-base-100 placeholder-dark-550 dark:placeholder-white text-xs border-none">
                            <button type="submit" class="absolute right-5" ref="searchButton">
                            <svg class="text-dark-550 dark:text-white" width="16" height="17" viewBox="0 0 16 17"
                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="7.82495" cy="7.82492" r="6.74142" stroke="currentColor" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round"></circle>
                                <path d="M12.5137 12.8638L15.1568 15.4999" stroke="currentColor" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </button>
                        <div class="absolute left-5" v-if="isSearch" :class="{'z-50': isSearch}">
                            <LoadingBar class="w-5" color="text-gray-300" opacity="text-opacity-30" />
                        </div>
                        <div class="bg-white dark:bg-base-200 w-full absolute top-16 rounded-lg py-6 px-6"
                            :class="{ 'z-50': inlineSearches.length }" v-if="inlineSearches.length">
                            <ul>
                                <li v-for="search in inlineSearches"
                                    class="flex items-center justify-between mb-4 border-b-2 border-gray-300 border-opacity-10 pb-4 last:mb-0 last:border-none">
                                    <router-link @click="inlineSearches.length = 0" :to="{ name: search.type, params: { slug: search.slug } }"
                                        class="text-dark-550 dark:text-gray-910 font-medium text-base">{{ search.title
                                        }}</router-link>
                                    <div class="flex py-2 px-2 bg-gray-300 bg-opacity-10 rounded-lg">
                                        <span class="text-dark-550 dark:text-gray-910 font-normal text-xs">{{
                                                __(search.type == 'product' ?
                                                    'Database' : 'Blog')
                                        }}</span>
                                        <svg class="mr-1" width="16" height="15" viewBox="0 0 16 15" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.08264 4.73315H10.8703" stroke="#64748B" stroke-width="0.953425"
                                                stroke-linecap="round" stroke-linejoin="round"></path>
                                            <path d="M4.9115 7.71259H10.8704" stroke="#64748B" stroke-width="0.953425"
                                                stroke-linecap="round" stroke-linejoin="round"></path>
                                            <path
                                                d="M1.21695 7.7126C1.21695 9.17386 1.29587 10.3156 1.4948 11.2107C1.69254 12.1004 2.00245 12.7171 2.44444 13.1591C2.88644 13.6011 3.50307 13.911 4.3928 14.1087C5.28794 14.3076 6.42965 14.3866 7.89092 14.3866C8.53473 14.3866 9.11831 14.3713 9.64756 14.3368C11.5325 14.2138 12.6467 13.8498 13.3374 13.1591C14.0281 12.4683 14.3921 11.3542 14.5151 9.46923C14.5496 8.93998 14.5649 8.35641 14.5649 7.7126C14.5649 6.25133 14.486 5.10962 14.287 4.21448C14.0893 3.32475 13.7794 2.70811 13.3374 2.26612C12.8954 1.82413 12.2788 1.51422 11.389 1.31648C10.4939 1.11754 9.35219 1.03862 7.89092 1.03862C6.42965 1.03862 5.28794 1.11754 4.3928 1.31648C3.50307 1.51422 2.88644 1.82413 2.44444 2.26612C2.00245 2.70811 1.69254 3.32475 1.4948 4.21448C1.29587 5.10962 1.21695 6.25133 1.21695 7.7126Z"
                                                stroke="#64748B" stroke-width="0.953425" stroke-linecap="round"
                                                stroke-linejoin="round"></path>
                                        </svg>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </form>
                    <ThemeButton type="lg" />
                    <div v-if="auth.check()">
                        <div class="flex items-center">
                            <div class="lg:flex hidden ml-4">
                                <CartButton :cart="cart" @setSideBar="setSideBar" />
                                <div class=" flex">
                                    <div class="group flex items-center ">
                                        <router-link :to="{ name: 'user.notification' }"
                                            class="group lg:w-12 lg:h-12  w-10 h-10 flex items-center relative dark:hover:bg-biscay-300 dark:bg-base-100 justify-center rounded-full bg-gray-210 hover:bg-biscay-700 transition cursor-pointer">
                                            <svg width="14" height="17" viewBox="0 0 14 17" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    class="fill-current text-biscay-700 dark:group-hover:text-dark-920 dark:text-gray-900 group-hover:text-biscay-300"
                                                    d="M13.3014 8.50275C12.709 7.81089 12.4397 7.21133 12.4397 6.19274V5.8464C12.4397 4.51905 12.1342 3.66382 11.47 2.8086C10.4463 1.48043 8.72294 0.679932 7.03584 0.679932H6.9641C5.31247 0.679932 3.64311 1.44367 2.60167 2.71793C1.90119 3.59031 1.56023 4.48229 1.56023 5.8464V6.19274C1.56023 7.21133 1.30873 7.81089 0.698539 8.50275C0.249559 9.01245 0.106079 9.66755 0.106079 10.3766C0.106079 11.0864 0.339033 11.7586 0.806552 12.3051C1.41675 12.9602 2.27843 13.3784 3.15866 13.4511C4.43305 13.5965 5.70744 13.6512 7.00038 13.6512C8.2925 13.6512 9.5669 13.5598 10.8421 13.4511C11.7215 13.3784 12.5832 12.9602 13.1934 12.3051C13.6601 11.7586 13.8939 11.0864 13.8939 10.3766C13.8939 9.66755 13.7504 9.01245 13.3014 8.50275">
                                                </path>
                                                <path
                                                    class="fill-current text-biscay-700 dark:group-hover:text-dark-920 dark:text-gray-900 group-hover:text-biscay-300"
                                                    opacity="0.4"
                                                    d="M8.62912 14.653C8.22367 14.5664 5.75307 14.5664 5.34762 14.653C5.00101 14.733 4.62619 14.9192 4.62619 15.3277C4.64634 15.7173 4.87446 16.0612 5.19044 16.2793L5.18963 16.2801C5.59831 16.5986 6.07792 16.8012 6.5801 16.8739C6.84771 16.9107 7.12016 16.909 7.39745 16.8739C7.89883 16.8012 8.37844 16.5986 8.78711 16.2801L8.78631 16.2793C9.10229 16.0612 9.3304 15.7173 9.35055 15.3277C9.35055 14.9192 8.97573 14.733 8.62912 14.653">
                                                </path>
                                            </svg>
                                            <span v-if="user.notification_count"
                                                class="absolute text-white bg-red-450 rounded-full w-6 h-6 flex items-center justify-center text-xs -top-2 -right-2">{{
                                                        user.notification_count
                                                }}</span>
                                        </router-link>
                                        <router-link class="lg:hidden dark:text-white text-biscay-700 mr-2"
                                            :to="{ name: 'user.notification' }">
                                            {{ __('Notifications') }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <UserButton :user="user" />
                        </div>
                    </div>
                    <div class="inline-block" v-else>
                        <div class="flex items-center">
                            <div class="flex bg-blue-700 bg-opacity-10 rounded-lg ">
                                <router-link :to="{ name: 'login' }"
                                    class="text-blue-700 bg-blue-700 bg-opacity-0 hover:bg-opacity-80 transition duration-200 hover:text-white font-medium sm:text-base text-xs inline-flex items-center rounded-lg h-10 pr-3 -ml-3 pl-6">
                                    <span class="sm:inline-block hidden">
                                        {{ __('Login') }}
                                    </span>
                                    <span class="mr-1">
                                        <svg width="22" height="23" viewBox="0 0 22 23" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.4"
                                                d="M6.67065 6.4847C6.67065 4.27199 8.53019 2.47095 10.8157 2.47095H15.3587C17.6387 2.47095 19.4935 4.26748 19.4935 6.47567V16.5109C19.4935 18.7246 17.6349 20.5265 15.3494 20.5265H10.8064C8.52646 20.5265 6.67065 18.7291 6.67065 16.52V15.6714V6.4847Z"
                                                fill="currentColor"></path>
                                            <path
                                                d="M14.5621 11.0056L11.8827 8.37941C11.6058 8.10858 11.1602 8.10858 10.8841 8.38122C10.6091 8.65386 10.61 9.09351 10.886 9.36434L12.3531 10.8025H3.04688C2.65717 10.8025 2.34082 11.1139 2.34082 11.4985C2.34082 11.8822 2.65717 12.1927 3.04688 12.1927H12.3531L10.886 13.6318C10.61 13.9026 10.6091 14.3423 10.8841 14.6149C11.0226 14.7512 11.2033 14.8198 11.3848 14.8198C11.5645 14.8198 11.7452 14.7512 11.8827 14.6167L14.5621 11.9905C14.695 11.8596 14.7702 11.6827 14.7702 11.4985C14.7702 11.3134 14.695 11.1365 14.5621 11.0056Z"
                                                fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </router-link>
                                <router-link :to="{ name: 'register' }"
                                    class="sm:inline-flex hidden bg-blue-700 text-white hover:opacity-80  duration-75 font-medium sm:text-base text-xs items-center rounded-lg h-10 px-3 ">
                                    <span>
                                        {{ __('Register') }}
                                    </span>
                                    <span class="mr-1">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.4"
                                                d="M19.3827 8.78099H18.2966V7.71911C18.2966 7.26576 17.933 6.89612 17.485 6.89612C17.0379 6.89612 16.6734 7.26576 16.6734 7.71911V8.78099H15.5892C15.1412 8.78099 14.7776 9.15063 14.7776 9.60398C14.7776 10.0573 15.1412 10.427 15.5892 10.427H16.6734V11.4898C16.6734 11.9431 17.0379 12.3128 17.485 12.3128C17.933 12.3128 18.2966 11.9431 18.2966 11.4898V10.427H19.3827C19.8297 10.427 20.1943 10.0573 20.1943 9.60398C20.1943 9.15063 19.8297 8.78099 19.3827 8.78099"
                                                fill="currentColor"></path>
                                            <path
                                                d="M8.90975 13.681C5.25731 13.681 2.13892 14.265 2.13892 16.598C2.13892 18.9301 5.23834 19.5351 8.90975 19.5351C12.5613 19.5351 15.6806 18.9511 15.6806 16.6181C15.6806 14.2851 12.5812 13.681 8.90975 13.681"
                                                fill="currentColor"></path>
                                            <path opacity="0.4"
                                                d="M8.90984 11.459C11.3966 11.459 13.39 9.43996 13.39 6.92114C13.39 4.40232 11.3966 2.38232 8.90984 2.38232C6.42308 2.38232 4.42969 4.40232 4.42969 6.92114C4.42969 9.43996 6.42308 11.459 8.90984 11.459"
                                                fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="[menuSate ? '!right-0' : '!-right-full']"
                    class="dark:shadow-sm lg:bg-gray-50 dark:bg-base-300 lg:mx-9 lg:shadow-headerSecTwo lg:rounded-b-3xl lg:py-5 lg:static fixed flex flex-col items-center z-under-overlay lg:w-auto w-276 transition-all duration-200 ease-in-out top-0 bottom-0 h-full lg:h-auto pt-10 bg-white">
                    <div class="flex justify-center relative w-full flex-col lg:flex-row overflow-auto lg:overflow-visible">
                    <div class="justify-between relative items-center lg:hidden flex">
                        <span class="transform lg:scale-90 scale-75">
                            <img :src="logo" class="w-56 sm:inline-block">
                        </span>
                        <button class="lg:hidden absolute bottom-full mb-1 left-5" @click="setSideBar(false)">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 2L9 9M9 9L2 16M9 9L16 16M9 9L2 2" stroke="#353F53" stroke-width="2.88235"
                                    stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </button>
                    </div>


                    <form class="lg:hidden inline-block w-full px-4 pt-5 pb-1">
                        <div class="w-full h-full relative flex items-center">
                            <input type="text" name="search" @keyup="search($event)"
                                class="w-full py-4 bg-gray-210 dark:bg-dark-890 rounded-xl pl-12 pr-8 dark:placeholder-gray-200 dark:text-white placeholder-dark-550 text-xs border-none"
                                :placeholder="__('what are you looking for ?')">
                            <button @click="setSaerch" type="button" class="absolute left-5">
                                <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="7.82495" cy="7.82492" r="6.74142" stroke="#64748B" stroke-width="1.5"
                                        stroke-linecap="round" stroke-linejoin="round"></circle>
                                    <path d="M12.5137 12.8638L15.1568 15.4999" stroke="#64748B" stroke-width="1.5"
                                        stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </button>
                        </div>
                    </form>

                    <div class="px-6 w-full mt-5 mb-3 lg:hidden inline-block">
                        <div
                            class="font-bold text-gray-800 py-5 border-t border-b dark:border-opacity-10 border-biscay-100">
                            <ThemeButton />
                            <div class="lg:hidden flex mb-5" v-if="auth.check()">
                                <div class="group flex items-center">
                                    <router-link :to="{ name: 'cart' }"
                                        class="group lg:w-12 lg:h-12  w-10 h-10 flex items-center relative justify-center rounded-full bg-gray-210 dark:bg-base-300 hover:bg-biscay-700 transition cursor-pointer">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                class="fill-current text-biscay-700 dark:text-gray-200 group-hover:text-biscay-300"
                                                fill-rule="evenodd" clip-rule="evenodd"
                                                d="M19.1705 14.9551L18.4657 9.27669C18.0363 7.25046 16.8211 6.41663 15.6626 6.41663H6.35407C5.17937 6.41663 3.92365 7.1921 3.55909 9.27669L2.84616 14.9551C2.26286 19.1243 4.40973 20.1666 7.21282 20.1666H14.8119C17.6069 20.1666 19.689 18.6574 19.1705 14.9551ZM8.33901 11.1362C7.89158 11.1362 7.52887 10.7629 7.52887 10.3024C7.52887 9.84187 7.89158 9.46855 8.33901 9.46855C8.78644 9.46855 9.14915 9.84187 9.14915 10.3024C9.14915 10.7629 8.78644 11.1362 8.33901 11.1362ZM12.8353 10.3024C12.8353 10.7629 13.198 11.1362 13.6454 11.1362C14.0928 11.1362 14.4555 10.7629 14.4555 10.3024C14.4555 9.84187 14.0928 9.46855 13.6454 9.46855C13.198 9.46855 12.8353 9.84187 12.8353 10.3024Z">
                                            </path>
                                            <path
                                                class="fill-current text-biscay-700 dark:text-gray-200 group-hover:text-biscay-300"
                                                opacity="0.4"
                                                d="M15.5594 6.20972C15.5623 6.28082 15.5486 6.35162 15.5195 6.41658H14.2021C14.1766 6.35053 14.1631 6.28049 14.1622 6.20972C14.1622 4.45201 12.7324 3.0271 10.9686 3.0271C9.20486 3.0271 7.77504 4.45201 7.77504 6.20972C7.78713 6.27815 7.78713 6.34815 7.77504 6.41658H6.42575C6.41367 6.34815 6.41367 6.27815 6.42575 6.20972C6.52827 3.76367 8.54793 1.83325 11.0046 1.83325C13.4612 1.83325 15.4808 3.76367 15.5834 6.20972H15.5594Z">
                                            </path>
                                        </svg>
                                        <span v-if="cart.items.length"
                                            class="absolute text-white bg-red-450 rounded-full w-6 h-6 flex items-center justify-center text-xs -top-2 -right-2">
                                            {{ cart.items.length }}
                                        </span>
                                    </router-link>
                                    <router-link class="lg:hidden mr-2 dark:text-gray-200 text-biscay-700"
                                        :to="{ name: 'cart' }">
                                        {{ __('Cart') }}
                                    </router-link>
                                </div>
                            </div>
                            <div class=" flex" v-if="auth.check()">
                                <div class="group flex items-center ">
                                    <router-link :to="{ name: 'user.notification' }"
                                        class="group lg:w-12 lg:h-12  w-10 h-10 flex items-center relative dark:hover:bg-biscay-300 dark:bg-base-300 justify-center rounded-full bg-gray-210 hover:bg-biscay-700 transition cursor-pointer">
                                        <svg width="14" height="17" viewBox="0 0 14 17" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                class="fill-current text-biscay-700 dark:group-hover:text-dark-920 dark:text-gray-900 group-hover:text-biscay-300"
                                                d="M13.3014 8.50275C12.709 7.81089 12.4397 7.21133 12.4397 6.19274V5.8464C12.4397 4.51905 12.1342 3.66382 11.47 2.8086C10.4463 1.48043 8.72294 0.679932 7.03584 0.679932H6.9641C5.31247 0.679932 3.64311 1.44367 2.60167 2.71793C1.90119 3.59031 1.56023 4.48229 1.56023 5.8464V6.19274C1.56023 7.21133 1.30873 7.81089 0.698539 8.50275C0.249559 9.01245 0.106079 9.66755 0.106079 10.3766C0.106079 11.0864 0.339033 11.7586 0.806552 12.3051C1.41675 12.9602 2.27843 13.3784 3.15866 13.4511C4.43305 13.5965 5.70744 13.6512 7.00038 13.6512C8.2925 13.6512 9.5669 13.5598 10.8421 13.4511C11.7215 13.3784 12.5832 12.9602 13.1934 12.3051C13.6601 11.7586 13.8939 11.0864 13.8939 10.3766C13.8939 9.66755 13.7504 9.01245 13.3014 8.50275">
                                            </path>
                                            <path
                                                class="fill-current text-biscay-700 dark:group-hover:text-dark-920 dark:text-gray-900 group-hover:text-biscay-300"
                                                opacity="0.4"
                                                d="M8.62912 14.653C8.22367 14.5664 5.75307 14.5664 5.34762 14.653C5.00101 14.733 4.62619 14.9192 4.62619 15.3277C4.64634 15.7173 4.87446 16.0612 5.19044 16.2793L5.18963 16.2801C5.59831 16.5986 6.07792 16.8012 6.5801 16.8739C6.84771 16.9107 7.12016 16.909 7.39745 16.8739C7.89883 16.8012 8.37844 16.5986 8.78711 16.2801L8.78631 16.2793C9.10229 16.0612 9.3304 15.7173 9.35055 15.3277C9.35055 14.9192 8.97573 14.733 8.62912 14.653">
                                            </path>
                                        </svg>
                                        <span v-if="user.notification_count"
                                            class="absolute text-white bg-red-450 rounded-full w-6 h-6 flex items-center justify-center text-xs -top-2 -right-2">
                                            {{ user.notification_count }}
                                        </span>
                                    </router-link>
                                    <router-link class="lg:hidden dark:text-white text-biscay-700 mr-2"
                                        :to="{ name: 'user.notification' }">
                                        {{ __('Notifications') }}
                                    </router-link>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="lg:overflow-visible px-3 overflow-auto lg:h-auto h-full lg:w-auto w-full">
                        <Menu :children="header.menus" :horizontal="true" />
                    </div>
                </div>
                </div>
            </div>
        </header>
    </div>
</template>
<script setup>
import { ref, inject,onMounted } from 'vue';
import Auth from '../../composables/auth';
import useHome from '../../composables/home';
import LoadingBar from '../svg/LoadingBar.vue'
import Menu from "../layout/Menu.vue";
import ThemeButton from './ThemeButton.vue';
import CartButton from './CartButton.vue';
import UserButton from './UserButton.vue';
const header = window.header;
defineProps(['logo']);
const emit = defineEmits(['setSideBar']);
const menuSate = inject('menuSate');
const userDropDown = ref(null);
let timeout = null;
const value = ref('');
const user = Auth.user;
const { inlineSearches, isSearch, controller, getInlineSearch,router } = useHome();
const cart = window.cart;
const searchButton = ref(null);
onMounted(() => document.querySelector('.backdrop-filter').addEventListener('click',() => {inlineSearches.value.length = 0;}))
const setSideBar = (show = true, e = null) => {
    emit('setSideBar', show, e !== null);
    if (e === null) return false;
    userDropDown.value = e.currentTarget;
    if(e.currentTarget?.name == 'search') {
        searchButton.value?.classList?.add('z-50')
        e.currentTarget.classList.add('z-50')
    }
}

const setSaerch = () => {
    setSideBar(false,'search')
    if(!value.value.trim().length) return false;
    router.push({name:'search',query:{value:value.value}});
    searchButton.value?.classList?.remove('z-50');
    inlineSearches.value.length = 0;
}

const search = (e) => {
    value.value = e.target.value.trim();
    if (timeout) clearTimeout(timeout)
    if(menuSate.value) return false;
    if (controller.value) controller.value.abort();
    if (value.value.length < 3) {
        inlineSearches.value.length = 0;
        return false;
    }
    timeout = setTimeout(() => getInlineSearch(value.value), 300);
}
</script>