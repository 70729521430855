<template>
    <footer class="relative z-0">
        <div class="container">
            <div class="content pb-10 pt-10">
                <div class="mb-12 relative">
                    <span
                        class="absolute border-t border-1 sm:top-2/4 top-5 trasform sm:translate-y-2/4 dark:border-blue-980 border-chambray-100 w-full z-0 right-0"></span>
                    <div class="flex items-center justify-between sm:flex-row flex-col relative z-10">
                        <div
                            class="logo w-fit-content dark:bg-base-100 sm:px-0 bg-background-main-rgb px-5 sm:pl-8 sm:mb-0 mb-6">
                            <img :src="logo" class="inline-block">
                        </div>

                        <ul class="flex dark:bg-base-100 bg-background-main-rgb sm:pr-8 self-center ">
                            <li v-for="(social, index) in footer.social" :class="{ 'mr-2': index !== 0 }">
                                <a :href="social.url" rel="nofollow"
                                    class="group flex items-center rounded justify-center w-10 h-10 dark:hover:bg-blue-970 dark:bg-blue-800 dark:bg-opacity-25 bg-blue-50 group hover:bg-blue-700 transition duration-300 ease-linear">
                                    <span v-html="social.icon"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="grid md:grid-cols-9 grid-cols-1 xl:gap-20 gap-7">
                    <div class="md:col-span-4">
                        <div class="relative flex flex-col md:items-start md:mx-0  mx-auto w-fit-content items-center">
                            <h3 class="text-xl relative font-bold dark:text-white text-biscay-700 mb-4 z-10">{{
                                    __('About your number')
                            }}</h3>
                            <span
                                class="z-0 w-4 h-4 flex absolute bg-blue-700 opacity-20 top-0 rounded-full -right-2"></span>
                        </div>

                        <div class="flex relative ">
                            <span
                                class="md:flex hidden absolute top-1 right-0 w-1 h-1 mt-2 bg-blue-700 opacity-30 rounded-full"></span>
                            <div class="md:mr-2 md:text-right text-center">
                                <p
                                    class="text-base mb-2 md:w-10/12 w-full md:text-justify text-center leading-7 dark:text-gray-920 text-biscay-650 ">
                                    {{ footer.about }}</p>

                                <router-link :to="{name: 'products'}"
                                    class="inline-flex items-center group dark:hover:text-blue-60 dark:text-blue-950 text-blue-700 text-13 font-medium transition transform  duration dark:hover:text-gray-210-20 hover:text-dark-700 md:mx-0 mx-auto">
                                    {{ __('View databases') }}
                                    <svg class="mr-1" width="15" height="10" viewBox="0 0 15 10" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill="currentColor" opacity="0.4"
                                            d="M10.4527 4.15949L13.3686 3.90161C14.023 3.90161 14.5536 4.43735 14.5536 5.09812C14.5536 5.75889 14.023 6.29463 13.3686 6.29463L10.4527 6.03674C9.93928 6.03674 9.52308 5.61649 9.52308 5.09812C9.52308 4.57888 9.93928 4.15949 10.4527 4.15949">
                                        </path>
                                        <path fill="currentColor"
                                            class="fill-current duration-200 transition dark:group-hover:text-blue-60 dark:text-blue-950 text-blue dark:hover:text-gray-210-700 grou-hover:text-dark-700"
                                            d="M0.660621 4.20689C0.706196 4.16087 0.87646 3.96637 1.0364 3.80487C1.96941 2.79331 4.40555 1.13921 5.67995 0.632993C5.87343 0.552242 6.36272 0.38032 6.62499 0.368164C6.87523 0.368164 7.11429 0.42634 7.34216 0.540954C7.6268 0.701589 7.85381 0.95513 7.97936 1.25382C8.05933 1.46048 8.18488 2.08131 8.18488 2.09259C8.30957 2.77073 8.3775 3.87346 8.3775 5.09255C8.3775 6.25259 8.30957 7.31017 8.20724 7.99959C8.19606 8.01175 8.07051 8.78192 7.93379 9.04589C7.68355 9.52866 7.19426 9.82735 6.67057 9.82735H6.62499C6.28361 9.81606 5.56644 9.5165 5.56644 9.50608C4.35998 8.99987 1.98145 7.42565 1.02522 6.37936C1.02522 6.37936 0.755212 6.11019 0.638263 5.94261C0.455961 5.70122 0.36481 5.40253 0.36481 5.10383C0.36481 4.77041 0.46714 4.46043 0.660621 4.20689">
                                        </path>
                                    </svg>
                                </router-link>
                            </div>
                        </div>

                        <!-- <div class="relative mt-11 dark:bg-base-100">
                            <Form @submit="subscribeNewsletter">
                                <svg class="dark:text-gray-880 text-gray-990 absolute top-2/4 transform -translate-y-2/4 right-4"
                                    width="14" height="13" viewBox="0 0 24 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M21.9714 6.11867C21.7669 5.19351 21.5001 4.51466 21.1854 4C20.1591 5.01102 18.8602 6.23772 17.4497 7.31464C15.5051 8.79945 13.575 9.81806 12 9.81806C10.425 9.81806 8.49494 8.79945 6.55027 7.31464C5.13983 6.23772 3.84094 5.01102 2.81462 4C2.49985 4.51466 2.23311 5.19351 2.0286 6.11867C2.0189 6.16254 2.00937 6.20685 2 6.25158C2.93097 7.14206 4.03799 8.14215 5.22513 9.04856C7.2098 10.5639 9.64571 12 12 12C14.3543 12 16.7902 10.5639 18.7749 9.04856C19.962 8.14215 21.069 7.14206 22 6.25158C21.9906 6.20685 21.9811 6.16254 21.9714 6.11867Z"
                                        fill="currentColor"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M0 11C0 20.0585 2.118 22 12 22C21.882 22 24 20.0585 24 11C24 1.9415 21.882 0 12 0C2.118 0 0 1.9415 0 11ZM12 20.1667C9.57515 20.1667 7.76307 20.0452 6.38993 19.7654C5.03949 19.4903 4.23924 19.0871 3.70846 18.6006C3.17768 18.114 2.73783 17.3805 2.43771 16.1426C2.13254 14.8838 2 13.2228 2 11C2 8.77722 2.13254 7.11615 2.43771 5.85743C2.73783 4.61954 3.17768 3.88597 3.70846 3.39943C4.23924 2.91288 5.03949 2.50968 6.38993 2.23457C7.76307 1.95483 9.57515 1.83333 12 1.83333C14.4249 1.83333 16.2369 1.95483 17.6101 2.23457C18.9605 2.50968 19.7608 2.91288 20.2915 3.39943C20.8223 3.88597 21.2622 4.61954 21.5623 5.85743C21.8675 7.11615 22 8.77722 22 11C22 13.2228 21.8675 14.8838 21.5623 16.1426C21.2622 17.3805 20.8223 18.114 20.2915 18.6006C19.7608 19.0871 18.9605 19.4903 17.6101 19.7654C16.2369 20.0452 14.4249 20.1667 12 20.1667Z"
                                        fill="currentColor"></path>
                                    <rect x="7" y="15" width="10" height="2" rx="1" fill="currentColor"></rect>
                                </svg>
                                <div
                                    class="h-10 rounded dark:bg-base-100 dark:border-gray-880 border-gray-100 border-t border-r border-b dark:border-opacity-5 border-1">
                                    <Field type="email"
                                        :placeholder="__('Enter your e-mail number to get the latest news and discounted festivals.')"
                                        v-model="email" name="email" rules="required|email"
                                        class="border-none dark:rounded dark:bg-transparent m-0 h-full w-full pl-24 dark:placeholder-gray-880 dark:text-white  placeholder-gray-400 text-gray-400 text-xs py-2 pr-8" />
                                </div>
                                <button type="submit"
                                    class="h-full bg-blue-700 absolute top-0 left-0 px-5 text-white rounded-l text-13 hover:bg-dark-700 transition duration-200 ease-linear">{{
                                            __('Record')
                                    }}
                                    {{ __('Mail') }}</button>
                            </Form>
                        </div> -->
                    </div>

                    <div class="md:col-span-2">
                        <div class="relative flex md:items-start md:mx-0  mx-auto w-fit-content items-center">
                            <h3 class="text-xl relative font-bold dark:text-white text-biscay-700 mb-4 z-10">{{
                                    __('Sections of the site')
                            }}</h3>
                            <span
                                class="z-0 w-4 h-4 flex absolute bg-blue-700 opacity-20 top-0 rounded-full -right-2"></span>
                        </div>
                        <ul class="flex flex-col md:items-start items-center">
                            <li class="mb-3 last:mb-0 flex items-center" v-for="menu in footer.menus">
                                <span
                                    class="md:flex hidden w-1 h-1 ml-2 mb-1 dark:text-gray-940 bg-blue-700 opacity-30 rounded-full"></span>
                                    <router-link :to="{name: menu.slug}" v-if="menu.slug" class="text-base text-biscay-650 dark:text-gray-920 transition transform dark:hover:text-blue-450 hover:text-dark-700 duration-200 ease-linear"
                                    active-class="" :href="(menu.link || menu.slug)">{{ menu.title }}</router-link>
                                <a v-else class="text-base text-biscay-650 dark:text-gray-920 transition transform dark:hover:text-blue-450 hover:text-dark-700 duration-200 ease-linear"
                                    active-class="" :href="menu.link">{{ menu.title }}</a>
                            </li>
                        </ul>
                    </div>

                    <div class="md:col-span-3">
                        <div class="relative flex md:items-start md:mx-0  mx-auto w-fit-content items-center">
                            <h3 class="text-xl relative font-bold dark:tew dark:text-white text-biscay-700 mb-4 z-10">{{
                                    __('Contact us')
                            }}</h3>
                            <span
                                class="z-0 w-4 h-4 flex absolute bg-blue-700 opacity-20 top-0 rounded-full -right-2"></span>
                        </div>

                        <ul>
                            <li class="flex items-center justify-between mb-6 last:mb-0">
                                <span class="flex items-center">
                                    <span class="relative ml-3">
                                        <i class="flex w-5 h-5 bg-blue-700 opacity-20 rounded-full"></i>

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="1.5" stroke="currentColor"
                                            class="dark:text-blue-950 text-blue-980 absolute -top-1 -left-2" width="22"
                                            height="19">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                        </svg>

                                    </span>

                                    <span class="text-sm font-medium text-dark-550 dark:text-gray-920"> {{ __('Address')
                                    }}: </span>
                                </span>
                                <a
                                    class="dark:text-white text-biscay-650 dark:hover:text-blue-450 font-medium text-sm transition transform  hover:text-dark-700 duration-200 ease-linear">{{
                                            footer.address
                                    }}</a>
                            </li>
                            <li class="flex items-center justify-between mb-6 last:mb-0">
                                <span class="flex items-center"><span class="relative ml-3">
                                        <i class="flex w-5 h-5 bg-blue-700 opacity-20 rounded-full"></i>

                                        <svg class="dark:text-blue-950 text-blue-980 absolute -top-1 -left-2" width="17"
                                            height="17" viewBox="0 0 17 17" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8.83538 10.3C9.27844 10.6121 9.74293 10.8745 10.2217 11.0731C10.519 11.1809 10.9855 10.8756 11.3285 10.6511L11.3286 10.6511C11.4143 10.595 11.4923 10.544 11.558 10.5057L11.5796 10.4936C11.9027 10.3121 12.2615 10.1106 12.7228 10.2078C13.1373 10.2929 14.5594 11.4136 14.9524 11.8108C15.2097 12.0661 15.3526 12.3357 15.374 12.6123C15.4169 13.6408 14.0377 14.7969 13.709 14.9955C13.0087 15.5062 12.0725 15.4991 10.9435 14.9884C9.73579 14.499 8.29228 13.4705 6.8988 12.2222C6.39998 11.7753 5.44205 10.8344 5.16944 10.5202C3.76167 8.99521 2.57543 7.35674 2.01803 6.03036C1.75363 5.47002 1.625 4.95224 1.625 4.4912C1.625 4.03725 1.75363 3.63295 2.00374 3.2854C2.15381 3.02296 3.36149 1.59018 4.42625 1.62565C4.69066 1.65402 4.96221 1.78878 5.22661 2.04413C5.62679 2.43424 6.7773 3.84574 6.86306 4.26422C6.96089 4.71509 6.75803 5.07099 6.57526 5.39163L6.57526 5.39163L6.56292 5.41328C6.52173 5.48445 6.46588 5.56888 6.40483 5.66117L6.40482 5.66118C6.1799 6.0012 5.8843 6.44805 5.99052 6.73257C6.25564 7.38512 6.63438 8.02348 7.09102 8.6122C7.59779 9.21811 8.39233 9.98789 8.83538 10.3Z"
                                                stroke="currentColor" stroke-width="1.5"></path>
                                        </svg>
                                    </span>

                                    <span class="text-sm font-medium text-dark-550 dark:text-gray-920">{{ __('Phone Stable') }}: </span>
                                </span>
                                <a class="dark:text-white text-biscay-650 dark:hover:text-blue-450 font-medium text-sm transition transform  hover:text-dark-700 duration-200 ease-linear"
                                    :href="`tel:${footer.phone}`">{{ footer.phone }}</a>
                            </li>
                            <li class="flex items-center justify-between mb-6 last:mb-0">
                                <span class="flex items-center"><span class="relative ml-3">
                                        <i class="flex w-5 h-5 bg-blue-700 opacity-20 rounded-full"></i>

                                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="dark:text-blue-950 text-blue-980 w-5 h-5 absolute -top-1 -left-2">
  <path d="M8 16.25a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75z" />
  <path fill-rule="evenodd" d="M4 4a3 3 0 013-3h6a3 3 0 013 3v12a3 3 0 01-3 3H7a3 3 0 01-3-3V4zm4-1.5v.75c0 .414.336.75.75.75h2.5a.75.75 0 00.75-.75V2.5h1A1.5 1.5 0 0114.5 4v12a1.5 1.5 0 01-1.5 1.5H7A1.5 1.5 0 015.5 16V4A1.5 1.5 0 017 2.5h1z" clip-rule="evenodd" />
</svg>


                                    </span>

                                    <span class="text-sm font-medium text-dark-550 dark:text-gray-920">{{ __('Phone number') }}: </span>
                                </span>
                                <a class="dark:text-white text-biscay-650 dark:hover:text-blue-450 font-medium text-sm transition transform  hover:text-dark-700 duration-200 ease-linear"
                                    :href="`tel:${footer.mobile}`">{{ footer.mobile }}</a>
                            </li>
                            <li class="flex items-center justify-between mb-7 last:mb-0">
                                <span class="flex items-center">
                                    <span class="relative ml-3">
                                        <i class="flex w-5 h-5 bg-blue-700 opacity-20 rounded-full"></i>

                                        <svg class="dark:text-blue-950 text-blue-980 absolute -top-1 -left-2" width="17"
                                            height="15" viewBox="0 0 17 15" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M15.5632 4.17184C15.4183 3.54105 15.2294 3.0782 15.0064 2.72729C14.2794 3.41662 13.3594 4.25301 12.3603 4.98727C10.9828 5.99965 9.61574 6.69415 8.50008 6.69415C7.38443 6.69415 6.01733 5.99965 4.63986 4.98727C3.64079 4.25301 2.72075 3.41662 1.99377 2.72729C1.77081 3.0782 1.58187 3.54105 1.437 4.17184C1.43013 4.20176 1.42338 4.23196 1.41675 4.26247C2.07618 4.86961 2.86033 5.55149 3.70121 6.1695C5.10702 7.2027 6.83246 8.18184 8.50008 8.18184C10.1677 8.18184 11.8931 7.2027 13.299 6.1695C14.1398 5.55149 14.924 4.86961 15.5834 4.26246C15.5768 4.23196 15.57 4.20176 15.5632 4.17184Z"
                                                fill="currentColor"></path>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M0 7.5C0 13.6762 1.50025 15 8.5 15C15.4997 15 17 13.6762 17 7.5C17 1.32375 15.4997 0 8.5 0C1.50025 0 0 1.32375 0 7.5ZM8.5 13.75C6.7824 13.75 5.49884 13.6672 4.5262 13.4764C3.56964 13.2889 3.0028 13.0139 2.62683 12.6822C2.25086 12.3505 1.9393 11.8503 1.72671 11.0063C1.51055 10.1481 1.41667 9.01553 1.41667 7.5C1.41667 5.98447 1.51055 4.85192 1.72671 3.99371C1.9393 3.14968 2.25086 2.64953 2.62683 2.31779C3.0028 1.98605 3.56964 1.71115 4.5262 1.52357C5.49884 1.33284 6.7824 1.25 8.5 1.25C10.2176 1.25 11.5012 1.33284 12.4738 1.52357C13.4304 1.71115 13.9972 1.98605 14.3732 2.31779C14.7491 2.64953 15.0607 3.14968 15.2733 3.99371C15.4895 4.85192 15.5833 5.98447 15.5833 7.5C15.5833 9.01553 15.4895 10.1481 15.2733 11.0063C15.0607 11.8503 14.7491 12.3505 14.3732 12.6822C13.9972 13.0139 13.4304 13.2889 12.4738 13.4764C11.5012 13.6672 10.2176 13.75 8.5 13.75Z"
                                                fill="currentColor"></path>
                                        </svg>
                                    </span>

                                    <span class="text-sm font-medium text-dark-550 dark:text-gray-920"> {{ __('Mail')
                                    }}: </span>
                                </span>
                                <a class="dark:text-white text-biscay-650 dark:hover:text-blue-450 font-medium text-sm transition transform  hover:text-dark-700 duration-200 ease-linear"
                                    :href="`mailto:${footer.email}`">{{ footer.email }}</a>
                            </li>
                        </ul>

                        <!-- <div class="flex items-center mt-9">
                            <div class="cursor-pointer">
                                <img src="https://roocket.ir/img/pic/samandehi.png?width=68&height=86" width="68" height="86"
                                    onclick="window.open(&quot;https://logo.samandehi.ir/Verify.aspx?id=111121&amp;p=rfthrfthrfthrfthuiwkrfth&quot;, &quot;Popup&quot;,&quot;toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30&quot;)"
                                    alt="samandehi">
                            </div>
                        </div> -->
                    </div>
                </div>

                <div class="relative mt-2 flex md:justify-start justify-center">
                    <p
                        class="dark:bg-base-100 dark:text-gray-970 bg-background-main-rgb pl-3 text-center relative z-10 inline-block text-xs text-chambray-400">
                        {{ footer.copyright }}</p>

                    <span
                        class="absolute border-t border-1 top-2/4 trasform translate-y-2/4  dark:border-blue-980 border-chambray-100 w-full z-0 right-0"></span>
                </div>
            </div>
        </div>
    </footer>
</template>
<script setup>
import useHome from '../../composables/home';
import { Field, Form } from "vee-validate";
import { ref } from 'vue';
defineProps(['logo']);
const footer = window.footer;
const { subscribeNewsletter,email } = useHome();
</script>