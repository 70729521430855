<template>
    <Form @submit="onSubmit" class="w-96 mt-52 mr-52" v-slot="{ errors }">
    <Field name="user_id" v-slot="{ field }" v-model="person" rules="required">
      <v-select dir="rtl" :options="persons" :reduce="person => person.id" v-bind="field" class="input" :class="{ 'input-error': errors.user_id }" @search="fetchOptions" :loading="loading" label="fullname">
      <template #no-options>
        {{ __('Nothing found to display') }}
    </template>
    </v-select>
    </Field>
<button type="submit" class="btn btn-primary">Submit</button>
  </Form>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { Field, Form } from "vee-validate";
import useDashboard from '../../composables/dashboard';
const { getPersons,persons,loading } = useDashboard();
const person = ref();
let timeout = null;
const fetchOptions = (search) => {
  loading.value = true;
  if(timeout) clearTimeout(timeout);
  timeout = setTimeout(() => getPersons({search}),500);
}

</script>
