<template>
    <Progressbar v-if="loading" />
    <NetworkError v-else-if="!campaign.name && errors" @tryAgain="getData" />
    <div class="w-full h-full bg-gray-200 dark:bg-base-100 rounded-tr-3xl xl:px-9 pt-6 lg:pt-10 pb-14 " v-else>
        <div class="container">
            <div class="flex justify-between items-center mb-6">
                <h3 class="flex items-center md:text-26 text-2xl dark:text-white text-biscay-700 font-semibold">
                    <i class="md:flex hidden w-2 h-2 dark:bg-white rounded-full bg-blue-700 ml-2"></i>
                    {{ __('Campaign details') }}
                </h3>
                <div class="flex justify-end order-first lg:order-last" v-if="can('campaign.create')">
                        <router-link :to="{name: 'user.campaign.edit',params: {id: campaign.id}}" class="btn btn-outline btn-info btn-sm"
                        :class="{'btn-disabled': !can('campaign.edit.all') && !can('campaign.edit.self',campaign.self)}">
                            <span class="mt-0.5">{{ __('Edit') }}</span>
                        </router-link>
                        <label @click="setItem(campaign)" for="delete-modal" :class="{'btn-disabled': !can('campaign.delete.all') && !can('campaign.delete.self',campaign.self)}"
                                        class="btn btn-outline btn-error btn-sm mr-2">
                                        {{ __('Delete') }}
                        </label>
                    </div>
            </div>
            <div
                class="bg-white dark:bg-base-300 dark:shadow-whiteShadow rounded-xl shadow-sm sm:px-6 sm:py-6 px-3 py-4  my-5">

                <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 md:gap-x-14 gap-y-4">
                    <p class="text-gray-70">{{ __('Name') }}: <span class="text-black dark:text-white">{{ campaign.name }}</span></p>
                    <p class="text-gray-70">{{ __('Status') }}: <span class="text-black dark:text-white">{{ __(campaign.status?.capitalize()) }}</span></p>
                    <p class="text-gray-70">{{ __('Start date') }}: <span class="text-black dark:text-white">{{ campaign.start_date }}</span></p>
                    <p class="text-gray-70">{{ __('End date') }}: <span class="text-black dark:text-white">{{ campaign.end_date }}</span></p>
                    <p class="text-gray-70">{{ __('Type') }}: <span class="text-black dark:text-white">{{ __(campaign.type?.capitalize()) }}</span></p>
                    <p class="text-gray-70">{{ __('Currency') }}: <span class="text-black dark:text-white">{{ __(campaign.currency?.capitalize()) || '---' }}</span></p>
                    <p class="text-gray-70">{{ __('Impressions') }}: <span class="text-black dark:text-white">{{ setCurrency(campaign.impressions) || '---' }}</span></p>
                    <p class="text-gray-70">{{ __('Budget') }}: <span class="text-black dark:text-white">{{ setCurrency(campaign.budget) || '---' }}</span></p>
                    <p class="text-gray-70">{{ __('Actual cost') }}: <span class="text-black dark:text-white">{{ setCurrency(campaign.actual_cost) || '---' }}</span></p>
                    <p class="text-gray-70">{{ __('Expected income') }}: <span class="text-black dark:text-white">{{ setCurrency(campaign.expected_revenue) || '---' }}</span></p>
                    <p class="text-gray-70">{{ __('Expected cost') }}: <span class="text-black dark:text-white">{{ setCurrency(campaign.expected_cost) || '---' }}</span></p>
                    <p class="text-gray-70">{{ __('Expected response') }}: <span class="text-black dark:text-white">{{ __(campaign.expected_response?.capitalize()) || '---' }}</span></p>
                    <p class="text-gray-70">{{ __('Target') }}: <span class="text-black dark:text-white">{{ campaign.objective || '---' }}</span></p>
                    <p class="text-gray-70">{{ __('User') }}: <span class="text-black dark:text-white">{{
                            campaign.user }}</span></p>
                    <p class="text-gray-70">{{ __('Created At') }}: <span class="text-black dark:text-white">{{ campaign.created_at }}</span></p>
                    <p class="text-gray-70">{{ __('Update date') }}: <span class="text-black dark:text-white">{{ campaign.updated_at }}</span></p>
                    <div class="divider h-auto col-span-full my-0"></div>
                    <p class="text-gray-70">{{ __('Description') }}: <span class="text-black dark:text-white">{{ campaign.description || '---' }}</span></p>
                </div>
            </div>
            <div class="flex justify-between items-center mt-8 mb-6">
                <h3 class="flex items-center md:text-26 text-xl dark:text-white text-biscay-700 font-semibold">
                    <i class="md:flex hidden w-2 h-2 dark:bg-white rounded-full bg-blue-700 ml-2"></i>
                    {{ __('Sub campaigns') }}
                </h3>
                <div class="flex justify-end order-first lg:order-last" v-if="can('campaign.create')">
                        <router-link :to="{name: 'user.campaign.create',query:{p_id: campaign.id}}" class="btn btn-outline btn-info btn-base">
                            <span class="mt-0.5">{{ __('Generate campaign') }}</span>
                        </router-link>
                    </div>
            </div>
            <div class="rounded-2xl dark:bg-base-300 bg-white">
                <div class=" overflow-x-auto">
                    <table class="table table-xs md:table-sm xl:table-md" v-if="campaigns.length">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th> {{ __('Name') }} </th>
                                <th> {{ __('Type') }} </th>
                                <th> {{ __('Status') }} </th>
                                <th> {{ __('Start date') }} </th>
                                <th> {{ __('End date') }} </th>
                                <th> {{ __('Created At') }} </th>
                                <th> {{ __('Action') }} </th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in campaigns">
                                <td> {{ item.id }} </td>
                                <td> {{ item.name }} </td>
                                <td> {{ __(item.type.capitalize()) }} </td>
                                <td> {{ __(item.status.capitalize()) }} </td>
                                <td> {{ item.start_date }} </td>
                                <td> {{ item.end_date }} </td>
                                <td> {{ item.created_at }} </td>
                                <td>
                                    <router-link :to="{name: 'user.campaign.show',params: {id: item.id}}" :class="{'btn-disabled': !can('campaign.view.all') && !can('campaign.view.self',item.self)}"
                                        class="btn btn-outline btn-primary btn-sm ml-2">
                                        {{ __('Show') }}
                                    </router-link>
                                    <router-link :to="{name: 'user.campaign.edit',params: {id: item.id}}" :class="{'btn-disabled': !can('campaign.edit.all') && !can('campaign.edit.self',item.self)}"
                                        class="btn btn-outline btn-info btn-sm ml-2">
                                        {{ __('Edit') }}
                                    </router-link>
                                    <label @click="setItem(item,false)" for="delete-modal" :class="{'btn-disabled': !can('campaign.delete.all') && !can('campaign.delete.self',item.self)}"
                                        class="btn btn-outline btn-error btn-sm">
                                        {{ __('Delete') }}
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="col-span-12 flex flex-col items-center mt-9" v-else>
                        <NotFound />
                    </div>
                </div>
            </div>
            <Pagination :meta="meta" @setPage="setPage" class="!mt-6" />
        </div>
    </div>
    <Teleport to="#app">
        <input type="checkbox" id="delete-modal" class="modal-toggle" />
        <label class="modal" for="delete-modal">
            <label class="modal-box" for="">
                <h3 class="font-bold text-lg">{{ __('Delete campaign') }}</h3>
                <p class="py-4">{{ __('Are you sure to delete this campaign?') }}</p>
                <div class="modal-action">
                    <label class="btn btn-sm" for="delete-modal"
                        :class="{ 'pointer-events-none opacity-60': loadingBtn }">{{
                            __('Cancel') }}</label>
                    <button class="btn btn-error btn-sm text-white" @click="removeCampaign"
                        :class="{ 'loading': loadingBtn }">
                        {{ __('Delete') }}
                    </button>
                </div>
            </label>
        </label>
    </Teleport>
</template>
<script setup>
import useDashboard from "../../composables/dashboard";
import { computed, onMounted,onUpdated, reactive, ref } from 'vue';
const { getCampaign,getCampaigns, campaigns,campaign,route,router,remove, loading, meta, errors,removeCampaign,loadingBtn } = useDashboard();
const setPage = (p) => {
    setQuery('page', p.toString());
};
const setQuery = (key, value) => {
    getCampaigns(window.setQuery(router, route, key, value));
}
const setItem = (item,self = true) => Object.assign(remove,{id: item.id,single: self});
const getData = () => {
    getCampaign();
    getCampaigns(route.query);
}
const setCurrency = (value) => window.setCurrency(value,window.i18n[campaign.currency?.capitalize()]);
onMounted(() => {
    route.query.parent_id = route.params.id;
    getData();
});
onUpdated(() => {
    if (route.query.parent_id !== route.params.id) {
        route.query.parent_id = route.params.id;
        getData();
    }
});
</script>
  