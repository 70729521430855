<template>
  <div class="modal" :class="{'modal-open': show}">
    <div class="modal-box text-center p-4">
      <button class="btn btn-ghost btn-lg text-sm pointer-events-none">
        <span class="loading loading-spinner mb-1"></span>
        {{__('please wait...')}}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['show']
}
</script>
