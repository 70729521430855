import {createRouter, createWebHistory} from "vue-router";
import { useHead,useSeoMeta } from '@vueuse/head'
import Auth from "../composables/auth";
import Test from "../components/layout/Test.vue";
import Login from "../components/auth/Login.vue";
import Register from "../components/auth/Register.vue";
import ForgetPassword from "../components/auth/ForgetPassword.vue";
import ResetPassword from "../components/auth/ResetPassword.vue";
import Profile from "../components/dashboard/Profile.vue";
import Notification from "../components/dashboard/Notification.vue";
import LoginStatistic from "../components/dashboard/LoginStatistic.vue";
import PaymentLog from "../components/dashboard/PaymentLog.vue";
// import PaymentStatus from "../components/product/PaymentStatus.vue";
// import Blog from "../components/blog/Blog.vue";
// import SingleBlog from "../components/blog/Single.vue";
import Dashboard from "../components/dashboard/Home.vue";
import PageNotFound from "../components/layout/PageNotFound.vue";
// import Comment from "../components/dashboard/Comment.vue";
// import Faq from "../components/section/Faq.vue";
// import Page from "../components/section/Page.vue";
// import ContactUs from "../components/section/ContactUs.vue";
import Calendar from "../components/dashboard/Calendar.vue";
import Roles from "../components/dashboard/Roles.vue";
import Messages from "../components/dashboard/Messages.vue";
import SendMessage from "../components/dashboard/SendMessage.vue";
import SaveMessages from "../components/dashboard/SaveMessages.vue";
import AddEditRole from "../components/dashboard/AddEditRole.vue";
import Users from "../components/dashboard/Users.vue";
import AddEditUser from "../components/dashboard/AddEditUser.vue";
import Discounts from "../components/dashboard/Discounts.vue";
import AddEditDiscount from "../components/dashboard/AddEditDiscount.vue";
import Clues from "../components/dashboard/Clues.vue";
import Clue from "../components/dashboard/Clue.vue";
import AddEditClue from "../components/dashboard/AddEditClue.vue";
import ConvertClue from "../components/dashboard/ConvertClue.vue";
import Campaigns from "../components/dashboard/Campaigns.vue";
import AddEditCampaign from "../components/dashboard/AddEditCampaign.vue";
import LeadSources from "../components/dashboard/LeadSources.vue";
import Industries from "../components/dashboard/Industries.vue";
import Industry from "../components/dashboard/Industry.vue";
import AddEditIndustry from "../components/dashboard/AddEditIndustry.vue";
import Unauthorized from "../components/layout/Unauthorized.vue";
import Campaign from "../components/dashboard/Campaign.vue";
import User from "../components/dashboard/User.vue";
import Settings from "../components/dashboard/Settings.vue";
import Accounts from "../components/dashboard/Accounts.vue";
import Account from "../components/dashboard/Account.vue";
import AddEditAccount from "../components/dashboard/AddEditAccount.vue";
import Persons from "../components/dashboard/Persons.vue";
import Person from "../components/dashboard/Person.vue";
import AddEditPerson from "../components/dashboard/AddEditPerson.vue";
import Opportunities from "../components/dashboard/Opportunities.vue";
import Opportunity from "../components/dashboard/Opportunity.vue";
import AddEditOpportunity from "../components/dashboard/AddEditOpportunity.vue";
import Appointments from "../components/dashboard/Appointments.vue";
import Appointment from "../components/dashboard/Appointment.vue";
import AddEditAppointment from "../components/dashboard/AddEditAppointment.vue";
// import Search from "../components/section/Search.vue";

const baseRoute = '/';
const routes = [
    // {
    //     path: '',
    //     name: 'home',
    //     component: Home,
    // },
    // {
    //     path: baseRoute + 'contact-us',
    //     name: 'contact',
    //     component: ContactUs,
    //     meta: {
    //         title: window.i18n['Contact us'],
    //     }
    // },
    // {
    //     path: baseRoute + 'faq',
    //     name: 'faq',
    //     component: Faq,
    //     meta: {
    //         title: window.i18n['Frequently Asked Questions'],
    //     }
    // },
    {
        path: baseRoute + 'naser',
        name: 'test',
        component: Test,
        meta: {
            title: window.i18n['Test'],
        }
    },
    {
        path: baseRoute + 'login',
        name: 'login',
        component: Login,
        meta: {
            title: window.i18n['Login'],
        }
    },
    {
        path: baseRoute + 'register',
        name: 'register',
        component: Register,
        meta: {
            title: window.i18n['Register'],
        }
    },
    {
        path: baseRoute + 'forget-password',
        name: 'forget.password',
        component: ForgetPassword,
        meta: {
            title: window.i18n['Forget Pasword'],
        }
    },
    {
        path: baseRoute + 'reset-password/:token/:email',
        name: 'reset.password',
        component: ResetPassword,
        meta: {
            title: window.i18n['Reset Password'],
        }
    }
    // {
    //     path: baseRoute + 'search',
    //     name: 'search',
    //     component: Search,
    //     meta: {
    //         title: window.i18n['Search'],
    //     }
    // },
    // {
    //     path: baseRoute + 'blogs',
    //     name: 'blogs',
    //     component: Blog,
    //     meta: {
    //         title: window.i18n['Blogs'],
    //     }
    // },
    // {
    //     path: baseRoute + 'blogs/:slug',
    //     name: 'blog',
    //     component: SingleBlog,
    // },
    ,
    {
        path: baseRoute + 'dashboard/home',
        name: 'user.home',
        component: Dashboard,
        meta: {
            title: window.i18n['Dashboard'],
        }
    },
    {
        path: baseRoute + 'dashboard/role',
        name: 'user.roles',
        component: Roles,
        meta: {
            title: window.i18n['Roles'],
        }
    },
    {
        path: baseRoute + 'dashboard/role/create',
        name: 'user.role.create',
        component: AddEditRole,
        meta: {
            title: window.i18n['Create a role'],
        }
    },
    {
        path: baseRoute + 'dashboard/role/:id/edit',
        name: 'user.role.edit',
        component: AddEditRole,
        meta: {
            title: window.i18n['Edit role'],
        }
    },
    {
        path: baseRoute + 'dashboard/user',
        name: 'user.users',
        component: Users,
        meta: {
            title: window.i18n['Users'],
        }
    },
    {
        path: baseRoute + 'dashboard/user/:id',
        name: 'user.user.show',
        component: User,
        meta: {
            title: window.i18n['User details'],
        }
    },
    {
        path: baseRoute + 'dashboard/user/create',
        name: 'user.user.create',
        component: AddEditUser,
        meta: {
            title: window.i18n['Create a user'],
        }
    },
    {
        path: baseRoute + 'dashboard/user/:id/edit',
        name: 'user.user.edit',
        component: AddEditUser,
        meta: {
            title: window.i18n['Edit user'],
        }
    },
    {
        path: baseRoute + 'dashboard/clue',
        name: 'user.clues',
        component: Clues,
        meta: {
            title: window.i18n['Clues'],
        }
    },
    {
        path: baseRoute + 'dashboard/clue/:id',
        name: 'user.clue.show',
        component: Clue,
        meta: {
            title: window.i18n['Clue details'],
        }
    },
    {
        path: baseRoute + 'dashboard/clue/:id/convert',
        name: 'user.clue.convert',
        component: ConvertClue,
        meta: {
            title: window.i18n['Clue convert'],
        }
    },
    {
        path: baseRoute + 'dashboard/lead-source',
        name: 'user.lead.sources',
        component: LeadSources,
        meta: {
            title: window.i18n['Lead Sources'],
        }
    },
    {
        path: baseRoute + 'dashboard/discount',
        name: 'user.discounts',
        component: Discounts,
        meta: {
            title: window.i18n['Discounts'],
        }
    },
    {
        path: baseRoute + 'dashboard/discount/create',
        name: 'user.discount.create',
        component: AddEditDiscount,
        meta: {
            title: window.i18n['Add discount'],
        }
    },
    {
        path: baseRoute + 'dashboard/discount/:id/edit',
        name: 'user.discount.edit',
        component: AddEditDiscount,
        meta: {
            title: window.i18n['Edit discount'],
        }
    },
    {
        path: baseRoute + 'dashboard/save-message',
        name: 'user.save.messages',
        component: SaveMessages,
        meta: {
            title: window.i18n['Ready messages'],
        }
    },
    {
        path: baseRoute + 'dashboard/message',
        name: 'user.messages',
        component: Messages,
        meta: {
            title: window.i18n['Messages'],
        }
    },
    {
        path: baseRoute + 'dashboard/message/create',
        name: 'user.message.create',
        component: SendMessage,
        meta: {
            title: window.i18n['Add Message'],
        }
    },
    {
        path: baseRoute + 'dashboard/industry',
        name: 'user.industries',
        component: Industries,
        meta: {
            title: window.i18n['Industries'],
        }
    },
    {
        path: baseRoute + 'dashboard/industry/create',
        name: 'user.industry.create',
        component: AddEditIndustry,
        meta: {
            title: window.i18n['Add industry'],
        }
    },
    {
        path: baseRoute + 'dashboard/industry/:id/edit',
        name: 'user.industry.edit',
        component: AddEditIndustry,
        meta: {
            title: window.i18n['Edit industry'],
        }
    },
    {
        path: baseRoute + 'dashboard/industry/:id',
        name: 'user.industry.show',
        component: Industry,
        meta: {
            title: window.i18n['Industry details'],
        }
    },
    {
        path: baseRoute + 'dashboard/clue/create',
        name: 'user.clue.create',
        component: AddEditClue,
        meta: {
            title: window.i18n['Generate leads'],
        }
    },
    {
        path: baseRoute + 'dashboard/clue/:id/edit',
        name: 'user.clue.edit',
        component: AddEditClue,
        meta: {
            title: window.i18n['Edit leads'],
        }
    },
    {
        path: baseRoute + 'dashboard/campaign',
        name: 'user.campaigns',
        component: Campaigns,
        meta: {
            title: window.i18n['Campaigns'],
        }
    },
    {
        path: baseRoute + 'dashboard/campaign/create',
        name: 'user.campaign.create',
        component: AddEditCampaign,
        meta: {
            title: window.i18n['Generate campaigns'],
        }
    },
    {
        path: baseRoute + 'dashboard/campaign/:id/edit',
        name: 'user.campaign.edit',
        component: AddEditCampaign,
        meta: {
            title: window.i18n['Edit campaign'],
        }
    },
    {
        path: baseRoute + 'dashboard/campaign/:id',
        name: 'user.campaign.show',
        component: Campaign,
        meta: {
            title: window.i18n['Campaign details'],
        }
    },
    {
        path: baseRoute + 'dashboard/person',
        name: 'user.persons',
        component: Persons,
        meta: {
            title: window.i18n['Persons'],
        }
    },
    {
        path: baseRoute + 'dashboard/person/:id',
        name: 'user.person.show',
        component: Person,
        meta: {
            title: window.i18n['Person details'],
        }
    },
    {
        path: baseRoute + 'dashboard/person/create',
        name: 'user.person.create',
        component: AddEditPerson,
        meta: {
            title: window.i18n['Create person'],
        }
    },
    {
        path: baseRoute + 'dashboard/person/:id/edit',
        name: 'user.person.edit',
        component: AddEditPerson,
        meta: {
            title: window.i18n['Edit person'],
        }
    },
    {
        path: baseRoute + 'dashboard/opportunity',
        name: 'user.opportunities',
        component: Opportunities,
        meta: {
            title: window.i18n['Opportunities'],
        }
    },
    {
        path: baseRoute + 'dashboard/opportunity/:id',
        name: 'user.opportunity.show',
        component: Opportunity,
        meta: {
            title: window.i18n['Opportunity details'],
        }
    },
    {
        path: baseRoute + 'dashboard/opportunity/create',
        name: 'user.opportunity.create',
        component: AddEditOpportunity,
        meta: {
            title: window.i18n['Create opportunity'],
        }
    },
    {
        path: baseRoute + 'dashboard/opportunity/:id/edit',
        name: 'user.opportunity.edit',
        component: AddEditOpportunity,
        meta: {
            title: window.i18n['Edit opportunity'],
        }
    },
    {
        path: baseRoute + 'dashboard/calendar',
        name: 'user.calendar',
        component: Calendar,
        meta: {
            title: window.i18n['Calendar'],
        }
    },
    {
        path: baseRoute + 'dashboard/appointment',
        name: 'user.appointments',
        component: Appointments,
        meta: {
            title: window.i18n['Appointments'],
        }
    },
    {
        path: baseRoute + 'dashboard/appointment/:id',
        name: 'user.appointment.show',
        component: Appointment,
        meta: {
            title: window.i18n['Appointment details'],
        }
    },
    {
        path: baseRoute + 'dashboard/appointment/create',
        name: 'user.appointment.create',
        component: AddEditAppointment,
        meta: {
            title: window.i18n['Create appointment'],
        }
    },
    {
        path: baseRoute + 'dashboard/appointment/:id/edit',
        name: 'user.appointment.edit',
        component: AddEditAppointment,
        meta: {
            title: window.i18n['Edit appointment'],
        }
    },
    {
        path: baseRoute + 'dashboard/contact',
        name: 'user.contacts',
        component: Appointments,
        meta: {
            title: window.i18n['Contacts'],
        }
    },
    {
        path: baseRoute + 'dashboard/contact/:id',
        name: 'user.contact.show',
        component: Appointment,
        meta: {
            title: window.i18n['Contact details'],
        }
    },
    {
        path: baseRoute + 'dashboard/contact/create',
        name: 'user.contact.create',
        component: AddEditAppointment,
        meta: {
            title: window.i18n['Create contact'],
        }
    },
    {
        path: baseRoute + 'dashboard/contact/:id/edit',
        name: 'user.contact.edit',
        component: AddEditAppointment,
        meta: {
            title: window.i18n['Edit audience'],
        }
    },
    {
        path: baseRoute + 'dashboard/task',
        name: 'user.tasks',
        component: Appointments,
        meta: {
            title: window.i18n['Tasks'],
        }
    },
    {
        path: baseRoute + 'dashboard/task/:id',
        name: 'user.task.show',
        component: Appointment,
        meta: {
            title: window.i18n['Task details'],
        }
    },
    {
        path: baseRoute + 'dashboard/task/create',
        name: 'user.task.create',
        component: AddEditAppointment,
        meta: {
            title: window.i18n['Create task'],
        }
    },
    {
        path: baseRoute + 'dashboard/task/:id/edit',
        name: 'user.task.edit',
        component: AddEditAppointment,
        meta: {
            title: window.i18n['Edit task'],
        }
    },
    {
        path: baseRoute + 'dashboard/account',
        name: 'user.accounts',
        component: Accounts,
        meta: {
            title: window.i18n['Accounts'],
        }
    },
    {
        path: baseRoute + 'dashboard/account/:id',
        name: 'user.account.show',
        component: Account,
        meta: {
            title: window.i18n['Account details'],
        }
    },
    {
        path: baseRoute + 'dashboard/account/create',
        name: 'user.account.create',
        component: AddEditAccount,
        meta: {
            title: window.i18n['Create account'],
        }
    },
    {
        path: baseRoute + 'dashboard/account/:id/edit',
        name: 'user.account.edit',
        component: AddEditAccount,
        meta: {
            title: window.i18n['Edit account'],
        }
    },
    {
        path: baseRoute + 'dashboard/profile',
        name: 'user.profile',
        component: Profile,
        meta: {
            title: window.i18n['Profile'],
        }
    },
    {
        path: baseRoute + 'dashboard/notification',
        name: 'user.notification',
        component: Notification,
        meta: {
            title: window.i18n['Notifications'],
        }
    },
    {
        path: baseRoute + 'dashboard/session',
        name: 'user.session',
        component: LoginStatistic,
        meta: {
            title: window.i18n['Login statistics'],
        }
    },

    {
        path: baseRoute + 'dashboard/payment-log',
        name: 'user.payment.log',
        component: PaymentLog,
        meta: {
            title: window.i18n['Transactions'],
        }
    },
    {
        path: baseRoute + 'dashboard/settings',
        name: 'user.settings',
        component: Settings,
        meta: {
            title: window.i18n['Settings'],
        }
    },
    // {
    //     path: baseRoute + 'payment-status/:track',
    //     name: 'payment.status',
    //     component: PaymentStatus,
    //     meta: {
    //         title: window.i18n['Payment Status'],
    //     }
    // },
    // {
    //     path: baseRoute + 'dashboard/comment',
    //     name: 'user.comment',
    //     component: Comment,
    //     meta: {
    //         title: window.i18n['My views'],
    //     }
    // },
    {
        path: baseRoute + 'unauthorized',
        name: 'unauthorized',
        component: Unauthorized,
        meta: {
            title: window.i18n['Unauthorized'],
        }
    },
    {
        path: '/:pathMatch(.*)',
        name: 'not.found',
        component: PageNotFound,
        meta: {
            title: window.i18n['Page not found'],
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: 'active',
    linkExactActiveClass: '',
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 , behavior: 'smooth'}
      },
});

router.beforeEach((to, from, next) => {
    window.head?.dispose();
    window.seo?.dispose();
    document.querySelector('.dropdown-open')?.classList?.remove('dropdown-open');
    document.activeElement.blur();
    const name = to.name.toString();
    const metaTitle = to?.meta?.title;
    if(metaTitle){
        useHead({
            title: metaTitle,
            link :{rel: 'canonical',href: location.href}
          })
          useSeoMeta({
            ogUrl: location.href,
            ogTitle: metaTitle,
            twitterTitle: metaTitle,
          })
    }
    if (Auth.check()) {
        if(!Auth.user.value.permissions || ['user.profile','user.roles'].includes(name)) Auth.getUser();
        if (['login', 'register'].includes(name)) router.replace({name: 'user.home'});
        else next();
    } else if (name.includes('user.') || to?.meta?.requireAuth) router.push({name: 'login'});
    else next();
});
// router.afterEach((to, from) => {
//     document.querySelectorAll('.dropdown label').forEach(elm => {
//         elm.addEventListener('click',() => {
//           const dropdown = document.querySelector('.dropdown-open');
//           if(dropdown && dropdown !== elm.parentElement)dropdown.classList.remove('dropdown-open');
//           elm.parentElement.classList.toggle('dropdown-open');
//           document.activeElement.blur();
//         })
//       });
// });
export default router;
